import {React, useRef, useState, useEffect} from "react";
import { useParams } from 'react-router-dom';
import "../assets/css/classic.css";
import * as sheet from "opensheetmusicdisplay";
import {Helmet} from "react-helmet";




function ScoreShow({midiFile, xmlFile}){
    const ref = useRef(null);
    useEffect(() => {
        // code to run after render goes here
        //const element = ref.current;
        const osmd = new sheet.OpenSheetMusicDisplay(ref.current, {
        autoResize: true, // just an example for an option, no option is necessary.
        backend: 'svg',
        //drawTitle: true,
        // put further options here
        });
        var loadPromise = osmd.load(xmlFile).then(function () {
            osmd.zoom = 0.8;
            osmd.render();
            window.addEventListener('resize', () => {
                //osmd.render();
            });
              
            var innerDivs = document.getElementById('right').getElementsByTagName('div');
            //console.log(innerDivs.length)
            if (innerDivs.length > 1) {
                //console.log("more than one div found inside right");
                innerDivs[1].innerHTML = '';
            }
        });       
    }, [xmlFile])// <-- }, [])  empty array means 'run once'
    return (
        <>
            <div className="text-base-content bg-base-100 py-1 flex justify-center items-center">
                <div className="flex justify-center items-center"><a  href={midiFile} download><button className="btn btn-ghost w-80"><ion-icon name="download-outline"></ion-icon> midi </button> </a></div>
            </div>
            <div id="section3">
                <midi-player id="myPlayer" src={midiFile}  sound-font="https://storage.googleapis.com/magentadata/js/soundfonts/sgm_plus" visualizer="#myVisualizer">
                </midi-player>
                <midi-visualizer type="piano-roll" id="myVisualizer" src={midiFile}  ></midi-visualizer>
            </div >
            <div ref={ref} id="right"> </div>
        </>

    );
}





    function ClassicalScoreview (){
        //const ref = useRef(null);
        //const visualref = useRef(null);
        //const midiref = useRef(null);
        // Get a specific parameter
        let { id } = useParams();

        const [error, setError] = useState(null);
        const [isLoaded, setIsLoaded] = useState(false);
        const [items, setItems] = useState([]);

        useEffect(() => {
                const fileID = parseInt(id);
                const url = `https://goldfish-app-asx3v.ondigitalocean.app/allera/classical/${fileID}`;
                
                fetch(url)
                  .then(res => res.json())
                  .then(
                    (result) => {
                      setIsLoaded(true);
                      setItems(result);
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                      setIsLoaded(true);
                      setError(error);
                    }
                  );   
        }, [id])// <-- }, [])  empty array means 'run once'
    

        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {
                const midifileUrl = "https://arfusoftaudiofiles.s3.eu-central-1.amazonaws.com/"+items.Composer+"/"+items.MusicxmlFile+".mid";
                const xmlfileUrl = "https://arfusoftaudiofiles.s3.eu-central-1.amazonaws.com/"+items.Composer+"/"+items.MusicxmlFile+".musicxml";
                return(
                    <div  className="z-0">

                        <Helmet>
                            <meta charSet="utf-8"/>
                            <title>{`${items.DisplayName}`} </title>
                            <link rel="canonical" href={`https://alleramusic.com/score/classical/${id}`}/>
                            <meta property="og:locale" content="en_US"/>
                            <meta property="og:type" content="article"/>
                            <meta property="og:title" content={`${items.DisplayName}`}/>
                        </Helmet>


                        { 
                            <ScoreShow
                            key={items.Id} 
                            midiFile={midifileUrl}
                            xmlFile={xmlfileUrl}
                            ></ScoreShow>
                        }
                    </div>
            
                );
            }
    }
    
export  {ClassicalScoreview, ScoreShow};