import {React, useRef, useState, useEffect} from "react";
import { useParams } from 'react-router-dom';
import "../assets/css/classic.css";
import { ScoreShow } from "./ClassicalScoreview";
import {Helmet} from "react-helmet";



function AncientScoreview (){
    let { id } = useParams();
    let { category } = useParams();

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);

    useEffect(() => {
            const fileID = parseInt(id);
            const url = `https://goldfish-app-asx3v.ondigitalocean.app/allera/${category}/${fileID}`;
            
            fetch(url)
              .then(res => res.json())
              .then(
                (result) => {
                  setIsLoaded(true);
                  setItems(result);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                  setIsLoaded(true);
                  setError(error);
                }
              );   
    }, [id])// <-- }, [])  empty array means 'run once'


    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {
            const midifileUrl = "https://arfusoftaudiofiles.s3.eu-central-1.amazonaws.com/"+items.Composer+"/"+items.MusicxmlFile+".mid";
            const xmlfileUrl = "https://arfusoftaudiofiles.s3.eu-central-1.amazonaws.com/"+items.Composer+"/"+items.MusicxmlFile+".musicxml";
            return(
                <div  className="z-0">

                    <Helmet>
                        <meta charSet="utf-8"/>
                        <title>{`${items.DisplayName}`} </title>
                        <link rel="canonical" href={`https://alleramusic.com/score/ancient/${category}/${id}`}/>
                        <meta property="og:locale" content="en_US"/>
                        <meta property="og:type" content="article"/>
                        <meta property="og:title" content={`${items.DisplayName}`}/>
                    </Helmet>


                    { 
                        <ScoreShow
                        key={items.Id} 
                        midiFile={midifileUrl}
                        xmlFile={xmlfileUrl}
                        ></ScoreShow>
                    }
                </div>
        
            );
        }
}
export  {AncientScoreview};