import {React} from "react";
import AncientCompositions from "./AncientCompositions";


function Agricola() {
    return (
      <>
        <AncientCompositions category={'renaissance'} author={'Agricola'} />
      </>
    );
  }
  function Anonymous() {
    return (
      <>
        <AncientCompositions category={'renaissance'}  author={'Anonymous'} />
      </>
    );
  }
  function Binchois() {
    return (
      <>
        <AncientCompositions category={'renaissance'}  author={'Binchois'} />
      </>
    );
  }
  function Brumel() {
    return (
      <>
        <AncientCompositions category={'renaissance'}  author={'Brumel'} />
      </>
    );
  }
  function Busnoys() {
    return (
      <>
        <AncientCompositions category={'renaissance'}  author={'Busnoys'} />
      </>
    );
  }
  function Compere() {
    return (
      <>
        <AncientCompositions category={'renaissance'}  author={'Compere'} />
      </>
    );
  }
  function Daser() {
    return (
      <>
        <AncientCompositions category={'renaissance'}  author={'Daser'} />
      </>
    );
  }
  function DeOrto() {
    return (
      <>
        <AncientCompositions category={'renaissance'}  author={'DeOrto'} authorName={'De Orto'}/>
      </>
    );
  }
  function DuFay() {
    return (
      <>
        <AncientCompositions category={'renaissance'}  author={'DuFay'} authorName={'Du Fay'}/>
      </>
    );
  }
  function Frye() {
    return (
      <>
        <AncientCompositions category={'renaissance'}  author={'Frye'} />
      </>
    );
  }
  function Fevin() {
    return (
      <>
        <AncientCompositions category={'renaissance'}  author={'Fevin'} authorName={'Févin'} />
      </>
    );
  }
  function Gaspard() {
    return (
      <>
        <AncientCompositions category={'renaissance'}  author={'Gaspard'} />
      </>
    );
  }
  function Isaac() {
    return (
      <>
        <AncientCompositions category={'renaissance'}  author={'Isaac'} />
      </>
    );
  }
  function Japart() {
    return (
      <>
        <AncientCompositions category={'renaissance'}  author={'Japart'} />
      </>
    );
  }
  function Josquin() {
    return (
      <>
        <AncientCompositions category={'renaissance'}  author={'Josquin'} />
      </>
    );
  }
  function LaRue() {
    return (
      <>
        <AncientCompositions category={'renaissance'}  author={'LaRue'} authorName={'La Rue'}/>
      </>
    );
  }
  function Martini() {
    return (
      <>
        <AncientCompositions category={'renaissance'}  author={'Martini'} />
      </>
    );
  }
  function Mouton() {
    return (
      <>
        <AncientCompositions category={'renaissance'}  author={'Mouton'} />
      </>
    );
  }
  function Obrecht() {
    return (
      <>
        <AncientCompositions category={'renaissance'}  author={'Obrecht'} />
      </>
    );
  }
  function Ockeghem() {
    return (
      <>
        <AncientCompositions category={'renaissance'}  author={'Ockeghem'} />
      </>
    );
  }
  function Pipelare() {
    return (
      <>
        <AncientCompositions category={'renaissance'}  author={'Pipelare'} />
      </>
    );
  }
  function Regis() {
    return (
      <>
        <AncientCompositions category={'renaissance'}  author={'Regis'} />
      </>
    );
  }
  function Tinctoris() {
    return (
      <>
        <AncientCompositions category={'renaissance'}  author={'Tinctoris'} />
      </>
    );
  }
  export {
    Agricola,
    Anonymous,
    Binchois,
    Brumel,
    Busnoys,
    Compere,
    Daser,
    DeOrto,
    DuFay,
    Frye,
    Fevin,
    Gaspard,
    Isaac,
    Japart,
    Josquin,
    LaRue,
    Martini,
    Mouton,
    Obrecht,
    Ockeghem,
    Pipelare,
    Regis,
    Tinctoris,
  };