import {React, useState, useEffect} from "react";
import {Helmet} from "react-helmet";
import background6 from "../assets/images/about-shape-1.svg";
import CommonFooter from "./CommonFooter.jsx";




  function ImageMP3Display ({name, imageFile, mp3File, midiFile}){
    return (
        <>
            <div className="py-4">
                <li>
                    <details>
                        <summary>
                        {name}
                        </summary>
                        <div className="px-1 py-2 mx-auto max-w-lg">
                            <div className="card shadow w-full">
                                <figure>
                                    <img src={imageFile} alt="" />
                                </figure>
                                <div className="card-body ">
                                    <h2 className="card-title"> </h2>
                                    <figure>
                                      <figcaption></figcaption>
                                      <audio  controls src={mp3File}>
                                        <a href={mp3File}> Download midi </a>
                                      </audio>
                                    </figure>
                                      <a href={midiFile}  download><button className="btn px-12 text-base-content bg-base-100 w-full"><ion-icon name="download-outline"></ion-icon> midi </button> </a>
                                </div>
                            </div>
                        </div>
                    </details>
                </li>
                
            </div>
            <hr></hr>
        </>
    )
}


  function Progressions(){
      const [searchItem, setSearchItem] = useState('')
      const [error, setError] = useState(null);
      const [isLoaded, setIsLoaded] = useState(false);
      const [items, setItems] = useState([]);
    
      useEffect(() => {
        fetch("https://goldfish-app-asx3v.ondigitalocean.app/allera/progressions")
          .then(res => res.json())
          .then(
            (result) => {
              setIsLoaded(true);
              setItems(result);
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
              setIsLoaded(true);
              setError(error);
            }
          )
    
      }, [])
    
      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return <div>Loading...</div>;
      } else {

      const backgroundImage = `url(${background6})`;

    
      return (
        <>

            <Helmet>
                <meta charSet="utf-8"/>
                <title>Chords progressions</title>
                <link rel="canonical" href="https://alleramusic.com/progressions"/>
                <meta name="description"
                      content="Chords progressions bring together a collection of exciting chord progressions that can be heard in most albums and hits with international success."/>
                <meta name="keywords" content="chords,chords progressions"/>
                <meta property="og:title" content="Chords progressions"/>
                <meta property="og:description"
                      content="Chords progressions bring together a collection of exciting chord progressions that can be heard in most albums and hits with international success."/>
                <meta property="og:site_name" content="Chords progressions"/>
                <meta property="og:locale" content="en_US"/>
                <meta property="og:type" content="article"/>
            </Helmet>


            <div className="text-base-content py-2 flex justify-center items-center  bg-base-100 mainDiv" style={{backgroundImage: backgroundImage}}>
              


          <div className="py-4 lg:py-6">

<div className="space-y-6 py-2 lg:py-4 text-base leading-7 text-black-500 dark:text-white px-6 lg:px-12">
<h1 className="flex justify-center items-center  text-2xl  text-blue-500"><b>Chords Progressions</b></h1>
<p className="text-black-500 dark:text-white text-base">
                  Chords progressions bring together a collection of exciting chord progressions that can be heard in most albums and hits with international success. 
                  Harmony is how musicians tell stories with sounds. Certain harmonies can make listeners feel tense and dark, while other harmonies make listeners feel optimistic and happy. 
                  Understanding harmony can shape how you want to play chords and how you want other people to feel when they listen to you. In other words, harmony is the basis of any composition. 
                  We selected some exciting harmonies (chord progression), and they are listed below. 
                  Each chord progression is displayed as a notation in tablature. 
                  You can play back each chord sequence and download it in the midi and mp3 formats. 
                    <span className="text-base">The chord sequences we gathered are usually found in musical styles such as </span>
                    <code className="text-sm font-bold"> Pop, Rock, Folk, Ballads, Blues, Funk and Latin music</code>.
                  </p>
      <p className="flex justify-center items-center text-black-500 dark:text-white text-base">The native application can be downloaded on: </p><br />
      <a className="flex justify-center items-center" href="https://play.google.com/store/apps/details?id=tntechnology.app.chords_progressions" target="_blank" rel="noreferrer"><button className="btn btn-wide btn-outline btn-info">Google Playstore</button></a>
      <a className="flex justify-center items-center" href="https://www.microsoft.com/store/apps/9P5SGW218XL9" target="_blank" rel="noreferrer"><button className="btn btn-wide btn-info">Microsoft Store</button></a>
      </div>
</div>


          </div>
          <div className="text-base-content bg-base-100 px-2 py-2 flex justify-center items-center">
            <input  type="text" placeholder="Search here" className="input input-bordered input-info w-full max-w-md"  value={searchItem} onChange={event => setSearchItem(event.target.value)}></input>
          </div>
          <div className="text-base-content bg-base-100 py-6 flex justify-center items-center">
            <ul className="menu menu-vertical px-1 w-full  max-w-lg">
              {items.filter((music) => //items here
              {
                if(searchItem === "") {
                  return music;
                }
                else if 
                (music.Name.toLowerCase().includes(searchItem.toLowerCase())) {
                  return music;
                }
                else return null
                
              }).map(chord => 
              <ImageMP3Display key={chord.Id} name={chord.Name} 
                imageFile={"https://arfusoftaudiofiles.s3.eu-central-1.amazonaws.com/progressions/"+chord.MidiImageFile+".png"} 
                mp3File={"https://arfusoftaudiofiles.s3.eu-central-1.amazonaws.com/progressions/"+chord.Mp3File+".mp3"}
                midiFile={"https://arfusoftaudiofiles.s3.eu-central-1.amazonaws.com/progressions/"+chord.MidiImageFile+".mid"} >

            </ImageMP3Display>)}
            </ul>

          </div>


      {/*<CommonFooter /> */}
        </>
      );}
}

export  {ImageMP3Display,Progressions}