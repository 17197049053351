import { BrowserRouter , Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./components/Home";
import {AncientScoreview} from "./components/AncientScoreview";
import {ClassicalScoreview} from "./components/ClassicalScoreview";
import {
  Agricola,
  Anonymous,
  Binchois,
  Brumel,
  Busnoys,
  Compere,
  Daser,
  DeOrto,
  DuFay,
  Frye,
  Fevin,
  Gaspard,
  Isaac,
  Japart,
  Josquin,
  LaRue,
  Martini,
  Mouton,
  Obrecht,
  Ockeghem,
  Pipelare,
  Regis,
  Tinctoris
} from "./components/Renaissance";

import {
  Acoma,
  BritishColumbia,
  CheyenneandArapaho,
  Chippewa1,
  Chippewa2,
  Choctaw,
  Maidu,
  Mandan,
  Menominee,
  Nootka,
  NorthernUte,
  Papago,
  Pawnee,
  Seminole,
  Sioux,
  Yuman
} from "./components/Native";

import {
  Bach,
  Beethoven,
  Chopin,
  Corelli,
  Haydn,
  Hummel,
  Joplin,
  Mozart,
  Scarlatti,
  Songs,
} from "./components/Classical";

import TheoryMain from "./components/Theory/TheoryMain";
import TheoryDefinitions from "./components/Theory/Definitions";
import {TheoryNoteMidi, TheoryMidiNote}from "./components/Theory/Note";
import TheoryMode from "./components/Theory/Mode";
import {TheoryScale, TheoryScaleDetect} from "./components/Theory/Scale";
import {TheoryChord, TheoryChordDetect,TheoryChordSuperset,TheoryChordSubset} from "./components/Theory/Chord";
import TheoryInterval from "./components/Theory/Interval";
import {Achords, ASharpchords, Bchords, Cchords, CSharpchords, Dchords, 
  DSharpchords, Echords, Fchords, FSharpchords, Gchords, GSharpchords} from "./components/Theory/GuitarChords";
import {AchordsP, ASharpchordsP, BchordsP, CchordsP, CSharpchordsP, DchordsP,
  DSharpchordsP, EchordsP, FchordsP, FSharpchordsP, GchordsP, GSharpchordsP} from "./components/Theory/PianoChords";


import Rhythms from "./components/Rhythms";
import {Progressions} from "./components/Progressions";

import ContemporarySongs from "./components/ContemporarySongs";
import ContemporaryScoreview from "./components/ContemporaryScoreview";
import BassGrooves from "./components/BassGrooves";
import NotFound from "./components/NotFound";

import About from "./components/About";
import Policy from "./components/Policy";
import Term from "./components/Term";
import Contact from "./components/Contact";
import Cookies from "./components/Cookies";
import Melodies from "./components/Melodies";




//frontend app life cycle
//https://blog.logrocket.com/user-authentication-firebase-react-apps/


const App = () => {
  return (
    <BrowserRouter>
      <Routes>
          <Route path = "/" element = { <Layout></Layout> }>
            <Route index element = { <Home></Home> }></Route>
            <Route path = "/agricola" element = { <Agricola></Agricola> } ></Route>
            <Route path = "/anonymous" element = { <Anonymous></Anonymous> } ></Route>
            <Route path = "/binchois" element = { <Binchois></Binchois> } ></Route>
            <Route path = "/brumel" element = { <Brumel></Brumel> } ></Route>
            <Route path = "/busnoys" element = { <Busnoys></Busnoys> } ></Route>
            <Route path = "/compere" element = { <Compere></Compere> } ></Route>
            <Route path = "/daser" element = { <Daser></Daser> } ></Route>
            <Route path = "/deorto" element = { <DeOrto></DeOrto> } ></Route>
            <Route path = "/dufay" element = { <DuFay></DuFay> } ></Route>
            <Route path = "/frye" element = { <Frye></Frye> } ></Route>
            <Route path = "/fevin" element = { <Fevin></Fevin> } ></Route>
            <Route path = "/gaspard" element = { <Gaspard></Gaspard> } ></Route>
            <Route path = "/isaac" element = { <Isaac></Isaac> } ></Route>
            <Route path = "/japart" element = { <Japart></Japart> } ></Route>
            <Route path = "/josquin" element = { <Josquin></Josquin> } ></Route>
            <Route path = "/larue" element = { <LaRue></LaRue> } ></Route>
            <Route path = "/martini" element = { <Martini></Martini> } ></Route>
            <Route path = "/mouton" element = { <Mouton></Mouton> } ></Route>
            <Route path = "/obrecht" element = { <Obrecht></Obrecht> } ></Route>
            <Route path = "/ockeghem" element = { <Ockeghem></Ockeghem> } ></Route>
            <Route path = "/pipelare" element = { <Pipelare></Pipelare> } ></Route>
            <Route path = "/regis" element = { <Regis></Regis> } ></Route>
            <Route path = "/tinctoris" element = { <Tinctoris></Tinctoris> } ></Route>


            <Route path = "/acoma" element = { <Acoma></Acoma> } ></Route>
            <Route path = "/britishcolumbia" element = { <BritishColumbia></BritishColumbia> } ></Route>
            <Route path = "/cheyenneandarapaho" element = { <CheyenneandArapaho></CheyenneandArapaho> } ></Route>
            <Route path = "/chippewa1" element = { <Chippewa1></Chippewa1> } ></Route>
            <Route path = "/chippewa2" element = { <Chippewa2></Chippewa2> } ></Route>
            <Route path = "/choctaw" element = { <Choctaw></Choctaw> } ></Route>
            <Route path = "/maidu" element = { <Maidu></Maidu> } ></Route>
            <Route path = "/mandan" element = { <Mandan></Mandan> } ></Route>
            <Route path = "/menominee" element = { <Menominee></Menominee> } ></Route>
            <Route path = "/nootka" element = { <Nootka></Nootka> } ></Route>
            <Route path = "/northernute" element = { <NorthernUte></NorthernUte> } ></Route>
            <Route path = "/papago" element = { <Papago></Papago> } ></Route>
            <Route path = "/pawnee" element = { <Pawnee></Pawnee> } ></Route>
            <Route path = "/seminole" element = { <Seminole></Seminole> } ></Route>
            <Route path = "/sioux" element = { <Sioux></Sioux> } ></Route>
            <Route path = "/yuman" element = { <Yuman></Yuman> } ></Route>


            <Route path = "/bach" element = { <Bach></Bach> } ></Route>
            <Route path = "/beethoven" element = { <Beethoven></Beethoven> } ></Route>
            <Route path = "/chopin" element = { <Chopin></Chopin> } ></Route>
            <Route path = "/corelli" element = { <Corelli></Corelli> } ></Route>
            <Route path = "/haydn" element = { <Haydn></Haydn> } ></Route>
            <Route path = "/hummel" element = { <Hummel></Hummel> } ></Route>
            <Route path = "/joplin" element = { <Joplin></Joplin> } ></Route>
            <Route path = "/mozart" element = { <Mozart></Mozart> } ></Route>
            <Route path = "/scarlatti" element = { <Scarlatti></Scarlatti> } ></Route>
            <Route path = "/songs" element = { <Songs></Songs> } ></Route>

            <Route path = "/score/ancient/:category/:id" element = { <AncientScoreview></AncientScoreview> } ></Route>
            <Route path = "/score/classical/:id"  element = { <ClassicalScoreview></ClassicalScoreview> } ></Route>
            <Route path = "/score/contemporary/:id"  element = { <ContemporaryScoreview></ContemporaryScoreview> } ></Route>

            <Route path ="/theory-main" element = { <TheoryMain/> } ></Route>
            <Route path ="/theory-definition" element = { <TheoryDefinitions/> } ></Route>
            <Route path ="/theory-mode" element = { <TheoryMode/> } ></Route>
            <Route path ="/theory-scale" element = { <TheoryScale/> } ></Route>
            <Route path ="/theory-scale-detect" element = { <TheoryScaleDetect/> } ></Route>
            <Route path ="/theory-interval" element = { <TheoryInterval/> } ></Route>
            <Route path ="/theory-note-tomidi" element = { <TheoryNoteMidi/> } ></Route>
            <Route path ="/theory-note-frommidi" element = { <TheoryMidiNote/> } ></Route>
            <Route path ="/theory-chord" element = { <TheoryChord/> } ></Route>
            <Route path ="/theory-chord-detect" element = { <TheoryChordDetect/> } ></Route>
            <Route path ="/theory-chord-superset" element = { <TheoryChordSuperset/> } ></Route>
            <Route path ="/theory-chord-subset" element = { <TheoryChordSubset/> } ></Route>
            <Route path ="/theory-guitar" element = { <Achords/> } ></Route>
            <Route path ="/theory-guitar-asharp" element = { <ASharpchords/> } ></Route>
            <Route path ="/theory-guitar-b" element = { <Bchords/> } ></Route>
            <Route path ="/theory-guitar-c" element = { <Cchords/> } ></Route>
            <Route path ="/theory-guitar-csharp" element = { <CSharpchords/> } ></Route>
            <Route path ="/theory-guitar-d" element = { <Dchords/> } ></Route>
            <Route path ="/theory-guitar-dsharp" element = { <DSharpchords/> } ></Route>
            <Route path ="/theory-guitar-e" element = { <Echords/> } ></Route>
            <Route path ="/theory-guitar-f" element = { <Fchords/> } ></Route>
            <Route path ="/theory-guitar-fsharp" element = { <FSharpchords/> } ></Route>
            <Route path ="/theory-guitar-g" element = { <Gchords/> } ></Route>
            <Route path ="/theory-guitar-gsharp" element = { <GSharpchords/> } ></Route>


            <Route path ="/theory-piano" element = { <AchordsP/> } ></Route>
            <Route path ="/theory-piano-asharp" element = { <ASharpchordsP/> } ></Route>
            <Route path ="/theory-piano-b" element = { <BchordsP/> } ></Route>
            <Route path ="/theory-piano-c" element = { <CchordsP/> } ></Route>
            <Route path ="/theory-piano-csharp" element = { <CSharpchordsP/> } ></Route>
            <Route path ="/theory-piano-d" element = { <DchordsP/> } ></Route>
            <Route path ="/theory-piano-dsharp" element = { <DSharpchordsP/> } ></Route>
            <Route path ="/theory-piano-e" element = { <EchordsP/> } ></Route>
            <Route path ="/theory-piano-f" element = { <FchordsP/> } ></Route>
            <Route path ="/theory-piano-fsharp" element = { <FSharpchordsP/> } ></Route>
            <Route path ="/theory-piano-g" element = { <GchordsP/> } ></Route>
            <Route path ="/theory-piano-gsharp" element = { <GSharpchordsP/> } ></Route>

            <Route path ="/rhythms" element = { <Rhythms/> } ></Route>
            <Route path ="/progressions" element = { <Progressions/> } ></Route>
            <Route path ="/melodies" element = { <Melodies/> } ></Route>
            <Route path ="/bass/grooves" element = { <BassGrooves/> } ></Route>
            <Route path ="/contemporary/songs/:category" element = { <ContemporarySongs/> } ></Route>




            <Route path ="/terms" element = { <Term/> } ></Route>
            <Route path ="/policy" element = { <Policy/> } ></Route>
            <Route path ="/contact" element = { <Contact/> } ></Route>
            <Route path ="/cookies" element = { <Cookies/> } ></Route>
            <Route path ="/about" element = { <About/> } ></Route>
          <Route path='*' element={<NotFound />}></Route>



          </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App