import {React, useState }from "react";
import {Helmet} from "react-helmet";
import { Chord} from "tonal";

function DisplayChordNotes({root, mode, operation}){
    const [outputText, setOutputText] = useState('')

    const startCalculateNotes =() => { 
        setOutputText('');
        const notes = Chord.notes(mode, root);
        const result = notes.join(', ');
        setOutputText(result);
              
    }
    const startCalculateSupersets =() => { 
        setOutputText('');
        const notes = Chord.extended(`${root}${mode}`);
        const result = notes.join(', ');
        setOutputText(result);
              
    }
    const startCalculateSubsets =() => { 
        setOutputText('');
        const notes = Chord.reduced(`${root}${mode}`);
        const result = notes.join(', ');
        setOutputText(result);
              
    }


    if(operation ==='notes'){
        return(
            <div>
                <div className="py-2">
                    <button className="btn bg-blue-600 hover:bg-blue-800 text-white w-full rounded-full normal-case" onClick={startCalculateNotes}>Show the notes of {root}{mode}</button>
                </div>
                <div>
                    <textarea className="textarea textarea-bordered font-bold w-full h-60" value={outputText} onChange={event => setOutputText(event.target.value)}></textarea>
                </div>
            </div>
        )
    }
    else if (operation ==='superset'){
        return(
            <div>
                <div className="py-2">
                    <button className="btn bg-blue-600 hover:bg-blue-800 text-white w-full rounded-full normal-case" onClick={startCalculateSupersets}>Show the supersets of {root}{mode}</button>
                </div>
                <div>
                    <textarea className="textarea textarea-bordered font-bold w-full h-60" value={outputText} onChange={event => setOutputText(event.target.value)}></textarea>
                </div>
            </div>
        )
    }
    else {
        return(
            <div>
                <div className="py-2">
                    <button className="btn bg-blue-600 hover:bg-blue-800 text-white w-full rounded-full normal-case" onClick={startCalculateSubsets}>Show the subsets of {root}{mode}</button>
                </div>
                <div>
                    <textarea className="textarea textarea-bordered font-bold w-full h-60" value={outputText} onChange={event => setOutputText(event.target.value)}></textarea>
                </div>
            </div>
        )
    }

}






function DetectChordName({notes}){
    const [outputText, setOutputText] = useState('')

    const startCalculate =() => { 
        if(notes ===''){
            setOutputText('Error: Enter comma-separated note names (Ex: A,B,C#).');
        }
        else {
            try {
                const notesArray = notes.replace(/\n\r/g, "").replace(/\n/g, "").split(" ").join("").replace(" ", "").split(",");
                console.log(notesArray);
                const chord = Chord.detect(notesArray);
                

                setOutputText(chord.join(', '));
            } catch (error) {
                setOutputText(error);
            }
        }
    
              
    }
    return(
        <div>
            <div className="py-2">
                <button className="btn bg-blue-600 hover:bg-blue-800 text-white w-full" onClick={startCalculate}>Detect chord</button>
            </div>
            <div>
                <textarea className="textarea textarea-bordered font-bold w-full" value={outputText} onChange={event => setOutputText(event.target.value)}></textarea>
            </div>
        </div>
    )
}



function TheoryChord () {
    const [rootText, setRootText] = useState('C') //default selected value
    const [modeText, setModeText] = useState('M') //default selected value
    return(
        <>

    <Helmet>
        <meta charSet="utf-8" />
        <title>Notes of a chord</title>
        <meta name="description" content="Generate the musical notes that constitute a chord" />              
        <meta name="keywords" content="music,note,chord,notes of a chord"/>
        <meta property="og:title" content="Notes of a chord" />        
        <meta property="og:description" content="Generate the musical notes that constitute a chord" />        
        <meta property="og:site_name" content="Notes of a chord" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
    </Helmet>

            <section className="text-base-content body-font   bg-base-100 dark:text-white relative">
                <div className="container px-5 py-1 mx-auto  max-w-lg">
                    <div className="flex flex-col text-justify w-full mb-12">
                    <h1 className="flex items-center justify-center  text-2xl  text-blue-500 mb-2"> <b>Chord Notes</b></h1>
                        <p className="text-base text-black-500 dark:text-white flex items-center justify-center">
                        A chord is the layering of several tones played simultaneously - usually built on superposed thirds. 
                        Chords are defined by their root note and their quality (major, minor, 7, etc) - and eventually by their inversion.
                        
                        </p>
                        
                    </div>
                </div>
            </section>
            <section className="text-base-content body-font  bg-base-100 dark:text-white relative">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                <h2 className=" flex items-center justify-center text-base font-bold mb-4 text-purple-500"> <u>Chord Notes Generator</u> </h2>
                <div className="flex flex-row py-2">
                    <div className="basis-1/2">
                        <div className="py-1 px-1">
                            <select className="select select-bordered w-full" value={rootText} onChange={event => setRootText(event.target.value)}>
                            <option value="A">A</option>
                            <option value="A#">A#</option>
                            <option value="B">B</option>
                            <option value="C">C</option>
                            <option value="C#">C#</option>
                            <option value="D">D</option>  
                            <option value="D#">D#</option>
                            <option value="E">E</option>
                            <option value="F">F</option>
                            <option value="F#">F#</option>
                            <option value="G">G</option>  
                            <option value="G#">G#</option>
                               
                            </select>
                        </div>
                    </div>
                    <div className="basis-1/2">
                        <div className="py-1">
                            <select className="select select-bordered w-full" value={modeText} onChange={event => setModeText(event.target.value)}>
                            <option value="M">M</option>
                            <option value="m">m</option>
                            <option value="5">5</option>
                            <option value="sus4">sus4</option>
                            <option value="aug">aug</option>
                            <option value="dim">dim</option>
                            <option value="7#5">7#5</option>
                            <option value="7b5">7b5</option>
                            <option value="maj7">maj7</option>
                            <option value="7">7</option>
                            <option value="M7b5">M7b5</option>
                            <option value="6">6</option>
                            <option value="m7#5">m7#5</option>
                            <option value="m7b5">m7b5</option>
                            <option value="mM7">mM7</option>
                            <option value="m7">m7</option>
                            <option value="m6">m6</option>
                            <option value="dim7">dim7</option>
                            <option value="sus2">sus2</option>
                            <option value="11">11</option>
                            <option value="9">9</option>
                            <option value="maj9">maj9</option>
                            <option value="maj13">maj13</option>
                            <option value="m9">m9</option>
                            <option value="m13">m13</option>
                            <option value="m11">m11</option>   
                            </select>
                        </div>
                    </div>
                </div>



                        <div>
                        <DisplayChordNotes root={rootText} mode={modeText} operation="notes"  ></DisplayChordNotes>  
                        </div>
                        <div className="py-6">
 
                        </div>
                        <p className="text-base text-black-500 dark:text-white flex items-center justify-center">
                        If you want to detect a chord from a collection of notes or find the supersets and subsets of a chord, 
                        please click on one of the links below:
                        </p>
                        <ul className="space-y-4 py-4 px-12 ">
                            <li className="flex items-center justify-center text-base">
                            <svg className="h-6 w-6 mr-1 flex-none fill-sky-100 stroke-sky-500 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <a className="text-sky-600 font-bold" href="/theory-chord-detect">Detect chord from notes</a>
                            </li>
                            <li className="flex items-center justify-center text-base">
                            <svg className="h-6 w-6 mr-1 flex-none fill-red-100 stroke-red-400 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                            <a className="text-red-400 font-bold" href="/theory-chord-superset">Supersets of a chord</a>
                            </li>
                            <li className="flex items-center justify-center text-base">
                            <svg className="h-6 w-6 mr-1 flex-none fill-purple-100 stroke-purple-400 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                            <a className="text-purple-600 font-bold" href="/theory-chord-subset">Subsets of a chord</a>
                            </li>


                        </ul>
                </div>
            </section>
        </>
    )
}


function TheoryChordSuperset () {
    const [rootText, setRootText] = useState('C') //default selected value
    const [modeText, setModeText] = useState('M') //default selected value
    return(
        <>

    <Helmet>
        <meta charSet="utf-8" />
        <title>Supersets of a chord</title>
        <meta name="description" content="Generate the supersets a chord" />              
        <meta name="keywords" content="music,note,chord,supersets of a chord"/>
        <meta property="og:title" content="Supersets of a chord" />        
        <meta property="og:description" content="Generate the supersets of a chord" />        
        <meta property="og:site_name" content="Supersets of a chord" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
    </Helmet>


            <section className="text-base-content body-font  bg-base-100 dark:text-white relative">
                <div className="container px-5 py-2 mx-auto  max-w-lg">
                    <div className="flex flex-col text-justify w-full mb-12">
                    <h1 className="flex items-center justify-center  text-2xl  text-blue-500 mb-2"> <b>Supersets of a chord</b></h1>
                        <p className="text-base text-black-500 dark:text-white flex items-center justify-center">
                        The supersets of a chord are all the chords with the same notes and at least one more. 
                        
                        </p>

                    </div>
                </div>
            </section>
            <section className="text-base-content body-font  bg-base-100 dark:text-white relative">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                <h2 className=" flex items-center justify-center text-base font-bold mb-4 text-purple-500"> <u>Chord Supersets Generator</u> </h2>
                <div className="flex flex-row py-2">
                    <div className="basis-1/2">
                        <div className="py-1 px-1">
                            <select className="select select-bordered w-full" value={rootText} onChange={event => setRootText(event.target.value)}>
                            <option value="A">A</option>
                            <option value="A#">A#</option>
                            <option value="B">B</option>
                            <option value="C">C</option>
                            <option value="C#">C#</option>
                            <option value="D">D</option>  
                            <option value="D#">D#</option>
                            <option value="E">E</option>
                            <option value="F">F</option>
                            <option value="F#">F#</option>
                            <option value="G">G</option>  
                            <option value="G#">G#</option>
                               
                            </select>
                        </div>
                    </div>
                    <div className="basis-1/2">
                        <div className="py-1">
                            <select className="select select-bordered w-full" value={modeText} onChange={event => setModeText(event.target.value)}>
                            <option value="M">M</option>
                            <option value="m">m</option>
                            <option value="5">5</option>
                            <option value="sus4">sus4</option>
                            <option value="aug">aug</option>
                            <option value="dim">dim</option>
                            <option value="7#5">7#5</option>
                            <option value="7b5">7b5</option>
                            <option value="maj7">maj7</option>
                            <option value="7">7</option>
                            <option value="M7b5">M7b5</option>
                            <option value="6">6</option>
                            <option value="m7#5">m7#5</option>
                            <option value="m7b5">m7b5</option>
                            <option value="mM7">mM7</option>
                            <option value="m7">m7</option>
                            <option value="m6">m6</option>
                            <option value="dim7">dim7</option>
                            <option value="sus2">sus2</option>
                            <option value="11">11</option>
                            <option value="9">9</option>
                            <option value="maj9">maj9</option>
                            <option value="maj13">maj13</option>
                            <option value="m9">m9</option>
                            <option value="m13">m13</option>
                            <option value="m11">m11</option> 
                               
                            </select>
                        </div>
                    </div>
                </div>



                        <div>
                        <DisplayChordNotes root={rootText} mode={modeText} operation="superset" ></DisplayChordNotes>  
                        </div>
                        <div className="py-6">
 
                        </div>
                        <p className="text-base text-black-500 dark:text-white flex items-center justify-center">
                        If you want to display the notes of chord, detect a chord from a collection of notes or find the subsets of a chord, 
                        please click on one of the links below:
                        </p>
                        <ul className="space-y-4 py-4 px-12">
                            <li className="text-base flex items-center justify-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-sky-100 stroke-sky-500 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <a className="text-sky-600 font-bold" href="/theory-chord-detect">Detect chord from notes</a>
                            </li>
                            <li className="text-base flex items-center justify-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-red-100 stroke-red-400 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                            <a className="text-red-400 font-bold" href="/theory-chord-subset">Subsets of a chord</a>
                            </li>
                            <li className="text-base flex items-center justify-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-purple-100 stroke-purple-400 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                            <a className="text-purple-600 font-bold" href="/theory-chord">Notes of a chord</a>
                            </li>
                        </ul>
                </div>
            </section>
        </>
    )
}


function TheoryChordSubset () {
    const [rootText, setRootText] = useState('C') //default selected value
    const [modeText, setModeText] = useState('M') //default selected value
    return(
        <>

    <Helmet>
        <meta charSet="utf-8" />
        <title>Subsets of a chord</title>
        <meta name="description" content="Generate the subsets a chord" />              
        <meta name="keywords" content="music,note,chord,subsets of a chord"/>
        <meta property="og:title" content="Subsets of a chord" />        
        <meta property="og:description" content="Generate the subsets of a chord" />        
        <meta property="og:site_name" content="Subsets of a chord" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
    </Helmet>


            <section className="text-base-content body-font  bg-base-100 dark:text-white relative">
                <div className="container px-5 py-2 mx-auto  max-w-lg">
                    <div className="flex flex-col text-justify w-full mb-12">
                    <h1 className="flex items-center justify-center  text-2xl  text-blue-500 mb-2"> <b>Subsets of a chord</b></h1>
                        <p className="text-base text-black-500 dark:text-white flex items-center justify-center">
                        The subsets of a chord are all the chords with fewer notes but all from the given chord.
                        </p>
                        
                    </div>
                </div>
            </section>
            <section className="text-base-content body-font  bg-base-100 dark:text-white relative">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                <h2 className=" flex items-center justify-center text-base font-bold mb-4 text-purple-500"> <u>Chord Subsets Generator</u> </h2>
                <div className="flex flex-row py-2">
                    <div className="basis-1/2">
                        <div className="py-1 px-1">
                            <select className="select select-bordered w-full" value={rootText} onChange={event => setRootText(event.target.value)}>
                            <option value="A">A</option>
                            <option value="A#">A#</option>
                            <option value="B">B</option>
                            <option value="C">C</option>
                            <option value="C#">C#</option>
                            <option value="D">D</option>  
                            <option value="D#">D#</option>
                            <option value="E">E</option>
                            <option value="F">F</option>
                            <option value="F#">F#</option>
                            <option value="G">G</option>  
                            <option value="G#">G#</option>
                               
                            </select>
                        </div>
                    </div>
                    <div className="basis-1/2">
                        <div className="py-1">
                            <select className="select select-bordered w-full" value={modeText} onChange={event => setModeText(event.target.value)}>
                            <option value="M">M</option>
                            <option value="m">m</option>
                            <option value="5">5</option>
                            <option value="sus4">sus4</option>
                            <option value="aug">aug</option>
                            <option value="dim">dim</option>
                            <option value="7#5">7#5</option>
                            <option value="7b5">7b5</option>
                            <option value="maj7">maj7</option>
                            <option value="7">7</option>
                            <option value="M7b5">M7b5</option>
                            <option value="6">6</option>
                            <option value="m7#5">m7#5</option>
                            <option value="m7b5">m7b5</option>
                            <option value="mM7">mM7</option>
                            <option value="m7">m7</option>
                            <option value="m6">m6</option>
                            <option value="dim7">dim7</option>
                            <option value="sus2">sus2</option>
                            <option value="11">11</option>
                            <option value="9">9</option>
                            <option value="maj9">maj9</option>
                            <option value="maj13">maj13</option>
                            <option value="m9">m9</option>
                            <option value="m13">m13</option>
                            <option value="m11">m11</option> 
                               
                            </select>
                        </div>
                    </div>
                </div>



                        <div>
                        <DisplayChordNotes root={rootText} mode={modeText} operation="subset" ></DisplayChordNotes>  
                        </div>
                        <div className="py-6">
 
                        </div>
                        <p className="text-base text-black-500 dark:text-white flex items-center justify-center">
                        If you want to display the notes of a chord, detect a chord from a collection of notes or find the supersets of a chord, 
                        please click on one of the links below:
                        </p>
                        <ul className="space-y-4 py-4 px-12">
                            <li className="text-base flex items-center justify-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-sky-100 stroke-sky-500 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <a className="text-sky-600 font-bold" href="/theory-chord-detect">Detect chord from notes</a>
                            </li>
                            <li className="text-base flex items-center justify-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-red-100 stroke-red-400 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                            <a className="text-red-400 font-bold" href="/theory-chord">Notes of a chord</a>
                            </li>
                            <li className="text-base flex items-center justify-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-purple-100 stroke-purple-400 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                            <a className="text-purple-600 font-bold" href="/theory-chord-superset">Supersets of a chord</a>
                            </li>


                        </ul>
                </div>
            </section>
        </>
    )
}





function TheoryChordDetect () {
    const [inputText, setInputText] = useState('')
    return(
        <>

    <Helmet>
        <meta charSet="utf-8" />
        <title>Detect chord from notes</title>
        <meta name="description" content="Detect chord name from a set of musical notes" />              
        <meta name="keywords" content="music,note,chord,chord name from musical notes"/>
        <meta property="og:title" content="Detect chord from notes" />        
        <meta property="og:description" content="Detect chord name from a set of musical notes" />        
        <meta property="og:site_name" content="Detect chord from notes" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
    </Helmet>

            <section className="text-base-content body-font relative  bg-base-100 dark:text-white ">
                <div className="container px-5 py-2 mx-auto  max-w-lg">
                    <div className="flex flex-col text-justify w-full mb-12">
                    <h1 className="flex items-center justify-center  text-2xl  text-blue-500 mb-2"> <b>Detect chord from notes</b></h1>
                        <p className="text-base text-black-500 dark:text-white flex items-center justify-center">
                        A group of notes (typically 3 or more) that are played together is called a chord. A Dyad is a set of 2 notes. 
                        Technically, they are not considered chords by strict music theory definition (which is 3 or more different pitches), however they can imply the character of chords from the interval between the notes.
                        A well-known dyad is a Power Chord, which is simply a Root Note together with another note that’s separated by a Perfect 5th interval.
                        Triads are the most basic form of a chord. They are comprised of 3-notes – root, 3rd interval, & 5th interval.
                        </p>
                        
                    </div>
                </div>
            </section>
            <section className="text-base-content body-font relative  bg-base-100 dark:text-white ">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                <h2 className=" flex items-center justify-center text-base font-bold mb-4 text-purple-500"> <u>Chord Detector</u> </h2>
                <div>
                    <input  type="text" placeholder="Type in coma-separated notes. Ex: A , B , C#, D, ..." className="input input-bordered w-full"  value={inputText} onChange={event => setInputText(event.target.value)}></input>
                </div>



                        <div>
                        <DetectChordName notes={inputText}  ></DetectChordName>  
                        </div>
                        <div className="py-6">
 
                        </div>
                        <p className="text-base text-black-500 dark:text-white flex items-center justify-center">
                        If you want to display the notes of a chord or find the supersets and subsets of a chord, 
                        please click on one of the links below:
                        </p>
                        <ul className="space-y-4 py-4 px-12">
                            <li className="text-base flex items-center justify-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-sky-100 stroke-sky-500 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <a className="text-sky-600 font-bold" href="/theory-chord">Notes of a chord</a>
                            </li>
                            <li className="text-base flex items-center justify-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-red-100 stroke-red-400 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                            <a className="text-red-400 font-bold" href="/theory-chord-superset">Supersets of a chord</a>
                            </li>
                            <li className="text-base flex items-center justify-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-purple-100 stroke-purple-400 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                            <a className="text-purple-600 font-bold" href="/theory-chord-subset">Subsets of a chord</a>
                            </li>


                        </ul>
                </div>
            </section>
        </>
    )
}



export {TheoryChord, TheoryChordDetect, TheoryChordSuperset, TheoryChordSubset};