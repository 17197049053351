import {React, useState, useEffect} from "react";
import {Helmet} from "react-helmet";
import CommonFooter from "./CommonFooter.jsx";

  
  
function AncientSong ({id, category,name}){
    return (
        <>
        <a className="flex justify-center items-center" href={"/score/ancient/"+category+"/"+id} key={id}>{name}</a> 
        <hr></hr>
        </>
    )
}


  function AncientCompositions({category, author, authorName=author}){
        const [searchItem, setSearchItem] = useState('')
        const [error, setError] = useState(null);
        const [isLoaded, setIsLoaded] = useState(false);
        const [items, setItems] = useState([]);
      
        useEffect(() => {
          const url = `https://goldfish-app-asx3v.ondigitalocean.app/allera/${category}/author/${author}`;
          fetch(url)
            .then(res => res.json())
            .then(
              (result) => {
                setIsLoaded(true);
                setItems(result);
              },
              // Note: it's important to handle errors here
              // instead of a catch() block so that we don't swallow
              // exceptions from actual bugs in components.
              (error) => {
                setIsLoaded(true);
                setError(error);
              }
            )
      
        }, [author, category])
      
        if (error) {
          return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
          return <div>Loading...</div>;
        } else {
      
        return (
          <>

              <Helmet>
                  <meta charSet="utf-8"/>
                  <title>{`Musical compositions of ${authorName}.`}</title>
                  <link rel="canonical" href={`https://alleramusic.com/${authorName.toLowerCase()}`}/>
                  <meta name="description" content={`Discover the works and musical masterpieces of ${authorName}.`}/>
                  <meta name="keywords" content="music,renaissance music,native american music"/>
                  <meta property="og:title" content={`Musical compositions of ${authorName}.`}/>
                  <meta property="og:description"
                        content={`Discover the works and musical masterpieces of ${authorName}.`}/>
                  <meta property="og:site_name" content={`Musical compositions of ${authorName}.`}/>
                  <meta property="og:locale" content="en_US"/>
                  <meta property="og:type" content="article"/>
              </Helmet>


              <div className="text-base-content bg-base-100 py-1 flex justify-center items-center">
            <h1 className="py-4 flex items-center justify-center text-2xl  text-blue-500"><b>{authorName} compositions</b></h1>
            </div>
            <div className="text-base-content bg-base-100 px-2 py-2 flex justify-center items-center">
              <input  type="text" placeholder="Search here" className="input input-bordered input-info w-full max-w-md"  value={searchItem} onChange={event => setSearchItem(event.target.value)}></input>
            </div>
            <div className="text-base-content bg-base-100 px-4 py-6">
              {items.filter((music) => //items here
              {
                if(searchItem === "") {
                  return music;
                }
                else if 
                (music.DisplayName.toLowerCase().includes(searchItem.toLowerCase())) {
                  return music;
                }
                else return null
                
              }).map(user => <AncientSong key={user.Id} id={user.Id} name={user.DisplayName} category={category}></AncientSong>)}
            </div>
            {/*<CommonFooter /> */}
          </>
        );}
  }

  export default AncientCompositions