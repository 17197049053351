import {React,  useState, useEffect} from "react";
import {Helmet} from "react-helmet";
import background6 from "../assets/images/about-shape-1.svg";
import {ImageMP3Display} from "./Progressions"
import CommonFooter from "./CommonFooter.jsx";

function BassGrooves() {
  const [searchItem, setSearchItem] = useState('')
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    fetch("https://goldfish-app-asx3v.ondigitalocean.app/allera/bass")
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )

  }, [])

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {

  const backgroundImage = `url(${background6})`;


  return (
    <>

        <Helmet>
            <meta charSet="utf-8"/>
            <title>Bass grooves</title>
            <link rel="canonical" href="https://alleramusic.com/bass/grooves"/>
            <meta name="description"
                  content="Our large collection of bass grooves consists of Pop, Rock, Blues, Funk, and Latin music bass lines."/>
            <meta name="keywords" content="bass,bass lines,bass grooves"/>
            <meta property="og:title" content="Bass grooves"/>
            <meta property="og:description"
                  content="Our large collection of bass grooves consists of Pop, Rock, Blues, Funk, and Latin music bass lines."/>
            <meta property="og:site_name" content="Bass grooves"/>
            <meta property="og:locale" content="en_US"/>
            <meta property="og:type" content="article"/>
        </Helmet>


        <div className="text-base-content py-2 flex justify-center items-center  bg-base-100 mainDiv" style={{backgroundImage: backgroundImage}}>
          

              <div className="py-4 lg:py-6">

        <div className="space-y-6 py-2 lg:py-4 text-base leading-7 text-black-500 dark:text-white px-6 lg:px-12">
        <h1 className="flex justify-center items-center  text-2xl  text-blue-500 "><b>Bass grooves</b></h1>
        <p className="text-black-500 dark:text-white text-base text-base-content">
              Bass grooves consist of an extensive collection of bass lines. The tracks are listed below. Each groove is displayed as a notation in tablature. You can play back each groove and download it in the midi and mp3 formats. The bass lines we gathered are usually found in musical styles such as 
                <span className="text-base font-bold"> Pop, Rock, Blues with walking bass lines, Funk with slap bass grooves, Latin music with Afro-Cuban, Mambo, Songo, Son Montuno grooves  and muted bass grooves</span>.
              </p>
              <p className="flex justify-center items-center text-black-500 dark:text-white text-base">The native application can be downloaded on: </p><br />
              <a className="flex justify-center items-center" href="https://play.google.com/store/apps/details?id=tntechnology.app.basslines" target="_blank" rel="noreferrer"><button className="btn btn-wide btn-outline btn-info">Google Playstore</button></a>
              <a className="flex justify-center items-center" href="https://www.microsoft.com/store/apps/9MV573T9VRX1" target="_blank" rel="noreferrer"><button className="btn btn-wide btn-info">Microsoft Store</button></a>
              </div>
        </div>

      </div>
      <div className="text-base-content bg-base-100 px-2 py-2 flex justify-center items-center">
        <input  type="text" placeholder="Search here" className="input input-bordered input-info w-full max-w-md"  value={searchItem} onChange={event => setSearchItem(event.target.value)}></input>
      </div>
      <div className="text-base-content bg-base-100 py-6 flex justify-center items-center">
        <ul className="menu menu-vertical px-1 w-full  max-w-lg">
          {items.filter((music) => //items here
              {
                if(searchItem === "") {
                  return music;
                }
                else if 
                (music.Name.toLowerCase().includes(searchItem.toLowerCase())) {
                  return music;
                }
                else return null
                
              }).map(chord => 
          <ImageMP3Display key={chord.Id} name={chord.Name} 
            imageFile={"https://arfusoftaudiofiles.s3.eu-central-1.amazonaws.com/basslines/"+chord.ImageFile+".png"} 
            mp3File={"https://arfusoftaudiofiles.s3.eu-central-1.amazonaws.com/basslines/"+chord.Mp3File+".mp3"}
            midiFile={"https://arfusoftaudiofiles.s3.eu-central-1.amazonaws.com/basslines/"+chord.MidiFile+".mid"} >

        </ImageMP3Display>)}
        </ul>

      </div>



      {/*<CommonFooter /> */}

    </>
  );}
  }
  export default  BassGrooves;