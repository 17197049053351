import React from "react";
import {Helmet} from "react-helmet";


function GuitarDescription(){
    return (
        <>

    <Helmet>
        <meta charSet="utf-8" />
        <title>Collection of guitar chords</title>
        <meta name="description" content="Learn major, minor, seventh, augmented, diminished, etc guitar chords." />              
        <meta name="keywords" content="music,chords,guitar chords,major chords,minor chords,seventh chords,augmented chords,diminished chords"/>
        <meta property="og:title" content="Collection of guitar chords" />        
        <meta property="og:description" content="Learn major, minor, seventh, augmented, diminished, etc guitar chords." />        
        <meta property="og:site_name" content="Collection of guitar chords" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
    </Helmet>

      
            <section className="text-gray-600 body-font relative bg-base-100 dark:text-white">
                <div className="container px-5 py-2 mx-auto">
                    <div className="flex flex-col text-justify w-full mb-12">
                    <h1 className="flex justify-center items-center text-2xl  text-blue-500 mb-4"><b>Guitar Chords</b></h1>
                    <p className="flex justify-center items-center text-base">
                    Guitar chords play a crucial role in understanding music for several reasons: <br />
                    &#9724; Foundation of Harmony: Chords are the building blocks of harmony in music. 
They provide the harmonic framework that supports melodies and gives songs their emotional tone. <br />
&#9724; Song Structure: Understanding chords helps you grasp the structure of songs. 
Most songs are built around chord progressions, which are sequences of chords that create a musical narrative. <br /><br />
Select a root note below to display all the guitar chords of that note.
                    </p>
                    </div>
                </div>
                <div className="py-6  px-2 flex flex-wrap justify-center items-center">
                    <a href="/theory-guitar" className="btn btn-sm m-1">A</a>
                    <a href="/theory-guitar-asharp" className="btn btn-sm m-1">A#</a>
                    <a href="/theory-guitar-b" className="btn btn-sm m-1">B</a>
                    <a href="/theory-guitar-c" className="btn btn-sm m-1">C</a>
                    <a href="/theory-guitar-csharp" className="btn btn-sm m-1">C#</a>
                    <a href="/theory-guitar-d" className="btn btn-sm m-1">D</a>
                    <a href="/theory-guitar-dsharp" className="btn btn-sm m-1">D#</a>
                    <a href="/theory-guitar-e" className="btn btn-sm m-1">E</a>
                    <a href="/theory-guitar-f" className="btn btn-sm m-1">F</a>
                    <a href="/theory-guitar-fsharp" className="btn btn-sm m-1">F#</a>
                    <a href="/theory-guitar-g" className="btn btn-sm m-1">G</a>
                    <a href="/theory-guitar-gsharp" className="btn btn-sm m-1">G#</a>
                </div>
            </section>
        </>
    )
}

function Achords(){

    return (
        <>
        < GuitarDescription />
            <section className="text-gray-600 body-font  bg-base-100  dark:bg-slate-600 dark:text-white">
                <div className="container px-5 py-4 mx-auto">
                    <div className="flex flex-wrap -m-4">
                        <div className="p-2 lg:w-1/4">
                            <div className="jtab">A</div>
                        </div>
                        <div className="p-2 lg:w-1/4">
                            <div className="jtab">A:3</div>
                        </div>
                        <div className="p-2 lg:w-1/4">
                            <div className="jtab">A7</div>
                        </div>
                        <div className="p-2 lg:w-1/4">
                            <div className="jtab">A7:3</div>
                        </div>
                        
                    </div>
                </div>
            </section>
            <section className="text-gray-600 body-font bg-base-100  dark:bg-slate-600 dark:text-white">
                <div className="container px-5 py-4 mx-auto">
                    <div className="flex flex-wrap -m-4">
                        <div className="p-2 lg:w-1/4">
                            <div className="jtab">Am</div>
                        </div>
                        <div className="p-2 lg:w-1/4">
                            <div className="jtab">Am:3</div>
                        </div>
                        <div className="p-2 lg:w-1/4">
                            <div className="jtab">Am7</div>
                        </div>
                        <div className="p-2 lg:w-1/4">
                            <div className="jtab">Am7:3</div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="text-gray-600 body-font bg-base-100  dark:bg-slate-600 dark:text-white">
                <div className="container px-5 py-4 mx-auto">
                    <div className="flex flex-wrap -m-4">
                        <div className="p-2 lg:w-1/4">
                            <div className="jtab">Amaj7</div>
                        </div>
                        <div className="p-2 lg:w-1/4">
                            <div className="jtab">A7b5</div>
                        </div>
                        <div className="p-2 lg:w-1/4">
                            <div className="jtab">A7#5</div>
                        </div>
                        <div className="p-2 lg:w-1/4">
                            <div className="jtab">Am7b5</div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="text-gray-600 body-font bg-base-100  dark:bg-slate-600 dark:text-white">
                <div className="container px-5 py-4 mx-auto">
                    <div className="flex flex-wrap -m-4">
                    <div className="p-2 lg:w-1/4">
                            <div className="jtab">A7b9</div>
                        </div>
                        <div className="p-2 lg:w-1/4">
                            <div className="jtab">A6</div>
                        </div>
                        <div className="p-2 lg:w-1/4">
                            <div className="jtab">Am6</div>
                        </div>
                        <div className="p-2 lg:w-1/4">
                            <div className="jtab">A69</div>
                        </div>

                    </div>
                </div>
            </section>
            <section className="text-gray-600 body-font bg-base-100  dark:bg-slate-600 dark:text-white">
                <div className="container px-5 py-4 mx-auto">
                    <div className="flex flex-wrap -m-4">
                    <div className="p-2 lg:w-1/4">
                            <div className="jtab">A9</div>
                        </div>
                        <div className="p-2 lg:w-1/4">
                            <div className="jtab">Am9</div>
                        </div>
                        <div className="p-2 lg:w-1/4">
                            <div className="jtab">Amaj9</div>
                        </div>
                        <div className="p-2 lg:w-1/4">
                            <div className="jtab">Aadd9</div>
                        </div>

                    </div>
                </div>
            </section>
            <section className="text-gray-600 body-font bg-base-100  dark:bg-slate-600 dark:text-white">
                <div className="container px-5 py-4 mx-auto">
                    <div className="flex flex-wrap -m-4">
                    <div className="p-2 lg:w-1/4">
                            <div className="jtab">Asus2</div>
                        </div>
                        <div className="p-2 lg:w-1/4">
                            <div className="jtab">Asus4</div>
                        </div>
                        <div className="p-2 lg:w-1/4">
                            <div className="jtab">Adim</div>
                        </div>
                        <div className="p-2 lg:w-1/4">
                            <div className="jtab">Adim7</div>
                        </div>

                    </div>
                </div>
            </section>
            <section className="text-gray-600 body-font bg-base-100  dark:bg-slate-600 dark:text-white">
                <div className="container px-5 py-4 mx-auto">
                    <div className="flex flex-wrap -m-4">
                    <div className="p-2 lg:w-1/4">
                            <div className="jtab">Aaug</div>
                        </div>
                        <div className="p-2 lg:w-1/4">
                            <div className="jtab">A13</div>
                        </div>
                        <div className="p-2 lg:w-1/4">
                            <div className="jtab"></div>
                        </div>
                        <div className="p-2 lg:w-1/4">
                            <div className="jtab"></div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

function ASharpchords(){
    return (
    <>
    < GuitarDescription />
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">A#</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">A#:3</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">A#7</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">A#7:3</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">A#m</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">A#m:3</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">A#m7</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">A#m7:3</div>
    </div>
    </div>
    </div>
    </section>
     <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">A#maj7</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">A#7b5</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">A#7#5</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">A#m7b5</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">A#7b9</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">A#6</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">A#m6</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">A#69</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">A#9</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">A#m9</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">A#maj9</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">A#add9</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">A#sus2</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">A#sus4</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">A#dim</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">A#dim7</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">A#aug</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">A#13</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab"></div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab"></div>
    </div>
    </div>
    </div>
    </section>
    </>
    )
    }
    
    
    
    function Bchords(){
    return (
    <>
    < GuitarDescription />
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">B</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">B:3</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">B7</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">B7:3</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Bm</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Bm:3</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Bm7</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Bm7:3</div>
    </div>
    </div>
    </div>
    </section>
     <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Bmaj7</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">B7b5</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">B7#5</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Bm7b5</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">B7b9</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">B6</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Bm6</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">B69</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">B9</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Bm9</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Bmaj9</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Badd9</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Bsus2</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Bsus4</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Bdim</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Bdim7</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Baug</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">B13</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab"></div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab"></div>
    </div>
    </div>
    </div>
    </section>
    </>
    )
    }
    
    
    
    function Cchords(){
    return (
    <>
    < GuitarDescription />
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">C</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">C:4</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">C7</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">C7:4</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Cm</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Cm:4</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Cm7</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Cm7:4</div>
    </div>
    </div>
    </div>
    </section>
     <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Cmaj7</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">C7b5</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">C7#5</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Cm7b5</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">C7b9</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">C6</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Cm6</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">C69</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">C9</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Cm9</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Cmaj9</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Cadd9</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Csus2</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Csus4</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Cdim</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Cdim7</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Caug</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">C13</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab"></div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab"></div>
    </div>
    </div>
    </div>
    </section>
    </>
    )
    }
    
    
    
    function CSharpchords(){
    return (
    <>
    < GuitarDescription />
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">C#</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">C#:4</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">C#7</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">C#7:4</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">C#m</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">C#m:4</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">C#m7</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">C#m7:4</div>
    </div>
    </div>
    </div>
    </section>
     <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">C#maj7</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">C#7b5</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">C#7#5</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">C#m7b5</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">C#7b9</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">C#6</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">C#m6</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">C#69</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">C#9</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">C#m9</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">C#maj9</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">C#add9</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">C#sus2</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">C#sus4</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">C#dim</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">C#dim7</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">C#aug</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">C#13</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab"></div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab"></div>
    </div>
    </div>
    </div>
    </section>
    </>
    )
    }
    
    
    
    function Dchords(){
    return (
    <>
    < GuitarDescription />
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">D</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">D:3</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">D7</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">D7:3</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Dm</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Dm:3</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Dm7</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Dm7:3</div>
    </div>
    </div>
    </div>
    </section>
     <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Dmaj7</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">D7b5</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">D7#5</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Dm7b5</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">D7b9</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">D6</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Dm6</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">D69</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">D9</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Dm9</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Dmaj9</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Dadd9</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Dsus2</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Dsus4</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Ddim</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Ddim7</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Daug</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">D13</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab"></div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab"></div>
    </div>
    </div>
    </div>
    </section>
    </>
    )
    }
    
    
    
    function DSharpchords(){
    return (
    <>
    < GuitarDescription />
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">D#</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">D#:3</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">D#7</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">D#7:3</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">D#m</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">D#m:3</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">D#m7</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">D#m7:3</div>
    </div>
    </div>
    </div>
    </section>
     <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">D#maj7</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">D#7b5</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">D#7#5</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">D#m7b5</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">D#7b9</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">D#6</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">D#m6</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">D#69</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">D#9</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">D#m9</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">D#maj9</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">D#add9</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">D#sus2</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">D#sus4</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">D#dim</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">D#dim7</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">D#aug</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">D#13</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab"></div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab"></div>
    </div>
    </div>
    </div>
    </section>
    </>
    )
    }
    
    
    
    function Echords(){
    return (
    <>
    < GuitarDescription />
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">E</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">E:4</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">E7</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">E7:4</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Em</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Em:4</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Em7</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Em7:4</div>
    </div>
    </div>
    </div>
    </section>
     <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Emaj7</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">E7b5</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">E7#5</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Em7b5</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">E7b9</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">E6</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Em6</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">E69</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">E9</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Em9</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Emaj9</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Eadd9</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Esus2</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Esus4</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Edim</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Edim7</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Eaug</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">E13</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab"></div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab"></div>
    </div>
    </div>
    </div>
    </section>
    </>
    )
    }
    
    
    
    function Fchords(){
    return (
    <>
    < GuitarDescription />
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">F</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">F:4</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">F7</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">F7:4</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Fm</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Fm:4</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Fm7</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Fm7:4</div>
    </div>
    </div>
    </div>
    </section>
     <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Fmaj7</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">F7b5</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">F7#5</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Fm7b5</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">F7b9</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">F6</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Fm6</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">F69</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">F9</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Fm9</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Fmaj9</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Fadd9</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Fsus2</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Fsus4</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Fdim</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Fdim7</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Faug</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">F13</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab"></div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab"></div>
    </div>
    </div>
    </div>
    </section>
    </>
    )
    }
    
    
    
    function FSharpchords(){
    return (
    <>
    < GuitarDescription />
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">F#</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">F#:4</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">F#7</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">F#7:4</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">F#m</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">F#m:4</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">F#m7</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">F#m7:4</div>
    </div>
    </div>
    </div>
    </section>
     <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">F#maj7</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">F#7b5</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">F#7#5</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">F#m7b5</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">F#7b9</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">F#6</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">F#m6</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">F#69</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">F#9</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">F#m9</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">F#maj9</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">F#add9</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">F#sus2</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">F#sus4</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">F#dim</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">F#dim7</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">F#aug</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">F#13</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab"></div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab"></div>
    </div>
    </div>
    </div>
    </section>
    </>
    )
    }
    
    
    
    function Gchords(){
    return (
    <>
    < GuitarDescription />
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">G</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">G:2</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">G7</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">G7:2</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Gm</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Gm:2</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Gm7</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Gm7:2</div>
    </div>
    </div>
    </div>
    </section>
     <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Gmaj7</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">G7b5</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">G7#5</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Gm7b5</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">G7b9</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">G6</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Gm6</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">G69</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">G9</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Gm9</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Gmaj9</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Gadd9</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Gsus2</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Gsus4</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Gdim</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Gdim7</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">Gaug</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">G13</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab"></div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab"></div>
    </div>
    </div>
    </div>
    </section>
    </>
    )
    }
    
    
    
    function GSharpchords(){
    return (
    <>
    < GuitarDescription />
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">G#</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">G#:2</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">G#7</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">G#7:2</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">G#m</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">G#m:2</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">G#m7</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">G#m7:2</div>
    </div>
    </div>
    </div>
    </section>
     <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">G#maj7</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">G#7b5</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">G#7#5</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">G#m7b5</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">G#7b9</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">G#6</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">G#m6</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">G#69</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">G#9</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">G#m9</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">G#maj9</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">G#add9</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">G#sus2</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">G#sus4</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">G#dim</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">G#dim7</div>
    </div>
    </div>
    </div>
    </section>
    <section className="text-gray-600 body-font bg-base-100 dark:text-white">
    <div className="container px-5 py-4 mx-auto">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/4">
    <div className="jtab">G#aug</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab">G#13</div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab"></div>
    </div>
    <div className="p-2 lg:w-1/4">
    <div className="jtab"></div>
    </div>
    </div>
    </div>
    </section>
    </>
    )
    }



export {Achords, ASharpchords, Bchords, Cchords, CSharpchords, Dchords,
     DSharpchords, Echords, Fchords, FSharpchords, Gchords, GSharpchords};