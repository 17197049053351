import React from "react";
import {Helmet} from "react-helmet";
import guitar_exploding from "../../assets/images/abstract-watercolor-guitar-exploding-with-colorful-motion-generated-by-ai_188544-19725.avif";
import treble_clef from "../../assets/images/texture-treble-clef-dark-background-isolated-generative-ai_169016-29581.jpg";
import red_note from "../../assets/images/3d-render-music-note-flyer-poster-illustration-design_460848-8305.jpg";
import abstract_music_stairs from "../../assets/images/3d-render-abstract-music-background-with-space-your-text-banner-flyer-poster-3d-illustration-design_460848-7085.jpg";
import blue_note from "../../assets/images/3d-render-music-note-illustration-design_460848-7770.jpg";
import acoustic_guitar from "../../assets/images/closeup-acoustic-guitar-dark-background-with-bokeh-lights_169016-43478.jpg";
import clef_dark from "../../assets/images/texture-treble-clef-dark-background-isolated-generative-ai_169016-29580.jpg";
import piano from "../../assets/images/night-performance-piano-near-flame-generative-ai_188544-7781.jpg";

function TheoryMain(){
    return(
        <>

    <Helmet>
        <meta charSet="utf-8" />
        <title>Core concepts of music theory</title>
        <meta name="description" content="Learn the core concepts of music theory such as notes, intervals, scales, and chords" />              
        <meta name="keywords" content="music,notes,intervals,scales,chords"/>
        <meta property="og:title" content="Core concepts of music theory" />        
        <meta property="og:description" content="Learn the core concepts of music theory such as notes, intervals, scales, and chords" />        
        <meta property="og:site_name" content="Core concepts of music theory" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
    </Helmet>


       
            {/**Description */}
            <section className="text-base-content  bg-base-100 body-font relative">
                <div className="container px-5 py-2 mx-auto">
                    <div className="flex flex-col text-justify w-full mb-12">
                    <h1 className="flex items-center justify-center text-2xl  text-blue-500 mb-2"><b>Music Theory </b></h1>
                        <p className="text-base text-base-content">
                           &bull; Learn the core concepts of music such as notes, intervals, scales and chords. 
                        <br />&bull;  Learn the <b>definitions</b> of terms such as <i>staff, clef, accidentals, note durations</i> are explained in simple terms.
                        <br />&bull; Learn about <b>scales</b>. You can enter a scale name (ex: C major) and get all the notes corresponding to that scale. 
                            You can also enter some notes and detect the scale corresponding to that collection of notes.
                        <br />&bull;  Learn about <b>Chords</b>. You can firstly enter a chord name (ex: Cm) and get all the notes corresponding to that chord.
                            Secondly, you can display chords similar to a given chord (superset and subset chords).
                            Finally, you can also enter some notes and detect the chord corresponding to that collection of notes.
                        <br />&bull;  Learn about <b>Intervals</b>. You can compute the interval between two notes.
                        <br />&bull;  Learn about musical <b>Notes</b>. You can compute the midi values and frequencies from note names.
                        Here is an exhaustive list of the topics covered: 
                        </p>
                        <ul className="space-y-4 py-4 px-12">
                            <li className="flex items-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-sky-100 stroke-sky-500 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <p className="text-black-500 dark:text-white text-base"> Definitions</p>
                            </li>
                            <li className="flex items-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-red-100 stroke-red-400 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <p className="text-black-500 dark:text-white text-base"> Scales</p>
                            </li>
                            <li className="flex items-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-purple-100 stroke-purple-400 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <p className="text-black-500 dark:text-white text-base"> Modes</p>
                            </li>
                            <li className="flex items-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-sky-100 stroke-sky-500 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <p className="text-black-500 dark:text-white text-base"> Chords</p>
                            </li>
                            <li className="flex items-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-red-100 stroke-red-400 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <p className="text-black-500 dark:text-white text-base"> Intervals</p>
                            </li>
                            <li className="flex items-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-purple-100 stroke-purple-400 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <p className="text-black-500 dark:text-white text-base"> Notes</p>
                            </li>
                            <li className="flex items-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-sky-100 stroke-sky-500 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <p className="text-black-500 dark:text-white text-base">Guitar Chords List</p>
                            </li>
                            <li className="flex items-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-red-100 stroke-red-400 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <p className="text-black-500 dark:text-white text-base">Piano Chords List</p>
                            </li>

                        </ul>
                        <p className="text-base">You will find below the sub-topics of Music Theory. Please select one of the options to <b>access</b> that page.</p>
                    </div>
                </div>
            </section>
            
            {/**Topics */}
            <section className="text-base-content  bg-base-100 dark:text-white">
                <div className="container bg-base-100 px-5 py-4 mx-auto">
                    <div className="flex flex-wrap -m-5">
                        <div className="p-4 lg:w-1/3">
                            {/**Definitions*/}
                            <div className="card shadow w-80 m-4 dark:bg-slate-800">
                                <figure>
                                    <img src={treble_clef} alt="definitions" />
                                </figure>
                                <div className="card-body flex items-center">
                                    <h2 className="card-title"> Definitions</h2>
                                    <p className="text-black-500 dark:text-white">
                                   
                                    </p>
                                    <a className="btn  bg-gray-500 hover:bg-gray-800  text-white btn-sm" href="/theory-definition">Access</a>
                                </div>
                            </div>
                        </div>
                        <div className="p-4 lg:w-1/3">
                            {/**Scales */}
                            <div className="card shadow w-80 m-4  dark:bg-slate-800">
                                <figure>
                                <img src={abstract_music_stairs} alt="scales" />
                                </figure>
                                <div className="card-body flex items-center">
                                    <h2 className="card-title">Scales</h2>
                                    <p className="text-black-500 dark:text-white">
                                   
                                    </p>
                                    <a className="btn bg-yellow-500 hover:bg-yellow-800 text-white btn-sm" href="/theory-scale">Access</a>
                                </div>
                            </div>
                        </div>

                        <div className="p-4 lg:w-1/3">
                            {/**Modes*/}
                            <div className="card shadow w-80 m-4 dark:bg-slate-800">
                                <figure>
                                <img src={blue_note} alt="modes" />
                                </figure>
                                <div className="card-body flex items-center">
                                    <h2 className="card-title">Modes</h2>
                                    <p className="text-black-500 dark:text-white">
                              
                                    </p>
                                    <a className="btn bg-blue-400 hover:bg-blue-800 text-white btn-sm" href="/theory-mode">Access</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="text-base-content body-font   bg-base-100 dark:text-white">
                <div className="container px-5 py-4 mx-auto">
                    <div className="flex flex-wrap -m-5">
                        <div className="p-4 lg:w-1/3">
                            {/**Chords*/}
                            <div className="card shadow w-80 m-4 dark:bg-slate-800">
                                <figure>
                                <img src={guitar_exploding} alt="chords" />
                                </figure>
                                <div className="card-body flex items-center">
                                    <h2 className="card-title">Chords</h2>
                                    <p className="text-black-500 dark:text-white">
                                 
                                    </p>
                                    <a className="btn bg-indigo-500 hover:bg-indigo-800 text-white btn-sm" href="/theory-chord">Access</a>
                                </div>
                            </div>
                        </div>
                        <div className="p-4 lg:w-1/3">
                            {/**Intervals */}
                            <div className="card shadow w-80 m-4 dark:bg-slate-800">
                                <figure>
                                <img src={clef_dark} alt="intervals" />
                                </figure>
                                <div className="card-body flex items-center">
                                    <h2 className="card-title">Intervals</h2>
                                    <p className="text-black-500 dark:text-white">
                            
                                    </p>
                                    <a className="btn bg-gray-500 hover:bg-gray-800 text-white btn-sm" href="/theory-interval">Access</a>
                                </div>
                            </div>
                        </div>

                        <div className="p-4 lg:w-1/3">
                            {/**Notes*/}
                            <div className="card shadow w-80 m-4 dark:bg-slate-800">
                                <figure>
                                <img src={red_note} alt="notes" />
                                </figure>
                                <div className="card-body flex items-center">
                                    <h2 className="card-title">Notes</h2>
                                    <p className="text-black-500 dark:text-white">
                           
                                    </p>
                                    <a className="btn bg-red-400 hover:bg-red-800 text-white btn-sm" href="/theory-note-tomidi">Access</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="text-base-content body-font   bg-base-100 dark:text-white">
                <div className="container px-5 py-4 mx-auto">
                    <div className="flex flex-wrap -m-5">
                        <div className="p-4 lg:w-1/3">
                            {/**Guitar Chords List*/}
                            <div className="card shadow w-80 m-4 dark:bg-slate-800">
                                <figure>
                                <img src={acoustic_guitar} alt="guitar" />
                                </figure>
                                <div className="card-body flex items-center">
                                    <h2 className="card-title">Guitar Chords List</h2>
                                    <p className="text-black-500 dark:text-white">
                                  
                                    </p>
                                    <a className="btn bg-teal-700 hover:bg-teal-800 text-white btn-sm" href="/theory-guitar">Access</a>
                                </div>
                            </div>
                        </div>
                        <div className="p-4 lg:w-1/3">
                            {/**Piano Chords List*/}
                            <div className="card shadow w-80 m-4 dark:bg-slate-800">
                                <figure>
                                <img src={piano} alt="piano" />
                                </figure>
                                <div className="card-body flex items-center">
                                    <h2 className="card-title">Piano Chords List</h2>
                                    <p className="text-black-500 dark:text-white">
                                 
                                    </p>
                                    <a className="btn bg-gray-700 hover:bg-gray-800 text-white btn-sm" href="/theory-piano">Access</a>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section>
        </>
    )
}
export default TheoryMain;
