import React from "react";
import GuitarTabPlayer from "./GuitarTabPlayer";
import {Helmet} from "react-helmet";

function ContemporaryScoreView() {
  return (
    <>

      <GuitarTabPlayer/>

    </>

  );
}
export default ContemporaryScoreView;