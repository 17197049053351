import {React, useRef,  useState, useEffect} from "react";
import { useParams } from 'react-router-dom';
import "../assets/css/guitarplayer.css";
import "../assets/fonts/Bravura.woff";
import "../assets/fonts/Bravura.otf";
import {Helmet} from "react-helmet";





function GPTAb({gpFile}){
    const wrapref = useRef(null);
    const mainref = useRef(null);
    const scrollref = useRef(null);
    const overlayref = useRef(null);
    //const trackitemsref = useRef(null);
    //const attracknameref = useRef(null);
    const tracklistref = useRef(null);
    const titleref = useRef(null);
    const artistref = useRef(null);

    //const tracktemplateref = useRef(null);
    const metroref = useRef(null);

    useEffect(() => {
            // load elements
            const wrapper = wrapref.current;
            const main = mainref.current;
          

                // initialize alphatab
                const settings = {
                    file: gpFile,
                    player: {
                    enablePlayer: true,
                    soundFont:
                        'https://cdn.jsdelivr.net/npm/@coderline/alphatab@latest/dist/soundfont/sonivox.sf2',
                    scrollElement: scrollref.current,
                    },
                };

                main.innerHTML = '';
                const alphaTab = window.alphaTab;
                const api = new alphaTab.AlphaTabApi(main, settings);

                
                // overlay logic
                const overlay = overlayref.current;
                api.renderStarted.on(() => {
                    overlay.style.display = 'flex';
                });
                api.renderFinished.on(() => {
                    overlay.style.display = 'none';
                });

                // track selector
                /*function createTrackItem(track) {
                    const trackItem = trackitemsref.current;
                    attracknameref.current.innerText = track.name;
                    trackItem.track = track;
                    trackItem.onclick = e => {
                    e.stopPropagation();
                    api.renderTracks([track]);
                    };
                    console.log(trackItem);
                    return trackItem;
                }*/

                function createTrackItem2(track) {

                    var myI = document.createElement('i');
                    myI.setAttribute('class', 'fas fa-guitar');
                    var mytrackIconDiv = document.createElement('div');
                    mytrackIconDiv.setAttribute('class', 'at-track-icon');
                    mytrackIconDiv.appendChild(myI);
                    
                    var mytrackNameDiv = document.createElement('div');
                    mytrackNameDiv.setAttribute('class', 'at-track-name text-sm');
                    mytrackNameDiv.innerText = track.name;
                    var mytrackDetailsDiv = document.createElement('div');
                    mytrackDetailsDiv.setAttribute('class', 'at-track-details');
                    mytrackDetailsDiv.appendChild(mytrackNameDiv);
                    
                    var myTrack = document.createElement('div');
                    myTrack.setAttribute('class', 'at-track');
                    myTrack.appendChild(mytrackIconDiv);
                    myTrack.appendChild(mytrackDetailsDiv);

                    
                    myTrack.track = track;
                    myTrack.onclick = e => {
                    e.stopPropagation();
                    api.renderTracks([track]);
                    };



                    return myTrack;
                }



                var trackList = tracklistref.current;
                //const trackList = tracktemplateref.current;
                api.scoreLoaded.on(score => {
                    // clear items
                    trackList.innerHTML = '';
                    // generate a track item for all tracks of the score
                    score.tracks.forEach((track) => {
                        //console.log(track);
                       // trackList.appendChild(createTrackItem(track));
                        trackList.appendChild(createTrackItem2(track));
                    });
                });

                api.renderStarted.on(() => {
                    // collect tracks being rendered
                    const tracks = new Map();
                    api.tracks.forEach((t) => {
                    tracks.set(t.index, t);
                    });
                    // mark the item as active or not
                    const trackItems = trackList.querySelectorAll('.at-track');
                    trackItems.forEach((trackItem) => {
                    if (tracks.has(trackItem.track.index)) {
                        trackItem.classList.add('active');
                    } else {
                        trackItem.classList.remove('active');
                    }
                    });

                    // Controls 
                    api.scoreLoaded.on(score => {
                    titleref.current.innerText = score.title;
                    artistref.current.innerText = score.artist;
                    });

                    const metronome = metroref.current;
                    metronome.onclick = () => {
                    metronome.classList.toggle('active');
                    if (metronome.classList.contains('active')) {
                        api.metronomeVolume = 1;
                    } else {
                        api.metronomeVolume = 0;
                    }
                    };

                    // main player controls
                    const playPause = wrapper.querySelector(
                    '.at-controls .at-player-play-pause'
                    );
                    const stop = wrapper.querySelector('.at-controls .at-player-stop');
                    playPause.onclick = e => {
                    if (e.target.classList.contains('disabled')) {
                        return;
                    }
                    api.playPause();
                    };
                    stop.onclick = e => {
                    if (e.target.classList.contains('disabled')) {
                        return;
                    }
                    api.stop();
                    };
                    api.playerReady.on(() => {
                    playPause.classList.remove('disabled');
                    stop.classList.remove('disabled');
                    });
                    api.playerStateChanged.on(e => {
                    const icon = playPause.querySelector('i.fas');
                    if (e.state === alphaTab.synth.PlayerState.Playing) {
                        icon.classList.remove('fa-play');
                        icon.classList.add('fa-pause');
                    } else {
                        icon.classList.remove('fa-pause');
                        icon.classList.add('fa-play');
                    }
                    });

                    // song position
                    function formatDuration(milliseconds) {
                    let seconds = milliseconds / 1000;
                    const minutes = (seconds / 60) | 0;
                    seconds = (seconds - minutes * 60) | 0;
                    return (
                        String(minutes).padStart(2, '0') +
                        ':' +
                        String(seconds).padStart(2, '0')
                    );
                    }

                    const songPosition = wrapper.querySelector('.at-song-position');
                    let previousTime = -1;
                    api.playerPositionChanged.on(e => {
                    // reduce number of UI updates to second changes.
                    const currentSeconds = (e.currentTime / 1000) | 0;
                    if (currentSeconds === previousTime) {
                        return;
                    }

                    songPosition.innerText =
                        formatDuration(e.currentTime) + ' / ' + formatDuration(e.endTime);
                    });
                });
    }, [gpFile])


    return (
        <>


                    <div className="flex justify-center items-center"><a href={gpFile}  download><button className="btn btn-ghost w-80"><ion-icon name="download-outline"></ion-icon> Download </button> </a></div>
                <div className='at-wrap' ref={wrapref}>
                    <div className='at-overlay' ref={overlayref}>
                        <div className='at-overlay-content'>Music sheet is loading</div>
                    </div> 

                    <div className='at-content'>
                        <div className='at-sidebar'>
                            <div className='at-sidebar-content'>
                                <div className='at-track-list' ref={tracklistref}></div>
                    
                            </div>
                        </div>
                        <div className='at-viewport' ref={scrollref}>
                            <div className='at-main' ref={mainref}></div>
                    
                    </div>
                </div>

                <div className='at-controls'>
                    <div className='at-controls-left'>
                        <a className='btn btn-ghost at-player-stop '>
                        <i className='fas fa-step-backward'></i>
                        </a>
                        <a className='btn btn-ghost at-player-play-pause '>
                        <i className='fas fa-play'></i>
                        </a>
                        <div className='at-song-position'>00:00 / 00:00</div>
                    </div>
                    <div className='at-controls-right'>
                        <a className='btn btn-ghost at-metronome' ref={metroref}>
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
                                <path d='M12,1.75L8.57,2.67L4.06,19.53C4.03,19.68 4,19.84 4,20C4,21.11 4.89,22 6,22H18C19.11,22 20,21.11 20,20C20,19.84 19.97,19.68 19.94,19.53L18.58,14.42L17,16L17.2,17H13.41L16.25,14.16L14.84,12.75L10.59,17H6.8L10.29,4H13.71L15.17,9.43L16.8,7.79L15.43,2.67L12,1.75M11.25,5V14.75L12.75,13.25V5H11.25M19.79,7.8L16.96,10.63L16.25,9.92L14.84,11.34L17.66,14.16L19.08,12.75L18.37,12.04L21.2,9.21L19.79,7.8Z' />
                            </svg>
                        </a>
                    </div>
                </div>
            </div>

                    {/*
                            <template id='at-track-template' ref={tracktemplateref}>
                                <div className='at-track' ref={trackitemsref}>
                                    <div className='at-track-icon'>
                                        <i className='fas fa-guitar'></i>
                                    </div>
                                    <div className='at-track-details'>
                                    <div className='at-track-name' ref={attracknameref}></div>
                                    </div>
                                </div>

                            </template>


                    */}
        </>
    )
}




function GuitarTabPlayer() {

    let { id } = useParams();
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);

    useEffect(() => {
                const fileID = parseInt(id);
                const url = `https://goldfish-app-asx3v.ondigitalocean.app/allera/contemporary/${fileID}`;
                
                fetch(url)
                  .then(res => res.json())
                  .then(
                    (result) => {
                      setIsLoaded(true);
                      setItems(result);
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                      setIsLoaded(true);
                      setError(error);
                    }
                  );   
        }, [id])// <-- }, [])  empty array means 'run once'

        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {

        const gproFile = 'https://arfusoftaudiofiles.s3.eu-central-1.amazonaws.com/contemporarygpxfiles/' +items.Name +'.gp';
    return (
        <div  className="z-20">

            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${items.Name}`} </title>
                <link rel="canonical" href={`https://alleramusic.com/score/contemporary/${id}`}/>
                <meta property="og:locale" content="en_US"/>
                <meta property="og:type" content="article"/>
                <meta property="og:title" content={`${items.Name}`}/>
            </Helmet>


            {
                <GPTAb
                key={items.Id}
                gpFile={gproFile}
                >
                </GPTAb>
            }

        </div>
    );}
  }
  export default GuitarTabPlayer;