import  {React}from "react";
import {Helmet} from "react-helmet";
import { Klavier } from 'klavier';
import { Chord, Midi} from "tonal";

function PianoDescription(){
    return (
        <>

    <Helmet>
        <meta charSet="utf-8" />
        <title>Collection of piano chords</title>
        <meta name="description" content="Learn major, minor, seventh, augmented, diminished, etc piano chords." />              
        <meta name="keywords" content="music,chords,piano chords,major chords,minor chords,seventh chords,augmented chords,diminished chords"/>
        <meta property="og:title" content="Collection of piano chords" />        
        <meta property="og:description" content="Learn major, minor, seventh, augmented, diminished, etc piano chords." />        
        <meta property="og:site_name" content="Collection of piano chords" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
    </Helmet>

      
            <section className="text-base-content body-font  bg-base-100 dark:text-white relative">
                <div className="container px-5 py-2 mx-auto">
                    <div className="flex flex-col text-justify w-full mb-12">
                    <h1 className="flex justify-center items-center text-2xl  text-blue-500 mb-4"><b>Piano Chords</b></h1>
                    <p className="flex justify-center items-center text-base">
                    Piano chords are fundamental to understanding music for several reasons: <br />
                    &#9724; Harmonic Foundation: Chords provide the harmonic context that supports melodies. 
They create the framework upon which songs are built, giving them depth and emotional resonance. <br />
&#9724; Music Theory: Learning chords introduces you to essential music theory concepts, such as scales, intervals, and chord construction. 
This knowledge is crucial for composing, improvising, and understanding the structure of music. <br />
&#9724; Sight Reading: Understanding chords can significantly improve your sight-reading skills. 
Recognizing chord patterns helps you read and play music more efficiently. <br />
&#9724; Technical Proficiency: 
Practicing chords enhances your finger strength, dexterity, and coordination, which are vital for playing more complex pieces. <br />
&#9724; Creativity and Improvisation: Knowing different chords and how they interact opens up creative possibilities. 
You can experiment with various chord progressions and voicings to create unique sounds and improvisations. <br />
&#9724; Accompaniment: Chords are essential for accompanying other instruments or voices. <br />
They provide the harmonic support that complements melodies and rhythms. <br /> 
By mastering piano chords, you not only become a better pianist but also gain a deeper understanding of music as a whole. <br /><br />
Select a root note below to display all the piano chords of that note.
                    </p>
                    </div>
                </div>
                <div className="py-6  px-2 flex flex-wrap justify-center items-center">
                    <a href="/theory-piano" className="btn btn-sm m-1">A</a>
                    <a href="/theory-piano-asharp" className="btn btn-sm m-1">A#</a>
                    <a href="/theory-piano-b" className="btn btn-sm m-1">B</a>
                    <a href="/theory-piano-c" className="btn btn-sm m-1">C</a>
                    <a href="/theory-piano-csharp" className="btn btn-sm m-1">C#</a>
                    <a href="/theory-piano-d" className="btn btn-sm m-1">D</a>
                    <a href="/theory-piano-dsharp" className="btn btn-sm m-1">D#</a>
                    <a href="/theory-piano-e" className="btn btn-sm m-1">E</a>
                    <a href="/theory-piano-f" className="btn btn-sm m-1">F</a>
                    <a href="/theory-piano-fsharp" className="btn btn-sm m-1">F#</a>
                    <a href="/theory-piano-g" className="btn btn-sm m-1">G</a>
                    <a href="/theory-piano-gsharp" className="btn btn-sm m-1">G#</a>
                </div>
            </section>
        </>
    )
}

function ThreeChord({noteName, rootNote}){
    const majorNotes = Chord.notes(`${noteName}`, `${rootNote}1`);
    const Note1 = Midi.toMidi(majorNotes[0]);
    const Note2 = Midi.toMidi(majorNotes[1]);
    const Note3 = Midi.toMidi(majorNotes[2]);
    return (
        <div className="card w-80 m-4">
            <div className="card-body flex items-center">
                <h2 className="card-title">{noteName}</h2>
                <Klavier noteRange={[24,48]}  activeNotes={[Note1,Note2, Note3]}/>
                <p>Notes: 
                    <span className="font-bold px-2">{majorNotes[0].replace('1','').replace('2','')}</span>,
                    <span className="font-bold px-2">{majorNotes[1].replace('1','').replace('2','')}</span>,
                    <span className="font-bold px-2">{majorNotes[2].replace('1','').replace('2','')}</span>
                    </p>
            </div>
        </div>
    )
    
    
}
function FourChord({noteName, rootNote}){
    const majorNotes = Chord.notes(`${noteName}`, `${rootNote}1`);
    const Note1 = Midi.toMidi(majorNotes[0]);
    const Note2 = Midi.toMidi(majorNotes[1]);
    const Note3 = Midi.toMidi(majorNotes[2]);
    const Note4 = Midi.toMidi(majorNotes[3]);
    return (
        <div className="card w-80 m-4">
            <div className="card-body flex items-center">
                <h2 className="card-title">{noteName}</h2>
                <Klavier noteRange={[24,48]}  activeNotes={[Note1,Note2, Note3, Note4]}/>
                <p>Notes: 
                    <span className="font-bold px-2">{majorNotes[0].replace('1','').replace('2','')}</span>,
                    <span className="font-bold px-2">{majorNotes[1].replace('1','').replace('2','')}</span>,
                    <span className="font-bold px-2">{majorNotes[2].replace('1','').replace('2','')}</span>,
                    <span className="font-bold px-2">{majorNotes[3].replace('1','').replace('2','')}</span>
                    </p>
            </div>
            
        </div>
    )
}

function AchordsP(){
    return (
        <>
        < PianoDescription />
            <section className="text-base-content body-font dark:text-white">
                <div className="container px-5 py-4 mx-auto bg-base-100">
                    <div className="flex flex-wrap -m-4">
                        <div className="p-2 lg:w-1/3"><ThreeChord noteName={"AM"}  rootNote={"A"}/></div>
                        <div className="p-2 lg:w-1/3"><ThreeChord noteName={"Am"}  rootNote={"A"}/></div>
                        <div className="p-2 lg:w-1/3"><FourChord noteName={"A7"}  rootNote={"A"}/></div>
                    </div>
                </div>
            </section>
            <section className="text-base-content body-font dark:text-white">
                <div className="container px-5 py-4 mx-auto bg-base-100">
                    <div className="flex flex-wrap -m-4">
                        <div className="p-2 lg:w-1/3"><FourChord noteName={"Amaj7"}  rootNote={"A"}/></div>
                        <div className="p-2 lg:w-1/3"><FourChord noteName={"Am7"}  rootNote={"A"}/></div>
                        <div className="p-2 lg:w-1/3"><FourChord noteName={"A6"}  rootNote={"A"}/></div>
                    </div>
                </div>
            </section>
            <section className="text-base-content body-font dark:text-white">
                <div className="container px-5 py-4 mx-auto bg-base-100">
                    <div className="flex flex-wrap -m-4">
                        <div className="p-2 lg:w-1/3"><FourChord noteName={"A7b5"}  rootNote={"A"}/></div>
                        <div className="p-2 lg:w-1/3"><FourChord noteName={"A7#5"}  rootNote={"A"}/></div>
                        <div className="p-2 lg:w-1/3"><FourChord noteName={"Am7b5"}  rootNote={"A"}/></div>
                    </div>
                </div>
            </section>
            <section className="text-base-content body-font dark:text-white">
                <div className="container px-5 py-4 mx-auto bg-base-100">
                    <div className="flex flex-wrap -m-4">
                        <div className="p-2 lg:w-1/3"><FourChord noteName={"Am7#5"}  rootNote={"A"}/></div>
                        <div className="p-2 lg:w-1/3"><ThreeChord noteName={"Asus4"}  rootNote={"A"}/></div>
                        <div className="p-2 lg:w-1/3"><FourChord noteName={"Am6"}  rootNote={"A"}/></div>
                    </div>
                </div>
            </section>
            <section className="text-base-content body-font dark:text-white">
                <div className="container px-5 py-4 mx-auto bg-base-100">
                    <div className="flex flex-wrap -m-4">
                        <div className="p-2 lg:w-1/3"><ThreeChord noteName={"Aaug"}  rootNote={"A"}/></div>
                        <div className="p-2 lg:w-1/3"><ThreeChord noteName={"Adim"}  rootNote={"A"}/></div>
                        <div className="p-2 lg:w-1/3"><FourChord noteName={"Adim7"}  rootNote={"A"}/></div>
                    </div>
                </div>
            </section>
        </>
    )
}

function ASharpchordsP(){
    return (
    <>
    < PianoDescription />
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"A#M"}  rootNote={"A#"}/></div>
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"A#m"}  rootNote={"A#"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"A#7"}  rootNote={"A#"}/></div>
    </div>
    </div>
    </section>
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><FourChord noteName={"A#maj7"}  rootNote={"A#"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"A#m7"}  rootNote={"A#"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"A#6"}  rootNote={"A#"}/></div>
    </div>
    </div>
    </section>
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><FourChord noteName={"A#7b5"}  rootNote={"A#"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"A#7#5"}  rootNote={"A#"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"A#m7b5"}  rootNote={"A#"}/></div>
    </div>
    </div>
    </section>
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><FourChord noteName={"A#m7#5"}  rootNote={"A#"}/></div>
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"A#sus4"}  rootNote={"A#"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"A#m6"}  rootNote={"A#"}/></div>
    </div>
    </div>
    </section>
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"A#aug"}  rootNote={"A#"}/></div>
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"A#dim"}  rootNote={"A#"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"A#dim7"}  rootNote={"A#"}/></div>
    </div>
    </div>
    </section>
    </>
    )
    }
    
    
    
    function BchordsP(){
    return (
    <>
    < PianoDescription />
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"BM"}  rootNote={"B"}/></div>
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"Bm"}  rootNote={"B"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"B7"}  rootNote={"B"}/></div>
    </div>
    </div>
    </section>
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><FourChord noteName={"Bmaj7"}  rootNote={"B"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"Bm7"}  rootNote={"B"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"B6"}  rootNote={"B"}/></div>
    </div>
    </div>
    </section>
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><FourChord noteName={"B7b5"}  rootNote={"B"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"B7#5"}  rootNote={"B"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"Bm7b5"}  rootNote={"B"}/></div>
    </div>
    </div>
    </section>
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><FourChord noteName={"Bm7#5"}  rootNote={"B"}/></div>
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"Bsus4"}  rootNote={"B"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"Bm6"}  rootNote={"B"}/></div>
    </div>
    </div>
    </section>
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"Baug"}  rootNote={"B"}/></div>
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"Bdim"}  rootNote={"B"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"Bdim7"}  rootNote={"B"}/></div>
    </div>
    </div>
    </section>
    </>
    )
    }
    
    
    
    function CchordsP(){
    return (
    <>
    < PianoDescription />
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"CM"}  rootNote={"C"}/></div>
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"Cm"}  rootNote={"C"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"C7"}  rootNote={"C"}/></div>
    </div>
    </div>
    </section>
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><FourChord noteName={"Cmaj7"}  rootNote={"C"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"Cm7"}  rootNote={"C"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"C6"}  rootNote={"C"}/></div>
    </div>
    </div>
    </section>
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><FourChord noteName={"C7b5"}  rootNote={"C"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"C7#5"}  rootNote={"C"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"Cm7b5"}  rootNote={"C"}/></div>
    </div>
    </div>
    </section>
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><FourChord noteName={"Cm7#5"}  rootNote={"C"}/></div>
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"Csus4"}  rootNote={"C"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"Cm6"}  rootNote={"C"}/></div>
    </div>
    </div>
    </section>
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"Caug"}  rootNote={"C"}/></div>
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"Cdim"}  rootNote={"C"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"Cdim7"}  rootNote={"C"}/></div>
    </div>
    </div>
    </section>
    </>
    )
    }
    
    
    
    function CSharpchordsP(){
    return (
    <>
    < PianoDescription />
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"C#M"}  rootNote={"C#"}/></div>
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"C#m"}  rootNote={"C#"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"C#7"}  rootNote={"C#"}/></div>
    </div>
    </div>
    </section>
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><FourChord noteName={"C#maj7"}  rootNote={"C#"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"C#m7"}  rootNote={"C#"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"C#6"}  rootNote={"C#"}/></div>
    </div>
    </div>
    </section>
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><FourChord noteName={"C#7b5"}  rootNote={"C#"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"C#7#5"}  rootNote={"C#"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"C#m7b5"}  rootNote={"C#"}/></div>
    </div>
    </div>
    </section>
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><FourChord noteName={"C#m7#5"}  rootNote={"C#"}/></div>
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"C#sus4"}  rootNote={"C#"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"C#m6"}  rootNote={"C#"}/></div>
    </div>
    </div>
    </section>
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"C#aug"}  rootNote={"C#"}/></div>
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"C#dim"}  rootNote={"C#"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"C#dim7"}  rootNote={"C#"}/></div>
    </div>
    </div>
    </section>
    </>
    )
    }
    
    
    
    function DchordsP(){
    return (
    <>
    < PianoDescription />
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"DM"}  rootNote={"D"}/></div>
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"Dm"}  rootNote={"D"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"D7"}  rootNote={"D"}/></div>
    </div>
    </div>
    </section>
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><FourChord noteName={"Dmaj7"}  rootNote={"D"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"Dm7"}  rootNote={"D"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"D6"}  rootNote={"D"}/></div>
    </div>
    </div>
    </section>
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><FourChord noteName={"D7b5"}  rootNote={"D"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"D7#5"}  rootNote={"D"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"Dm7b5"}  rootNote={"D"}/></div>
    </div>
    </div>
    </section>
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><FourChord noteName={"Dm7#5"}  rootNote={"D"}/></div>
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"Dsus4"}  rootNote={"D"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"Dm6"}  rootNote={"D"}/></div>
    </div>
    </div>
    </section>
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"Daug"}  rootNote={"D"}/></div>
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"Ddim"}  rootNote={"D"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"Ddim7"}  rootNote={"D"}/></div>
    </div>
    </div>
    </section>
    </>
    )
    }
    
    
    
    function DSharpchordsP(){
    return (
    <>
    < PianoDescription />
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"D#M"}  rootNote={"D#"}/></div>
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"D#m"}  rootNote={"D#"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"D#7"}  rootNote={"D#"}/></div>
    </div>
    </div>
    </section>
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><FourChord noteName={"D#maj7"}  rootNote={"D#"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"D#m7"}  rootNote={"D#"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"D#6"}  rootNote={"D#"}/></div>
    </div>
    </div>
    </section>
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><FourChord noteName={"D#7b5"}  rootNote={"D#"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"D#7#5"}  rootNote={"D#"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"D#m7b5"}  rootNote={"D#"}/></div>
    </div>
    </div>
    </section>
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><FourChord noteName={"D#m7#5"}  rootNote={"D#"}/></div>
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"D#sus4"}  rootNote={"D#"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"D#m6"}  rootNote={"D#"}/></div>
    </div>
    </div>
    </section>
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"D#aug"}  rootNote={"D#"}/></div>
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"D#dim"}  rootNote={"D#"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"D#dim7"}  rootNote={"D#"}/></div>
    </div>
    </div>
    </section>
    </>
    )
    }
    
    
    
    function EchordsP(){
    return (
    <>
    < PianoDescription />
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"EM"}  rootNote={"E"}/></div>
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"Em"}  rootNote={"E"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"E7"}  rootNote={"E"}/></div>
    </div>
    </div>
    </section>
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><FourChord noteName={"Emaj7"}  rootNote={"E"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"Em7"}  rootNote={"E"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"E6"}  rootNote={"E"}/></div>
    </div>
    </div>
    </section>
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><FourChord noteName={"E7b5"}  rootNote={"E"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"E7#5"}  rootNote={"E"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"Em7b5"}  rootNote={"E"}/></div>
    </div>
    </div>
    </section>
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><FourChord noteName={"Em7#5"}  rootNote={"E"}/></div>
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"Esus4"}  rootNote={"E"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"Em6"}  rootNote={"E"}/></div>
    </div>
    </div>
    </section>
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"Eaug"}  rootNote={"E"}/></div>
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"Edim"}  rootNote={"E"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"Edim7"}  rootNote={"E"}/></div>
    </div>
    </div>
    </section>
    </>
    )
    }
    
    
    
    function FchordsP(){
    return (
    <>
    < PianoDescription />
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"FM"}  rootNote={"F"}/></div>
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"Fm"}  rootNote={"F"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"F7"}  rootNote={"F"}/></div>
    </div>
    </div>
    </section>
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><FourChord noteName={"Fmaj7"}  rootNote={"F"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"Fm7"}  rootNote={"F"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"F6"}  rootNote={"F"}/></div>
    </div>
    </div>
    </section>
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><FourChord noteName={"F7b5"}  rootNote={"F"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"F7#5"}  rootNote={"F"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"Fm7b5"}  rootNote={"F"}/></div>
    </div>
    </div>
    </section>
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><FourChord noteName={"Fm7#5"}  rootNote={"F"}/></div>
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"Fsus4"}  rootNote={"F"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"Fm6"}  rootNote={"F"}/></div>
    </div>
    </div>
    </section>
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"Faug"}  rootNote={"F"}/></div>
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"Fdim"}  rootNote={"F"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"Fdim7"}  rootNote={"F"}/></div>
    </div>
    </div>
    </section>
    </>
    )
    }
    
    
    
    function FSharpchordsP(){
    return (
    <>
    < PianoDescription />
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"F#M"}  rootNote={"F#"}/></div>
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"F#m"}  rootNote={"F#"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"F#7"}  rootNote={"F#"}/></div>
    </div>
    </div>
    </section>
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><FourChord noteName={"F#maj7"}  rootNote={"F#"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"F#m7"}  rootNote={"F#"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"F#6"}  rootNote={"F#"}/></div>
    </div>
    </div>
    </section>
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><FourChord noteName={"F#7b5"}  rootNote={"F#"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"F#7#5"}  rootNote={"F#"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"F#m7b5"}  rootNote={"F#"}/></div>
    </div>
    </div>
    </section>
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><FourChord noteName={"F#m7#5"}  rootNote={"F#"}/></div>
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"F#sus4"}  rootNote={"F#"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"F#m6"}  rootNote={"F#"}/></div>
    </div>
    </div>
    </section>
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"F#aug"}  rootNote={"F#"}/></div>
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"F#dim"}  rootNote={"F#"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"F#dim7"}  rootNote={"F#"}/></div>
    </div>
    </div>
    </section>
    </>
    )
    }
    
    
    
    function GchordsP(){
    return (
    <>
    < PianoDescription />
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"GM"}  rootNote={"G"}/></div>
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"Gm"}  rootNote={"G"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"G7"}  rootNote={"G"}/></div>
    </div>
    </div>
    </section>
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><FourChord noteName={"Gmaj7"}  rootNote={"G"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"Gm7"}  rootNote={"G"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"G6"}  rootNote={"G"}/></div>
    </div>
    </div>
    </section>
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><FourChord noteName={"G7b5"}  rootNote={"G"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"G7#5"}  rootNote={"G"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"Gm7b5"}  rootNote={"G"}/></div>
    </div>
    </div>
    </section>
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><FourChord noteName={"Gm7#5"}  rootNote={"G"}/></div>
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"Gsus4"}  rootNote={"G"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"Gm6"}  rootNote={"G"}/></div>
    </div>
    </div>
    </section>
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"Gaug"}  rootNote={"G"}/></div>
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"Gdim"}  rootNote={"G"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"Gdim7"}  rootNote={"G"}/></div>
    </div>
    </div>
    </section>
    </>
    )
    }
    
    
    
    function GSharpchordsP(){
    return (
    <>
    < PianoDescription />
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"G#M"}  rootNote={"G#"}/></div>
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"G#m"}  rootNote={"G#"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"G#7"}  rootNote={"G#"}/></div>
    </div>
    </div>
    </section>
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><FourChord noteName={"G#maj7"}  rootNote={"G#"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"G#m7"}  rootNote={"G#"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"G#6"}  rootNote={"G#"}/></div>
    </div>
    </div>
    </section>
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><FourChord noteName={"G#7b5"}  rootNote={"G#"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"G#7#5"}  rootNote={"G#"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"G#m7b5"}  rootNote={"G#"}/></div>
    </div>
    </div>
    </section>
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><FourChord noteName={"G#m7#5"}  rootNote={"G#"}/></div>
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"G#sus4"}  rootNote={"G#"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"G#m6"}  rootNote={"G#"}/></div>
    </div>
    </div>
    </section>
    <section className="text-base-content body-font dark:text-white">
    <div className="container px-5 py-4 mx-auto bg-base-100">
    <div className="flex flex-wrap -m-4">
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"G#aug"}  rootNote={"G#"}/></div>
    <div className="p-2 lg:w-1/3"><ThreeChord noteName={"G#dim"}  rootNote={"G#"}/></div>
    <div className="p-2 lg:w-1/3"><FourChord noteName={"G#dim7"}  rootNote={"G#"}/></div>
    </div>
    </div>
    </section>
    </>
    )
    }
export {AchordsP, ASharpchordsP, BchordsP, CchordsP, CSharpchordsP, DchordsP,
    DSharpchordsP, EchordsP, FchordsP, FSharpchordsP, GchordsP, GSharpchordsP};
