import {React, useState }from "react";
import {Helmet} from "react-helmet";
import {Mode} from "tonal";

function DisplayModeNotes({root, mode}){
    const [outputText, setOutputText] = useState('')

    const startCalculate =() => { 
        setOutputText('');

        const notes = Mode.notes(mode, root);
        const triads = Mode.triads(mode, root);
        const result = `--Notes-- \n${notes.join(', ')} \n\n --Triads-- \n ${triads.join(', ')}`;
        setOutputText(result);
    
              
    }
    return(
        <div>
            <div className="py-2">
                <button className="btn bg-blue-600 hover:bg-blue-800 text-white w-full" onClick={startCalculate}>Show the notes of {root} {mode} mode</button>
            </div>
            <div>
                <textarea className="textarea textarea-bordered font-bold w-full h-60" value={outputText} onChange={event => setOutputText(event.target.value)}></textarea>
            </div>
        </div>
    )
}


function TheoryMode () {
    const [rootText, setRootText] = useState('C') //default selected value
    const [modeText, setModeText] = useState('dorian') //default selected value
    return(
        <>

    <Helmet>
        <meta charSet="utf-8" />
        <title>Notes of a musical mode</title>
        <meta name="description" content="Instantly get all the musical notes that constitute a mode." />              
        <meta name="keywords" content="music,musical mode,musical note"/>
        <meta property="og:title" content="Notes of a musical mode" />        
        <meta property="og:description" content="Instantly get all the musical notes that constitute a mode." />        
        <meta property="og:site_name" content="Notes of a musical mode" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
    </Helmet>


        
            <section className="text-base-content body-font  bg-base-100 dark:text-white relative">
                <div className="container px-5 py-2 mx-auto">
                    <div className="flex flex-col text-justify w-full mb-2">
                    <h1 className="flex items-center justify-center  text-2xl  text-blue-500 mb-2"> <b>Musical Mode</b></h1>
                        <h2 className=" flex items-center justify-center text-base mb-4 text-purple-500">Definition of a musical mode</h2>
                        <p  className="text-black-500 dark:text-white flex items-center justify-center text-base">
                        A mode is a scale characterized by a unique sequence of intervals. 
                        Unlike the more familiar major and minor scales, which are based on a specific pattern of whole and half steps, 
                        modes offer a variety of intervallic structures, giving each one a distinctive tonal quality. 
                        The main types of modes are diatonic, derived from the major scale, and non-diatonic, 
                        which include scales like the harmonic minor, melodic minor, and other synthetic modes.
                        </p>
                    </div>
                </div>
            </section>
            <section className="text-base-content body-font relative  bg-base-100 dark:text-white ">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                <h2 className=" flex items-center justify-center text-base font-bold mb-4 text-purple-500"> <u>Mode notes detector</u> </h2>
                <div className="flex flex-row py-2">
                    <div className="basis-1/2">
                        <div className="py-1 px-1">
                            <select className="select select-bordered w-full" value={rootText} onChange={event => setRootText(event.target.value)}>
                            <option value="A">A</option>
                            <option value="A#">A#</option>
                            <option value="B">B</option>
                            <option value="C">C</option>
                            <option value="C#">C#</option>
                            <option value="D">D</option>  
                            <option value="D#">D#</option>
                            <option value="E">E</option>
                            <option value="F">F</option>
                            <option value="F#">F#</option>
                            <option value="G">G</option>  
                            <option value="G#">G#</option>
                               
                            </select>
                        </div>
                    </div>
                    <div className="basis-1/2">
                        <div className="py-1">
                            <select className="select select-bordered w-full" value={modeText} onChange={event => setModeText(event.target.value)}>
                            <option value="dorian">dorian</option>
                            <option value="locrian">locrian</option>
                            <option value="ionian">ionian</option> 
                            <option value="phrygian">phrygian</option> 
                            <option value="lydian">lydian</option> 
                            <option value="mixolydian">mixolydian</option> 
                            <option value="aeolian">aeolian</option>
                            </select>
                        </div>
                    </div>
                </div>



                        <div>
                        <DisplayModeNotes root={rootText} mode={modeText}  ></DisplayModeNotes>  
                        </div>
                        <div className="py-24">
 
                        </div>
                </div>
            </section>
        </>
    )
}

export default TheoryMode;