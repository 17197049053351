import React from "react";
import AncientCompositions from "./AncientCompositions";

function Acoma() {
  return (
    <>
      <AncientCompositions category={'native'}  author={'acoma'} />
    </>
  );
}
function BritishColumbia() {
  return (
    <>
      <AncientCompositions category={'native'}   author={'britishcolumbia'} />
    </>
  );
}
function CheyenneandArapaho() {
  return (
    <>
      <AncientCompositions category={'native'}   author={'cheyenneandarapaho'} />
    </>
  );
}
function Chippewa1() {
  return (
    <>
      <AncientCompositions category={'native'}   author={'chippewa1'} />
    </>
  );
}
function Chippewa2() {
  return (
    <>
      <AncientCompositions category={'native'}   author={'chippewa2'} />
    </>
  );
}
function Choctaw() {
  return (
    <>
      <AncientCompositions category={'native'}   author={'choctaw'} />
    </>
  );
}
function Maidu() {
  return (
    <>
      <AncientCompositions category={'native'}   author={'maidu'} />
    </>
  );
}
function Mandan() {
  return (
    <>
      <AncientCompositions category={'native'}   author={'mandan'} />
    </>
  );
}
function Menominee() {
  return (
    <>
      <AncientCompositions category={'native'}   author={'menominee'} />
    </>
  );
}
function Nootka() {
  return (
    <>
      <AncientCompositions category={'native'}   author={'nootka'} />
    </>
  );
}
function NorthernUte() {
  return (
    <>
      <AncientCompositions category={'native'}   author={'northernute'} />
    </>
  );
}
function Papago() {
  return (
    <>
      <AncientCompositions category={'native'}   author={'papago'} />
    </>
  );
}
function Pawnee() {
  return (
    <>
      <AncientCompositions category={'native'}   author={'pawnee'} />
    </>
  );
}
function Seminole() {
  return (
    <>
      <AncientCompositions category={'native'}   author={'seminole'} />
    </>
  );
}
function Sioux() {
  return (
    <>
      <AncientCompositions category={'native'}   author={'sioux'} />
    </>
  );
}
function Yuman() {
  return (
    <>
      <AncientCompositions category={'native'}   author={'yuman'} />
    </>
  );
}
  export {
    Acoma,
    BritishColumbia,
    CheyenneandArapaho,
    Chippewa1,
    Chippewa2,
    Choctaw,
    Maidu,
    Mandan,
    Menominee,
    Nootka,
    NorthernUte,
    Papago,
    Pawnee,
    Seminole,
    Sioux,
    Yuman
  };