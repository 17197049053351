import React from "react";
import {Helmet} from "react-helmet";
import CommonFooter from "../CommonFooter.jsx";

import staff from "../../assets/images/music_staff.png";
import clef from "../../assets/images/music_clefs.png";
import note_duration from "../../assets/images/note_duration.gif";
import rest_duration from "../../assets/images/rest_duration.png";
import dotsties from "../../assets/images/rest_dots_ties.png";
import major_scale from "../../assets/images/major_scale.png";
import minor_scale from "../../assets/images/minor_scale.png";
import interval from "../../assets/images/music_intervals.png";
import melody from "../../assets/images/music_melody.jpg";
import accidental from "../../assets/images/music_accidentals.png";
import chord from "../../assets/images/music_chord.png";
import key_signature from "../../assets/images/keysign.jfif";
import progression_chords from "../../assets/images/Passamezzo_antico.png";

function TheoryDefinitions() {

    return (
      <>

    <Helmet>
        <meta charSet="utf-8" />
        <title>Definitions of the most common musical expressions</title>
        <meta name="description" content="Learn what is a staff, a clef, note duration, an accidental, scale, key signature, interval, etc." />              
        <meta name="keywords" content="music,staff,clef,note duration,accidental,scale,key signature,interval"/>
        <meta property="og:title" content="Definitions of the most common musical expressions" />        
        <meta property="og:description" content="Learn what is a staff, a clef, note duration, an accidental, scale, key signature, interval, etc." />        
        <meta property="og:site_name" content="Definitions of the most common musical expressions" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
    </Helmet>


       
        <div className="gradient-form h-full  place-items-center items-center justify-center text-base  text-base-content   bg-base-100 p-6 mainDiv">
          <h1 className="flex items-center justify-center  text-2xl  text-blue-500 mb-2"> <b>Definitions </b></h1>
          <h2 className="flex items-center justify-center">Learn more about the most popular musical terms and expressions such as clef, staff, scale, key signature, etc.</h2>

          <div className="flex items-center justify-center text-base" >

            <div className="block rounded-lg">
              <div className="g-0 lg:flex lg:flex-wrap">

                <div className="lg:w-6/12 w-full  md:h-1/2" >  
                  <div className="md:mx-6 md:p-12">

                    <div className="text-center" >
                      
                      <p className="mb-12 mt-1 pb-1 text-xl font-semibold"></p>
                    </div>
                  </div>
                </div>
                <div className="flex items-center rounded-b-lg lg:w-6/12 lg:rounded-r-lg lg:rounded-bl-none" 
              
                >
                  <div className="px-4 py-5 md:mx-6 md:p-12">
                    <h4 className="mb-6 text-xl font-semibold"><u>Staff and Clefs</u></h4>
                    <p className="text-base text-black-500 dark:text-white"> The <code className="text-base font-bold text-orange-600">staff </code>
                      is the foundation upon which notes are drown.<span className="text-base"> The modern staff comprises five lines and four spaces (see image). </span>
                      <span className="text-base">Every line or space on the staff represents a white key on the keyboard.</span>
                    </p><br />
                    <img className="mx-auto w-48" src={staff} loading="lazy" alt="logo" /> <br />
                    <p className="text-base text-black-500 dark:text-white"> A <code className="text-base font-bold text-orange-600">clef </code>
                    is a symbol at the beginning of the staff that specifies the pitch of the notes on the staff. <br></br>Different clefs are used, each with its own note as a starting point: 
                    Treble clef, Bass clef, Alto clef and Tenor clef (see image).
                    </p><br />
                    <img className="mx-auto w-48" src={clef} loading="lazy" alt="logo" />
                  </div>

                </div>
              </div>
            </div>
          </div>





          <div className="flex items-center justify-center rounded text-base" >

            <div className="block rounded-lg">
              <div className="g-0 lg:flex lg:flex-wrap">
              <div className="flex items-center rounded-b-lg lg:w-6/12 lg:rounded-l-lg lg:rounded-r-lg" 
                
                >
                  <div className="px-4 py-6 md:mx-6 md:p-12">
                    <h4 className="mb-6 text-xl font-semibold"><u>Note duration</u></h4>
                    <p className="text-base text-black-500 dark:text-white"> <code className="text-base font-bold text-orange-600">Note duration </code>
                     is how long a note lasts compared to a whole note. The duration, therefore, depends on the type of the note.
                    </p><br />
                    <img className="mx-auto w-80" src={note_duration} loading="lazy" alt="logo" /><br />
                    <p className="text-base text-black-500 dark:text-white"> A <code className="text-base font-bold text-orange-600">rest </code>
                    is an interval of silence in a piece of music, marked by a symbol indicating the length of the pause. Each rest symbol corresponds with a particular note value.
                    </p><br />
                    <img className="mx-auto w-48" src={rest_duration} loading="lazy" alt="logo" />
                  </div>

                </div>
                <div className="px-4 md:px-0 lg:w-6/12  w-full" >  
                  <div className="md:mx-6 md:p-12">

                    <div className="text-center">
                      <p className="mb-12 mt-1 pb-1 text-xl font-semibold"></p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>





          <div className="flex items-center justify-center text-base">

            <div className="block rounded-lg">
              <div className="g-0 lg:flex lg:flex-wrap">

                <div className="lg:w-6/12 w-full " >  
                  <div className="md:mx-6 md:p-12">

                    <div className="text-center">
                      <p className="mb-12 mt-1 pb-1 text-xl font-semibold"></p>
                    </div>
                  </div>
                </div>
                <div className="flex items-center rounded-b-lg lg:w-6/12 lg:rounded-r-lg lg:rounded-bl-none" 
                
                >
                  <div className="px-4 py-5 md:mx-6 md:p-12">
                    <h4 className="mb-6 text-xl font-semibold"><u>Dots and ties</u></h4>
                    <p className="text-base text-black-500 dark:text-white"> A <code className="text-base font-bold text-orange-600">dot </code>
                    added to a note increases the duration of that note by half. 
                    
                      <span className="text-base"> A second dot represents half the value of the first dot or a quarter of the original duration. (These are known as “double-dotted notes.”)</span>
                    </p><br />
                    <p className="text-base text-black-500 dark:text-white"> A <code className="text-base font-bold text-orange-600">tie </code>
                    links two notes together to create a new duration. Ties occur between notes of the same pitch.
                    </p><br />
                    <img className="mx-auto w-48" src={dotsties} loading="lazy" alt="logo" />
                  </div>

                </div>
              </div>
            </div>
          </div>





          <div className="flex items-center justify-center rounded text-base">

            <div className="block rounded-lg">
              <div className="g-0 lg:flex lg:flex-wrap">
              <div className="flex items-center rounded-b-lg lg:w-6/12 lg:rounded-l-lg lg:rounded-r-lg" 
    
                >
                  <div className="px-4 py-6 md:mx-6 md:p-12">
                    <h4 className="mb-6 text-xl font-semibold"><u>Accidentals</u></h4>
                    <p className="text-base text-black-500 dark:text-white"> <code className="text-base font-bold text-orange-600">Accidentals </code>
                    are characters that can be placed before notes to raise or lower them.<span className="text-base"> The sharp symbol (♯) raises a pitch a half step. </span>
                      <span className="text-base">The flat symbol (♭) lowers a pitch a half step. </span><br />
                      <span className="text-base">The double sharp symbol (𝄪) raises a pitch two half steps or a whole step. </span><br />
                      <span className="text-base">The double flat symbol (𝄫) lowers a pitch two half steps or a whole step. </span><br />
                      <span className="text-base">The natural symbol (♮) cancels out any other accidentals that may have occurred <br /> earlier in a measure or Major Key Signatures or Minor Key Signatures. </span>
                    </p><br />
                    <img className="mx-auto w-48" src={accidental} loading="lazy" alt="logo" />
                  </div>

                </div>
                <div className="px-4 md:px-0 lg:w-6/12  w-full " >  
                  <div className="md:mx-6 md:p-12">

                    <div className="text-center">
                      <p className="mb-12 mt-1 pb-1 text-xl font-semibold"></p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>






          <div className="flex items-center justify-center text-base">

          <div className="block rounded-lg">
            <div className="g-0 lg:flex lg:flex-wrap">

              <div className="lg:w-6/12 mainDiv  w-full " >  
                <div className="md:mx-6 md:p-12">

                  <div className="text-center">
                    <p className="mb-12 mt-1 pb-1 text-xl font-semibold"></p>
                  </div>
                </div>
              </div>
              <div className="flex items-center rounded-b-lg lg:w-6/12 lg:rounded-r-lg lg:rounded-bl-none" 

              >
                <div className="px-4 py-5 md:mx-6 md:p-12">
                  <h4 className="mb-6 text-xl font-semibold"><u>Scales and key signatures</u></h4>
                  <p className="text-base text-black-500 dark:text-white"> A <code className="text-base font-bold text-orange-600">scale </code>
                  is a series of notes ordered by pitch. The notes in a scale belong together. <br /> They are often used as a basis for melodies and chords in music.
                  <span className="text-base">  The modern staff comprises five lines and four spaces. </span>
                    <span className="text-base">Every line or space on the staff represents a white key on the keyboard.</span>
                  </p><br />
                  <p  className="text-base text-black-500 dark:text-white"><i><b>Notes of major scales</b></i></p>
                  <img className="mx-auto w-48" src={major_scale} loading="lazy" alt="logo" /><br />
                  <p  className="text-base text-black-500 dark:text-white"><i><b>Notes of minor scales</b></i></p>
                  <img className="mx-auto w-48" src={minor_scale} loading="lazy" alt="logo" /><br />
                  <p className="text-base text-black-500 dark:text-white"> A <code className="text-base font-bold text-orange-600">Key signature </code>
                  is the arrangement of sharp or flat signs on particular lines and spaces of a musical staff <br /><span className="text-base">to indicate  that the corresponding notes, in every octave, are to be consistently raised (by sharps) or lowered (by flats) from their natural pitches </span>
                  (see image).</p><br />
                  <img className="mx-auto w-96" src={key_signature} loading="lazy" alt="logo" />
                </div>

              </div>
            </div>
          </div>
          </div>





          <div className="flex items-center justify-center rounded text-base">

          <div className="block rounded-lg">
            <div className="g-0 lg:flex lg:flex-wrap">
            <div className="flex items-center rounded-b-lg lg:w-6/12 lg:rounded-l-lg lg:rounded-r-lg" 
              >
                <div className="px-4 py-6 md:mx-6 md:p-12">
                  <h4 className="mb-6 text-xl font-semibold"><u>Intervals and melody</u></h4>
                  <p className="text-base text-black-500 dark:text-white"> <code className="text-base font-bold text-orange-600">Intervals </code>
                  <span className="text-base"> are a measurement between two pitches, either vertically or horizontally. When measuring vertically, we refer to harmonic intervals because the two notes sound simultaneously. <br /> When measuring horizontally, we refer to melodic intervals because the notes occur one after the other. </span>
                    <span className="text-base">Every line or space on the staff represents a white key on the keyboard.</span>
                  </p><br />
                  <img className="mx-auto w-80" src={interval} loading="lazy" alt="logo" /><br />
                  <p className="text-base text-black-500 dark:text-white"> A <code className="text-base font-bold text-orange-600">melody </code>
                  is a linear sequence of notes in a song or composition. The two basic elements of music that define melody are pitch and rhythm. Melody is a succession of pitches in rhythm (see image). 
                  
                  </p><br />
                  <img className="mx-auto w-48" src={melody} loading="lazy" alt="logo" />

                </div>

              </div>
              <div className="px-4 md:px-0 lg:w-6/12  w-full " >  
                <div className="md:mx-6 md:p-12">

                  <div className="text-center">
                    <p className="mb-12 mt-1 pb-1 text-xl font-semibold"></p>
                  </div>
                </div>
              </div>

            </div>
          </div>
          </div>



          <div className="flex items-center justify-center text-base">

          <div className="block rounded-lg">
            <div className="g-0 lg:flex lg:flex-wrap">

              <div className="lg:w-6/12 mainDiv  w-full " >  
                <div className="md:mx-6 md:p-12">

                  <div className="text-center">
                    <p className="mb-12 mt-1 pb-1 text-xl font-semibold"></p>
                  </div>
                </div>
              </div>
              <div className="flex items-center rounded-b-lg lg:w-6/12 lg:rounded-r-lg lg:rounded-bl-none" 

              >
                <div className="px-4 py-5 md:mx-6 md:p-12">
                  <h4 className="mb-6 text-xl font-semibold"><u>Chords and harmonic progressions</u></h4>
                  <p className="text-base text-black-500 dark:text-white"> A <code className="text-base font-bold text-orange-600">chord </code>
                  is two or more harmonic notes played at the same time. Most basic chords are built using three notes. <br />
                  Chords are built on their root note (the starting note). The rest of the notes in a chord are determined by the chord quality. 
                  </p><br />
                  <img className="mx-auto w-48" src={chord} loading="lazy" alt="logo" /><br />
                  <p className="text-base text-black-500 dark:text-white"> A <code className="text-base font-bold text-orange-600">harmonic progression </code>
                  is a logical movement from one chord to another to create the structural foundation and movement of a work in Western Classical Music.
                  </p><br />
                  <img className="mx-auto w-80" src={progression_chords} loading="lazy" alt="logo" />
                </div>

              </div>
            </div>
          </div>
          </div>





        </div>
  {/** <CommonFooter /> */}
        


      </>
  
    );
  }
  export default   TheoryDefinitions;