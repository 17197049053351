import {React, useState, useEffect} from "react";
import {Helmet} from "react-helmet";
import background6 from "../assets/images/about-shape-1.svg";
import { useParams } from 'react-router-dom';
import CommonFooter from "./CommonFooter.jsx";
  
  

function ContemporarySong ({id, name}){
  return (
      <>
      <a className="flex justify-center items-center text-sm" href={"/score/contemporary/"+id} key={id}>{name}</a> 
      <hr></hr>
      </>
  )
}


function ContemporarySongs() {
  const backgroundImage = `url(${background6})`;
  //var users = [];
  let { category } = useParams();
    

  const [searchItem, setSearchItem] = useState('')
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    const url = `https://goldfish-app-asx3v.ondigitalocean.app/allera/contemporary/author/${category}`;
    fetch(url)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )

  }, [category])

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {

  /*if (users.length ===0){
    return (
      <>
          <div className="flex justify-center items-center"> 
    <p className="text-black-500 dark:text-white">No song matches this search parameter !</p> 
    </div>
    <div className="flex justify-center items-center py-12"> 
    <p className="text-black-500 dark:text-white"> <a className="btn btn-info" href="/contemporary/songs/m">Go back</a></p>
    </div>
      </>

    )
  }*/
  return (
    <>

        <Helmet>
            <meta charSet="utf-8"/>
            <title>{`Contemporary musical compositions starting with the letter ${category}`}</title>
            <link rel="canonical" href={`https://alleramusic.com/contemporary/songs/${category}`}/>
            <meta name="description"
                  content="Discover thousands of modern music compositions covering the period of the 80s till now."/>
            <meta name="keywords" content="music,contemporary music,pop,rock,blues"/>
            <meta property="og:title" content="Contemporary musical compositions."/>
            <meta property="og:description"
                  content="Discover thousands of modern music compositions covering the period of the 80s till now."/>
            <meta property="og:site_name" content="Contemporary musical compositions."/>
            <meta property="og:locale" content="en_US"/>
            <meta property="og:type" content="article"/>
        </Helmet>


        <div className="text-base-content  py-2  flex justify-center items-center  bg-base-100 mainDiv" style={{backgroundImage: backgroundImage}}>
          <div className="py-4 lg:py-6">

          <div className="space-y-6 py-2 lg:py-4 text-base leading-7 text-black-500 dark:text-white px-6 lg:px-12">
          <h1 className="  flex justify-center items-center  text-2xl  text-blue-500 "><b>Modern Music</b></h1>
                  <p className="text-black-500 dark:text-white text-base">
                  Modern or contemporary music means the music types currently being written, recorded, and performed. Its genres include blues, country, electronic/dance, experimental, folk, funk, hip-hop, jazz, metal, pop, rock, roots, and world.
                    <span className="text-base"> Because we want contemporary Music to be open to everyone, we have thousands of modern music compositions covering the period of the 80s till now. All the compositions starting with that character are displayed when you click on a letter, such as </span>
                    <code className="text-sm font-bold"> A, L, I, J, </code>etc. You can select a song to play back or download in the Guitar Pro format (.gp).
                  </p>
    
                </div>






          {/*<div className="md:mx-auto md:max-w-lg md:rounded-lg">
            <div className="mx-auto px-4">
              
              <div className="divide-y divide-gray-300/50">

              </div>
            </div>
  </div>*/}
        </div>
    
      </div>

      <div className="text-base-content bg-base-100 flex justify-center items-center">
          <ul className="menu menu-horizontal px-1">
            <li><div className="px-2"><a className="btn btn-outline btn-circle btn-xs" href="/contemporary/songs/a">A</a></div></li>
            <li><div className="px-2"><a className="btn btn-outline btn-circle btn-xs" href="/contemporary/songs/i">I</a></div></li>
            <li><div className="px-2"><a className="btn btn-outline btn-circle btn-xs" href="/contemporary/songs/j">J</a></div></li>
            <li><div className="px-2"><a className="btn btn-outline btn-circle btn-xs" href="/contemporary/songs/k">K</a></div></li>
            <li><div className="px-2"><a className="btn btn-outline btn-circle btn-xs" href="/contemporary/songs/l">L</a></div></li>
            <li><div className="px-2"><a className="btn btn-outline btn-circle btn-xs" href="/contemporary/songs/m">M</a></div></li>
            <li><div className="px-2"><a className="btn btn-outline btn-circle btn-xs" href="/contemporary/songs/n">N</a></div></li>
            <li><div className="px-2"><a className="btn btn-outline btn-circle btn-xs" href="/contemporary/songs/o">O</a></div></li>
            <li><div className="px-2"><a className="btn btn-outline btn-circle btn-xs" href="/contemporary/songs/p">P</a></div></li>
            <li><div className="px-2"><a className="btn btn-outline btn-circle btn-xs" href="/contemporary/songs/q">Q</a></div></li>
            <li><div className="px-2"><a className="btn btn-outline btn-circle btn-xs" href="/contemporary/songs/r">R</a></div></li>
            <li><div className="px-2"><a className="btn btn-outline btn-circle btn-xs" href="/contemporary/songs/s">S</a></div></li>
            <li><div className="px-2"><a className="btn btn-outline btn-circle btn-xs" href="/contemporary/songs/t">T</a></div></li>
            <li><div className="px-2"><a className="btn btn-outline btn-circle btn-xs" href="/contemporary/songs/u">U</a></div></li>
            <li><div className="px-2"><a className="btn btn-outline btn-circle btn-xs" href="/contemporary/songs/v">V</a></div></li>
            <li><div className="px-2"><a className="btn btn-outline btn-circle btn-xs" href="/contemporary/songs/w">W</a></div></li>
            <li><div className="px-2"><a className="btn btn-outline btn-circle btn-xs" href="/contemporary/songs/x">X</a></div></li>
            <li><div className="px-2"><a className="btn btn-outline btn-circle btn-xs" href="/contemporary/songs/y">Y</a></div></li>
            <li><div className="px-2"><a className="btn btn-outline btn-circle btn-xs" href="/contemporary/songs/z">Z</a></div></li>
          </ul>
        </div>


      <div className="text-base-content bg-base-100 px-2 py-2 flex justify-center items-center">
        <input  type="text" placeholder="Search here" className="input input-bordered input-info w-full max-w-md"  value={searchItem} onChange={event => setSearchItem(event.target.value)}></input>
      </div>

      <div className="text-base-content bg-base-100 px-4 py-6">
        
        {
        items.filter((music) => //items here
        {
          if(searchItem === "") {
            return music;
          }
          else if 
          (music.Name.toLowerCase().includes(searchItem.toLowerCase())) {
            return music;
          }
          else return null
          
        }).map(user => <ContemporarySong key={user.Id} id={user.Id} name={user.Name}></ContemporarySong>)
        }
      </div>
      {/*<CommonFooter /> */}
    </>
  );}
  }
  export default  ContemporarySongs;