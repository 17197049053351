import React, { useState } from "react";

import mozart from"../assets/images/mozart.jpg";
import beethoven from"../assets/images/ludwig-van-beethoven-.jpg";
import agricola from"../assets/images/agricola.jfif";
import josquin from"../assets/images/josquin.jfif";
import theory from"../assets/images/theory-removebg-preview.png";
import fordownload from"../assets/images/fordownload-removebg-preview.png";
import "../assets/css/titlestyle.css";
import HomeFooter from "./HomeFooter.jsx";

import orchestra from "../assets/images/bostonsymphonicorchestra.webp";
import {Helmet} from "react-helmet";


//lazy loading div background image
//https://azuladev.medium.com/make-a-lazy-loading-div-background-image-in-react-without-any-third-party-libraries-using-50b6b71a05a6
function LazyBackgroundImage({img}) {
     const [loaded, setLoaded] = useState(false);

     const handleLoad = () => {
       setLoaded(true);
     };

    
  return (
    <div className="absolute top-0 w-full h-full bg-center bg-cover"
      style={{
        backgroundImage: `url(${img})`,
        filter: loaded ? "none" : "blur(20px)",
        transition: "filter 0.5s",
      }}
    >
      <img src={img} alt="" onLoad={handleLoad} style={{ display: "none" }} />
      <span id="blackOverlay" className="w-full h-full absolute opacity-75 bg-black"></span>
      {loaded}
    </div>
  );
}


function HomeDescription(){
  return (
    <>
<div className="text-base-content  py-4  flex justify-center items-center  bg-base-100 mainDiv text-base">
  <div className="container py-2 lg:py-4">
    <div className="text-base leading-7 text-black-500 dark:text-white px-6 lg:px-12">
      <h2 className="flex justify-center items-center text-3xl m-4 pb-6 text-blue-400 ">
        <b>The Significance of Music</b>
      </h2>

      <p className="text-base"> 
      Music is a universal language that transcends cultures and eras. 
      It has the power to evoke emotions, tell stories, and connect people from all walks of life. 
      Throughout human history, music has played a vital role in religious ceremonies, cultural celebrations, 
      emotional expression, and the advancement of artistic innovation.
      Whether you're listening to a classical symphony, tapping your feet to a pop song, 
      or immersed in the rhythms of indigenous folk music, the profound impact of music on the human experience is undeniable. 
      Music has the capacity to soothe the soul, energize the body, and stimulate the mind.
      </p>
            
      <h2 className="flex justify-center items-center text-3xl m-4 pb-6 text-blue-400 ">
        <b>The Value of Understanding Music Theory</b>
      </h2>

      <p className="text-base"> 
      To truly appreciate and engage with the depth and complexity of music, 
      it is essential to explore the core principles of music theory. 
      By studying the fundamental elements that shape musical compositions, 
      you can unlock a deeper understanding of how music is constructed and the emotional resonance it evokes.
At its core, music theory examines the building blocks of music, including: <br />
&bull; Melody: The sequence of single notes that form a tune or song, often serving as the most memorable and recognizable aspect of a musical piece. <br />
&bull; Rhythm: The timing and duration of sounds, including beat, meter, and tempo, which provide the foundation for a musical work's energetic flow. <br />
&bull; Harmony: The simultaneous sounding of different pitches, creating chords and chord progressions that underpin the harmonic structure of a composition. <br />
&bull; Chords: Three or more notes played together, forming the vertical foundation of music and shaping the overall mood and tonality. <br />
&bull; Notation: The written representation of music, including the staff, clefs, and symbols that convey pitch, rhythm, and other musical instructions. <br />
By delving into these core concepts, you'll gain the tools to analyze, interpret, and create music with greater depth and intention. This understanding not only enhances your appreciation of music but also empowers you to become a more skilled and versatile musician, composer, or listener.
      </p>
      <h2 className="flex justify-center items-center text-3xl m-4 pb-6 text-blue-400 ">
        <b>Exploring the Diverse Tapestry of Musical Traditions</b>
      </h2>

      <p className="text-base"> 
      Our website's exploration of music extends beyond the foundational elements, venturing into the rich diversity of musical styles and compositions. From the timeless masterpieces of the classical canon to the rhythmic traditions of Native American music, each genre and culture offers a unique perspective on the power of music to express the human experience.
      Whether you're a seasoned music enthusiast or a curious beginner, this comprehensive guide to music theory and its applications will inspire you to embark on a transformative journey through the world of sound. Prepare to be captivated by the depth, nuance, and universal appeal of this enduring art form.
      </p>
    </div>



  </div>
</div>


    </>
  )
}


function Home() {

 // const bostonorchestra = `url('${orchestra}')`;
  return (
    <>


      <Helmet>
        <meta charSet="utf-8"/>
        <title>All Era Music Home page</title>
        <link rel="canonical" href="https://alleramusic.com"/>
        <meta name="description"
              content="Learn music theory and access thousands of chord progressions, music rhythms, bass lines, music melodies, Classical music, Native American music and music from the Renaissance era."/>
        <meta name="keywords"
              content="music,melodies,rhythms,music theory,scale,chord progressions,bass lines,classical music,native american music"/>
        <meta property="og:title" content="All Era Music Home page"/>
        <meta property="og:description"
              content="Learn music theory and access thousands of chord progressions, music rhythms, bass lines, music melodies, Classical music, Native American music and music from the Renaissance era."/>
        <meta property="og:site_name" content="All Era Music Home page"/>
        <meta property="og:locale" content="en_US"/>
        <meta property="og:type" content="article"/>
      </Helmet>

      <main>
        <div className="relative pt-16 pb-32 flex content-center items-center justify-center"
            style={{
              minHeight: "75vh"
            }}>
         {/**
                   <div className="absolute top-0 w-full h-full bg-center bg-cover"     
                        style={{
                backgroundImage: bostonorchestra, 
                
              }} >
            <span id="blackOverlay" className="w-full h-full absolute opacity-75 bg-black"></span>
          </div>
          * 
          */} 
          <LazyBackgroundImage img={orchestra}>
          
          </LazyBackgroundImage>

          <div className="container relative mx-auto">
              <div className="items-center flex flex-wrap">
                <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                  <div className="pr-12">
                    <h1 className="text-white font-semibold text-5xl">
                      Music from all eras for all ages
                    </h1>
                    <p className="mt-4 text-lg text-gray-300">
                      Learn music theory and access thousands of chord progressions, music rhythms, bass lines, music melodies, classical music, and music from the Renaissance era.
                    </p>
                  </div>
                </div>

              </div>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
            style={{ height: "70px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden bg-base-100"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-none bg-base-100"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </div>


        <section className="pb-20  text-base-content bg-base-100 -mt-24">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">

              <div className="lg:pt-12 pt-6 w-full md:w-3/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-base-200  w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-400">
                    <svg xmlns="http://www.w3.org/2000/svg" className="ionicon fill-white dark:fill-none" viewBox="0 0 512 512" ><path d="M400 48H112a64.07 64.07 0 00-64 64v288a64.07 64.07 0 0064 64h288a64.07 64.07 0 0064-64V112a64.07 64.07 0 00-64-64zm-35.75 138.29l-134.4 160a16 16 0 01-12 5.71h-.27a16 16 0 01-11.89-5.3l-57.6-64a16 16 0 1123.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0124.5 20.58z"/></svg>
                    </div>
                    <h2 className="text-xl font-semibold">Chord progressions</h2>
                    <p className="text-base font-light leading-relaxed mt-2 mb-4">
                    A collection of chord progressions covering various musical styles.
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-3/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words  bg-base-200 w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-purple-400">
                    <svg xmlns="http://www.w3.org/2000/svg" className="ionicon fill-white dark:fill-none" viewBox="0 0 512 512" ><path d="M256 464c-114.69 0-208-93.31-208-208S141.31 48 256 48s208 93.31 208 208-93.31 208-208 208z"/></svg>
                    </div>
                    <h2 className="text-xl font-semibold">
                    Music rhythms
                    </h2>
                    <p className="text-base font-light leading-relaxed mt-2 mb-4">
                    A collection of music rhythms covering musical styles such as Pop, Rock, Country and many more.
                    </p>
                  </div>
                </div>
              </div>

              <div className="lg:pt-12 pt-6 w-full md:w-3/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words  bg-base-200 w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-400">
                    <svg xmlns="http://www.w3.org/2000/svg" className="ionicon fill-white dark:fill-none" viewBox="0 0 512 512" ><path d="M400 48H112a64.07 64.07 0 00-64 64v288a64.07 64.07 0 0064 64h288a64.07 64.07 0 0064-64V112a64.07 64.07 0 00-64-64zm-35.75 138.29l-134.4 160a16 16 0 01-12 5.71h-.27a16 16 0 01-11.89-5.3l-57.6-64a16 16 0 1123.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0124.5 20.58z"/></svg>
                    </div>
                    <h2 className="text-xl font-semibold">
                      Bass grooves
                    </h2>
                    <p className="text-base font-light leading-relaxed mt-2 mb-4">
                    A large collection of bass lines usually found in various musical styles.
                    </p>
                  </div>
                </div>
              </div>


              <div className="w-full md:w-3/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words  bg-base-200 w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-purple-400">
                    <svg xmlns="http://www.w3.org/2000/svg" className="ionicon fill-white dark:fill-none" viewBox="0 0 512 512" ><path d="M256 464c-114.69 0-208-93.31-208-208S141.31 48 256 48s208 93.31 208 208-93.31 208-208 208z"/></svg>
                    </div>
                    <h2 className="text-xl font-semibold">
                      Classical music
                    </h2>
                    <p className="text-base font-light leading-relaxed mt-2 mb-4">
                    A massive collection of compositions from the greatest classical music composers of all time.
                    </p>
                  </div>
                </div>
              </div>



            </div>
            <HomeDescription   />

            <div className="flex flex-wrap items-center mt-32 text-base-content bg-base-10">


              
              <div className="w-full md:w-5/12 px-4 mr-auto ml-auto">
                <div className="p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-blue-400">
                <svg xmlns="http://www.w3.org/2000/svg" className="ionicon fill-white dark:fill-none" viewBox="0 0 512 512" ><path d="M421.84 37.37a25.86 25.86 0 00-22.6-4.46L199.92 86.49A32.3 32.3 0 00176 118v226c0 6.74-4.36 12.56-11.11 14.83l-.12.05-52 18C92.88 383.53 80 402 80 423.91a55.54 55.54 0 0023.23 45.63A54.78 54.78 0 00135.34 480a55.82 55.82 0 0017.75-2.93l.38-.13 21.84-7.94A47.84 47.84 0 00208 423.91v-212c0-7.29 4.77-13.21 12.16-15.07l.21-.06L395 150.14a4 4 0 015 3.86v141.93c0 6.75-4.25 12.38-11.11 14.68l-.25.09-50.89 18.11A49.09 49.09 0 00304 375.92a55.67 55.67 0 0023.23 45.8 54.63 54.63 0 0049.88 7.35l.36-.12 21.84-7.95A47.83 47.83 0 00432 375.92V58a25.74 25.74 0 00-10.16-20.63z"/></svg>
                </div>
                <h2 className="text-3xl mb-2 font-semibold leading-normal  text-blue-400 ">
                   Music theory
                </h2>
                <p className="text-base font-light leading-relaxed mt-4 mb-4 ">
                Music theory is the study of the fundamental elements of music, including pitch, rhythm, harmony, and melody. It provides a framework for understanding how music is constructed, organized, and experienced.
At its core, music theory explores: <br />

&bull; Pitch: The highness or lowness of a sound, determined by the frequency of sound waves. <br />
&bull; Rhythm: The timing and duration of sounds, including beat, meter, and tempo. <br />
&bull; Harmony: The simultaneous sounding of different pitches, creating chords and chord progressions. <br />
&bull; Melody: The succession of single pitches that form a tune or song.
                </p>

              </div>

              <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 rounded-lg">
                  <img
                    alt="..."
                    src={theory}
                    className="w-full align-middle rounded-t-lg"
                  />

                </div>
              </div>

            </div>
          </div>
        </section>











        <section className="pt-20 pb-48 text-base-content bg-base-100">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap justify-center text-center mb-24">
              <div className="w-full lg:w-6/12 px-4">
                <h2 className="text-4xl font-semibold text-blue-400">
                  Composers
                </h2>
                <p className="text-base leading-relaxed m-4 ">
                  Here are some composers whose works are part of classical music and Renaissance music compositions.
                </p>
              </div>
            </div>
            <div className="flex flex-wrap">
              <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="..."
                    src={mozart}
                    className="shadow-lg rounded-full max-w-full mx-auto"
                    style={{ maxWidth: "120px" }}
                  />
                  <div className="pt-6 text-center">
                    <h3 className="text-xl font-bold">
                      Mozart
                    </h3>
                  </div>
                </div>
              </div>


              <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="..."
                    src={beethoven}
                    className="shadow-lg rounded-full max-w-full mx-auto"
                    style={{ maxWidth: "120px" }}
                  />
                  <div className="pt-6 text-center">
                    <h3 className="text-xl font-bold">
                      Beethoven
                    </h3>
                  </div>
                </div>
              </div>


              <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="..."
                    src={josquin}
                    className="shadow-lg rounded-full max-w-full mx-auto"
                    style={{ maxWidth: "120px" }}
                  />
                  <div className="pt-6 text-center">
                    <h3 className="text-xl font-bold">
                      Josquin
                    </h3>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="..."
                    src={agricola}
                    className="shadow-lg rounded-full max-w-full mx-auto"
                    style={{ maxWidth: "120px" }}
                  />
                  <div className="pt-6 text-center">
                    <h3 className="text-xl font-bold">
                      Agricola
                    </h3>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>


        <section className="relative py-20 text-base-content bg-base-100">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style={{ height: "80px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden bg-base-100"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-none bg-base-100"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4">
            <div className="items-center flex flex-wrap">
              <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                <img
                  alt="..."
                  className="max-w-full rounded-lg"
                  src={fordownload}
                />
              </div>
              <div className="w-full md:w-5/12 ml-auto mr-auto px-4 py-6">
                <div className="md:pr-12">
                  <div className="p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-purple-400">
                  <svg xmlns="http://www.w3.org/2000/svg" className="ionicon fill-white dark:fill-none" viewBox="0 0 512 512" ><path d="M376 160H272v153.37l52.69-52.68a16 16 0 0122.62 22.62l-80 80a16 16 0 01-22.62 0l-80-80a16 16 0 0122.62-22.62L240 313.37V160H136a56.06 56.06 0 00-56 56v208a56.06 56.06 0 0056 56h240a56.06 56.06 0 0056-56V216a56.06 56.06 0 00-56-56zM272 48a16 16 0 00-32 0v112h32z"/></svg>
                  </div>
                  <h3 className="text-3xl font-semibold text-blue-400">
                  Download
                  </h3>
                  <p className="mt-4 text-base leading-relaxed">
                  Music is displayed as a notation in tablature. You can play back each composition as well as download it in the following formats : 
                  </p>
                  <ul className="list-none mt-6">
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-white bg-purple-400 mr-3">
                          <i className='fas fa-volume-down'></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="">
                            mp3 
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-white bg-purple-400 mr-3">
                          <i className='fas fa-volume-down'></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="">midi</h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block  py-1 px-2 uppercase rounded-full text-white bg-purple-400 mr-3">
                          <i className='fas fa-volume-down'></i>
                          </span>
                          
                        </div>
                        <div>
                        <p className="">gp (guitar pro)</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>





        <section className="pb-20 relative block  text-base-content bg-base-100">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style={{ height: "80px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden bg-base-100"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-none bg-base-100"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4 lg:pt-24 lg:pb-64">
            <div className="flex flex-wrap text-center justify-center">
              <div className="w-full lg:w-6/12 px-4">
                <h2 className="text-4xl font-semibold text-blue-500">
                  Roadmap
                </h2>
                <p className="text-lg leading-relaxed mt-4 mb-4">
                  Our website will soon integrate the following topics:
                </p>
              </div>
            </div>
            <div className="flex flex-wrap mt-12 justify-center">
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"  height="18" 
	 viewBox="0 0 500 500" enableBackground="new 0 0 500 500">
<g fill="#00000">
	
		<rect x="294.4" y="115.2" transform="matrix(-0.9387 -0.3446 0.3446 -0.9387 650.1656 356.6668)" fill="#00000" width="124.7" height="10.7"/>
	<polygon fill="#00000" points="411,153.8 293.9,110.8 281.3,145.4 398.4,188.3 	"/>
	
		<rect x="273.2" y="173.2" transform="matrix(-0.9387 -0.3446 0.3446 -0.9387 588.9542 461.8746)" fill="#00000" width="124.7" height="10.7"/>
	
		<rect x="104.9" y="115.3" transform="matrix(0.9387 -0.3446 0.3446 0.9387 -31.3302 65.0347)" fill="#00000" width="124.7" height="10.7"/>
	
		<rect x="115.4" y="131.2" transform="matrix(-0.9387 0.3446 -0.3446 -0.9387 396.2407 228.7773)" fill="#00000" width="124.7" height="36.8"/>
	
		<rect x="183.1" y="116.2" transform="matrix(-0.3446 -0.9387 0.9387 -0.3446 85.8618 416.9727)" fill="#00000" width="10.7" height="124.7"/>
	<path fill="#00000" d="M262,204.7c-60.2,0-109.1,48.9-109.1,109.1S201.8,422.9,262,422.9s109.1-48.9,109.1-109.1
		C371.2,253.6,322.1,204.7,262,204.7z"/>
	<path fill="#00000" d="M262,182.1c-72.8,0-131.7,58.9-131.7,131.7s59,131.7,131.7,131.7c72.8,0,131.7-59,131.7-131.7
		C393.7,241,334.7,182.1,262,182.1z M262,432.1c-65.3,0-118.3-53-118.3-118.3s53-118.3,118.3-118.3s118.3,53,118.3,118.3
		C380.3,379.1,327.3,432.1,262,432.1z"/>
	<path fill="#00000" d="M378.1,221.3H500c0-4.2-27.3-7.7-60.9-7.7C405.4,213.4,378.1,216.9,378.1,221.3z"/>
	<path fill="#00000" d="M439.1,235.9c33.7,0,60.9-3.5,60.9-7.7H378.1C378.1,232.4,405.4,235.9,439.1,235.9z"/>
	<polygon fill="#00000" points="435.8,422.1 412.5,445.6 423.7,445.6 439.8,429.4 455.9,445.6 467.2,445.6 443.8,422.1 
		443.8,235.9 439.1,235.9 435.8,235.9 	"/>
	<path fill="#00000" d="M88.9,81l81,23.1c1.1-4.1-35.9-18.3-82.8-31.7C40.2,58.9,1.1,51.4,0,55.5l81,23.1v343.5l-23.3,23.5h11.2
		L85,429.5l16.1,16.1h11.2L89,422.2V81H88.9z"/>
</g>
</svg>
                </div>
                <h3 className="text-xl mt-5 font-semibold ">
                  Drum patterns
                </h3>
                <p className="text-base mt-2 mb-4 ">
                  We are working on the most commonly used drum patterns.
                </p>
              </div>
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={18} height={18} fill="#00000"><path d="M16.75 4.36C18.77 6.56 18.77 9.61 16.75 11.63L15.07 9.94C15.91 8.76 15.91 7.23 15.07 6.05L16.75 4.36M20.06 1C24 5.05 23.96 11.11 20.06 15L18.43 13.37C21.2 10.19 21.2 5.65 18.43 2.63L20.06 1M13.36 13.76C15.31 14.42 17 15.5 17 17V22H1V17C1 15.5 2.69 14.42 4.65 13.76L5.27 15L5.5 15.5C4 15.96 2.9 16.62 2.9 17V20.1H7.12L8 15.03L7.06 13.15C7.68 13.08 8.33 13.03 9 13.03S10.32 13.08 10.94 13.15L10 15.03L10.88 20.1H15.1V17C15.1 16.62 14 15.96 12.5 15.5L12.73 15L13.36 13.76M9 6C7.9 6 7 6.9 7 8S7.9 10 9 10 11 9.1 11 8 10.1 6 9 6M9 12C6.79 12 5 10.21 5 8S6.79 4 9 4 13 5.79 13 8 11.21 12 9 12Z" /></svg>
                </div>
                <h3 className="text-xl mt-5 font-semibold ">
                  Vocal exercises
                </h3>
                <p className="text-base mt-2 mb-4 ">
                  We are working on some useful and commonly used vocal exercises and the corresponding piano accompaniments.
                </p>
              </div>
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512"><path d="M160 164s1.44-33 33.54-59.46C212.6 88.83 235.49 84.28 256 84c18.73-.23 35.47 2.94 45.48 7.82C318.59 100.2 352 120.6 352 164c0 45.67-29.18 66.37-62.35 89.18S248 298.36 248 324" fill="none" stroke="currentColor" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="40"/><circle cx="248" cy="399.99" r="32"/></svg>
                </div>
                <h3 className="text-xl mt-5 font-semibold ">
                  Your suggestion
                </h3>
                <p className="mt-2 mb-4  text-base">
                What should we also add to this website? Please send your suggestions by email at <a href = "mailto:contact@arfusoft.com" style={{color:"blue", fontWeight:"bold"}}>contact@arfusoft.com</a> 
                </p>
              </div>
            </div>
          </div>
        </section>

      </main>

      <HomeFooter />
    </>
  );
}
export default Home;