import React from "react";

export default function HomeFooter() {
  return (
    <footer className="relative  pt-8 pb-6  bg-base-100 dark:text-white ">
      <div
        className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style={{ height: "80px" }}
      >
        <svg
          className="absolute bottom-0 overflow-hidden bg-base-100"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            className="fill-none  bg-base-100"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
      <div className="container mx-auto px-4   text-base-content">
        <div className="flex flex-wrap">
          <div className="w-full lg:w-6/12 px-4">
            <h4 className="text-3xl font-semibold">
              Let's keep in touch!
            </h4>
            <h5 className="text-lg mt-0 mb-2">
              Contact us by email at 

            </h5>
            <a className="text-base" href = "mailto:contact@arfusoft.com" style={{color:"blue", fontWeight:"bold"}}>contact@arfusoft.com</a><br /><br />
            {/*<div className="mt-6">
              <button
                className="bg-white text-blue-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2 p-3"
                type="button"
              >
                <i className="flex fab fa-twitter"></i>
              </button>
              <button
                className="bg-white text-blue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2 p-3"
                type="button"
              >
                <i className="flex fab fa-facebook-square"></i>
              </button>
              <button
                className="bg-white text-pink-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2 p-3"
                type="button"
              >
                <i className="flex fab fa-dribbble"></i>
              </button>
              <button
                className="bg-white text-gray-900 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2 p-3"
                type="button"
              >
                <i className="flex fab fa-github"></i>
              </button>
            </div>*/}
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="flex flex-wrap items-top mb-6">
              <div className="w-full lg:w-4/12 px-4 py-2 ml-auto">
                <span className="block uppercase text-sm font-semibold mb-2">
                  Company
                </span>
                <ul className="list-unstyled">
                  <li>
                    <a className="block pb-2 text-sm"
                        href="/about">About Us
                    </a>
                  </li>
                  <li>
                    <a className="block pb-2 text-sm"
                        href="/contact" >Contact
                    </a>
                  </li>
                </ul>
              </div>
              <div className="w-full lg:w-4/12 px-4 py-2 ml-auto">
                <span className="block uppercase text-sm font-semibold mb-2">
                  Resources
                </span>
                <ul className="list-unstyled">

                  <li>
                    <a className="block pb-2 text-sm"
                        href="https://facilemath.com/" target="_blank" >Facile Math
                    </a>
                  </li>

                  <li>
                    <a className="block pb-2 text-sm"
                        href="https://libpeople.com/" target="_blank" >Library of the People
                    </a>
                  </li>
                  <li>
                    <a className="block pb-2 text-sm"
                        href="https://dreamsgist.com/" target="_blank" >Dreams Gist
                    </a>
                  </li>

                </ul>
              </div>
              <div className="w-full lg:w-4/12 px-4 py-2 ml-auto">
                <span className="block uppercase text-sm font-semibold mb-2">
                  Legal
                </span>
                <ul className="list-unstyled">
                  <li>
                    <a className="block pb-2 text-sm"
                        href="/terms">Terms of Use
                    </a>
                  </li>
                  <li>
                    <a className="block pb-2 text-sm"
                        href="/policy" >Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a className="block pb-2 text-sm"
                        href="/cookies" >Cookies Policy
                    </a>
                  </li>


                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr className="my-6 border-gray-400" />
        <div className="flex flex-wrap items-center md:justify-between justify-center">
          <div className="w-full md:w-4/12 px-4 mx-auto text-center">
            <div className="text-sm py-1">
              Copyright © {new Date().getFullYear()}{" "}Alleramusic by{" "}
              <a
                href="https://arfusoft.com/" target="_blank" rel="noreferrer"
                className=" font-bold text-blue-600 hover:text-blue-900"
              >
                Arfusoft
              </a>.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
