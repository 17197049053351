import {React, useState }from "react";
import {  Interval} from "tonal";
import {Helmet} from "react-helmet";

function DisplayInterval({start, end}){
    const [outputText, setOutputText] = useState('')

    const startCalculate =() => { 
        setOutputText('');
        try {
            const distance = Interval.distance(`${start}`, `${end}`);
            setOutputText(distance);
        } catch (error) {
            setOutputText(error);
        }
    }
    return(
        <div>
            <div className="py-2">
                <button className="btn bg-blue-600 hover:bg-blue-800 text-white w-full rounded-full normal-case" onClick={startCalculate}>Show interval {start} &lt;|---|&gt; {end}</button>
            </div>
            <div>
                <textarea className="textarea textarea-bordered font-bold w-full" value={outputText} onChange={event => setOutputText(event.target.value)}></textarea>
            </div>
        </div>
    )

}




function TheoryInterval () {
    const [startRootText, setStartRootText] = useState('C') //default selected value
    const [startDegreeText, setStartDegreeText] = useState('4') 
    const [endRootText, setEndRootText] = useState('B') //default selected value
    const [endDegreeText, setEndDegreeText] = useState('4') 
    return(
        <>

    <Helmet>
        <meta charSet="utf-8" />
        <title>Interval between two musical notes.</title>
        <meta name="description" content="Calculate the interval between two musical notes." />              
        <meta name="keywords" content="music,interval"/>
        <meta property="og:title" content="Interval between two musical notes." />        
        <meta property="og:description" content="Calculate the interval between two musical notes." />        
        <meta property="og:site_name" content="Interval between two musical notes." />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
    </Helmet>

        
            <section className="text-base-content body-font  bg-base-100 dark:text-white relative">
                <div className="container px-5 py-2 mx-auto max-w-lg">
                    <div className="flex flex-col text-justify w-full mb-12">
                    <h1 className="flex justify-center items-center text-2xl  text-blue-500"><b>Musical Intervals</b></h1>
                        <p className="text-black-500 dark:text-white flex items-center justify-center text-base">
                        An interval is the distance between two notes. 
                        Almost all music is created by using notes at different distances and thus different sizes of intervals. 
                        The notes in an interval may be played simultaneously, which is the case in chords, or played successively, 
                        which is the case in melodies. Below, you can compute the interval between two notes. 
                        </p>
                    </div>
                </div>
            </section>
            <section className="text-base-content body-font relative  bg-base-100 dark:text-white ">
                <div className="container px-5 py-1 mx-auto max-w-lg">

                <div className="flex flex-row py-2">
                    <div className="basis-1/5">
                        <div className="">
                            <select className="select select-bordered w-full" value={startRootText} onChange={event => setStartRootText(event.target.value)}>
                            <option value="A">A</option>
                            <option value="A#">A#</option>
                            <option value="B">B</option>
                            <option value="C">C</option>
                            <option value="C#">C#</option>
                            <option value="D">D</option>  
                            <option value="D#">D#</option>
                            <option value="E">E</option>
                            <option value="F">F</option>
                            <option value="F#">F#</option>
                            <option value="G">G</option>  
                            <option value="G#">G#</option>
                            </select>
                        </div>
                    </div>
                    <div className="basis-1/5">
                        <div className="">
                            <select className="select select-bordered w-full" value={startDegreeText} onChange={event => setStartDegreeText(event.target.value)}>
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option> 
                            <option value="6">6</option>
                            <option value="7">7</option> 
                            <option value="8">8</option> 
                            </select>
                        </div>
                    </div>

                    <div className="basis-1/5 mt-6"><hr /></div>



                    <div className="basis-1/5">
                        <div className="">
                            <select className="select select-bordered w-full" value={endRootText} onChange={event => setEndRootText(event.target.value)}>
                            <option value="A">A</option>
                            <option value="A#">A#</option>
                            <option value="B">B</option>
                            <option value="C">C</option>
                            <option value="C#">C#</option>
                            <option value="D">D</option>  
                            <option value="D#">D#</option>
                            <option value="E">E</option>
                            <option value="F">F</option>
                            <option value="F#">F#</option>
                            <option value="G">G</option>  
                            <option value="G#">G#</option>
                            </select>
                        </div>
                    </div>
                    <div className="basis-1/5">
                        <div className="">
                            <select className="select select-bordered w-full" value={endDegreeText} onChange={event => setEndDegreeText(event.target.value)}>
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option> 
                            <option value="8">8</option> 
                            </select>
                        </div>
                    </div>
                </div>



                        <div>
                        <DisplayInterval start={startRootText+startDegreeText} end={endRootText+endDegreeText} ></DisplayInterval>  
                        </div>
                        <div className="py-24">
 
                        </div>
                </div>
            </section>
        </>
    )
}

export default TheoryInterval;