import {React, useState }from "react";
import {Helmet} from "react-helmet";
import { Scale} from "tonal";

function DisplayScaleNotes({root, mode}){
    const [outputText, setOutputText] = useState('')

    const startCalculate =() => { 
        setOutputText('');
        var scale = Scale.get(`${root} ${mode}`);
        const notes = scale.notes.join(', ');
        setOutputText(notes);
              
    }
    return(
        <div>
            <div className="py-2">
                <button className="btn bg-blue-600 hover:bg-blue-800 text-white w-full" onClick={startCalculate}>Show the notes of {root} {mode} scale</button>
            </div>
            <div>
                <textarea className="textarea textarea-bordered font-bold w-full" value={outputText} onChange={event => setOutputText(event.target.value)}></textarea>
            </div>
        </div>
    )
}

function DetectScaleName({notes}){
    const [outputText, setOutputText] = useState('')

    const startCalculate =() => {
        setOutputText(''); 
        if(notes ===''){
            setOutputText('Error: Enter comma-separated note names (Ex: A, B, C#).');
        }
        else {
            try {
                const notesArray = notes.replace(/\n\r/g, " ").replace(/\n/g, " ").split(" ").join("").replace(' ', '').split(',');
                const scale = Scale.detect(notesArray);
                setOutputText(scale.join(', '));
            } catch (error) {
                setOutputText(error);
            }
        }
    
              
    }
    return(
        <div>
            <div className="py-2">
                <button className="btn bg-blue-600 hover:bg-blue-800 text-white w-full" onClick={startCalculate}>Detect Scales</button>
            </div>
            <div>
                <textarea className="textarea textarea-bordered font-bold w-full h-60" value={outputText} onChange={event => setOutputText(event.target.value)}></textarea>
            </div>
        </div>
    )
}



function TheoryScale () {
    const [rootText, setRootText] = useState('C') //default selected value
    const [modeText, setModeText] = useState('major') //default selected value
    return(
        <>

    <Helmet>
        <meta charSet="utf-8" />
        <title>Notes of a musical scale</title>
        <meta name="description" content="Get all the notes that constitute a musical scale." />              
        <meta name="keywords" content="music,musical note,musical scale"/>
        <meta property="og:title" content="Notes of a musical scale" />        
        <meta property="og:description" content="Get all the notes that constitute a musical scale." />        
        <meta property="og:site_name" content="Notes of a musical scale" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
    </Helmet>


       
            <section className="text-base-content body-font  bg-base-100 dark:text-white relative">
                <div className="container px-5 py-2 mx-auto  max-w-lg">
                    <div className="flex flex-col text-justify w-full mb-2">
                    <h1 className="flex items-center justify-center  text-2xl  text-blue-500 mb-2"> <b>Notes of a musical scale</b></h1>
                        <p className="text-black-500 dark:text-white flex items-center justify-center text-base">
                        A musical scale is a group of notes that are arranged in ascending or descending order of pitch.
                        In an ascending scale, each note is higher in pitch than the last, and in a descending scale, each note is lower in pitch than the previous.
                        </p>

                    </div>
                </div>
            </section>
            <section className="text-base-content body-font  bg-base-100 dark:text-white relative">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                <h1 className=" flex items-center justify-center text-base font-bold mb-4 text-purple-500"> <u>Scale Notes Generator</u> </h1>
                <div className="flex flex-row py-2">
                    <div className="basis-1/3">
                        <div className="py-1 px-1">
                            <select className="select select-bordered w-full" value={rootText} onChange={event => setRootText(event.target.value)}>
                            <option value="A">A</option>
                            <option value="A#">A#</option>
                            <option value="B">B</option>
                            <option value="C">C</option>
                            <option value="C#">C#</option>
                            <option value="D">D</option>  
                            <option value="D#">D#</option>
                            <option value="E">E</option>
                            <option value="F">F</option>
                            <option value="F#">F#</option>
                            <option value="G">G</option>  
                            <option value="G#">G#</option>
                               
                            </select>
                        </div>
                    </div>
                    <div className="basis-2/3">
                        <div className="py-1">
                            <select className="select select-bordered w-full" value={modeText} onChange={event => setModeText(event.target.value)}>
                            <option value="major">major</option>
                            <option value="minor">minor</option>
                            <option value="major pentatonic">major pentatonic</option>
                            <option value="major blues">major blues</option>
                            <option value="minor blues">minor blues</option>
                            <option value="melodic minor">melodic minor</option>
                            <option value="harmonic minor">harmonic minor</option>
                            <option value="bebop">bebop</option>
                            <option value="diminished">diminished</option>
                            <option value="dorian">dorian</option>
                            <option value="lydian">lydian</option>
                            <option value="mixolydian">mixolydian</option>
                            <option value="phrygian">phrygian</option>
                            <option value="locrian">locrian</option>
                            <option value="ionian pentatonic">ionian pentatonic</option>
                            <option value="mixolydian pentatonic">mixolydian pentatonic</option>
                            <option value="lydian pentatonic">lydian pentatonic</option>
                            <option value="locrian pentatonic">locrian pentatonic</option>
                            <option value="minor pentatonic">minor pentatonic</option>
                            <option value="whole tone pentatonic">whole tone pentatonic</option>
                            <option value="lydian dominant pentatonic">lydian dominant pentatonic</option>
                            <option value="minor hexatonic">minor hexatonic</option>
                            <option value="augmented">augmented</option>
                            <option value="whole tone">whole tone</option>
                            <option value="locrian major">locrian major</option>
                            <option value="lydian dominant">lydian dominant</option>
                            <option value="ultralocrian">ultralocrian</option>
                            <option value="locrian">locrian</option>
                            <option value="lydian minor">lydian minor</option>
                            <option value="flamenco">flamenco</option>
                            <option value="chromatic">chromatic</option>
                            </select>
                        </div>
                    </div>
                </div>



                        <div>
                        <DisplayScaleNotes root={rootText} mode={modeText}  ></DisplayScaleNotes>  
                        </div>
                        <div className="py-4">
 
                        </div>
                        <div className="py-4">
 
                        </div>
                        <div className="py-4">
                        
                        </div>
                        <p className="text-black-500 dark:text-white flex items-center justify-center text-base">
                        If you want to detect a scale from a collection of notes, please click on the link below:
                        </p>
                        <ul className="space-y-4 py-4 px-12">
                            <li className="flex items-center justify-center text-base">
                            <svg className="h-6 w-6 mr-1 flex-none fill-sky-100 stroke-sky-500 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <a className="text-sky-600 font-bold" href="/theory-scale-detect">Detect scale from notes</a>
                            </li>
                        </ul>
                </div>
            </section>
        </>
    )
}

function TheoryScaleDetect () {
    const [inputText, setInputText] = useState('')
    return(
        <>

    <Helmet>
        <meta charSet="utf-8" />
        <title>Musical scale detector</title>
        <meta name="description" content="Detect the scale that corresponds to a serie of musical notes." />              
        <meta name="keywords" content="music,musical note,musical scale"/>
        <meta property="og:title" content="Musical scale detector" />        
        <meta property="og:description" content="Detect the scale that corresponds to a serie of musical notes." />        
        <meta property="og:site_name" content="Musical scale detector" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
    </Helmet>


        
            <section className="text-base-content body-font  bg-base-100 dark:text-white relative">
                <div className="container px-5 py-2 mx-auto  max-w-lg">
                    <div className="flex flex-col text-justify w-full mb-12">
                    <h1 className="flex items-center justify-center  text-xl  text-blue-500 mb-2"> <b>Detect musical scale from notes</b></h1>
                        <p className="text-black-500 dark:text-white  flex items-center justify-center text-base">
                        Musical scales are groups of notes (12 notes per scale) which sound pleasant when played in or out of sequence, ascending or descending. 
                        From musical notes we can identify the musical scale(s) they are part of.  
                        </p>

                    </div>
                </div>
            </section>
            <section className="text-base-content body-font  bg-base-100 dark:text-white relative">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                <h2 className=" flex items-center justify-center text-base font-bold mb-4 text-purple-500"> <u>Scale Detector</u> </h2>
                <div>
                    <input  type="text" placeholder="Type in coma-separated notes. Ex: A , B , C#, D, ..." className="input input-bordered w-full"  value={inputText} onChange={event => setInputText(event.target.value)}></input>
                </div>



                        <div>
                        <DetectScaleName notes={inputText}  ></DetectScaleName>  
                        </div>
                        <div className="py-12">
 
                        </div>
                        <p className="text-black-500 dark:text-white  flex items-center justify-center text-base">
                       If you want to display the notes of a scale, please click on the link below:
                        </p>
                        <ul className="space-y-4 py-4 px-12">
                            <li className=" flex items-center justify-center text-base">
                            <svg className="h-6 w-6 mr-1 flex-none fill-sky-100 stroke-sky-500 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <a className="text-sky-600 font-bold" href="/theory-scale">Display scale notes</a>
                            </li>
                        </ul>
                </div>
            </section>
        </>
    )
}



export {TheoryScale, TheoryScaleDetect};