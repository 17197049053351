import {React, useState, useEffect} from "react";
import {Helmet} from "react-helmet";
import background6 from "../assets/images/about-shape-1.svg";
import {ImageMP3Display} from "./Progressions"
import CommonFooter from "./CommonFooter.jsx";

function Rhythms() {
  const [searchItem, setSearchItem] = useState('')

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    fetch("https://goldfish-app-asx3v.ondigitalocean.app/allera/rhythms")
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )

  }, [])

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {

  const backgroundImage = `url(${background6})`;


  return (
    <>

        <Helmet>
            <meta charSet="utf-8"/>
            <title>Music rhythms</title>
            <link rel="canonical" href="https://alleramusic.com/rhythms"/>
            <meta name="description"
                  content="Music rhythms combine a collection of interesting rhythms of various time signatures."/>
            <meta name="keywords" content="music,rhythms"/>
            <meta property="og:title" content="Music rhythms"/>
            <meta property="og:description"
                  content="Music rhythms combine a collection of interesting rhythms of various time signatures."/>
            <meta property="og:site_name" content="Music rhythms"/>
            <meta property="og:locale" content="en_US"/>
            <meta property="og:type" content="article"/>
        </Helmet>


        <div className="text-base-content py-2 flex justify-center items-center  bg-base-100 mainDiv" style={{backgroundImage: backgroundImage}}>
          
      <div className="py-4 lg:py-6">

<div className="space-y-6 py-2 lg:py-4 text-base leading-7 text-black-500 dark:text-white px-6 lg:px-12">
<h1 className="flex justify-center items-center  text-2xl  text-blue-500"><b>Music rhythms</b></h1>
<p className="text-black-500 dark:text-white text-base">
              Music rhythms combine a collection of interesting rhythms of various time signatures. 
              The rhythms are listed below. Each rhythm is displayed as a notation in tablature. 
              You can play back each rhythm and download it in the midi and mp3 formats. 
                <span className="text-base"> The rhythms we gathered are usually found in musical styles such as </span>
                <code className="text-sm font-bold"> Pop, Rock, Folk, Ballads, Blues, Funk, Latin music, Jazz, Reggae, Dixie and African musics</code>.
              </p>
      <p className="flex justify-center items-center text-black-500 dark:text-white text-base">The native application can be downloaded on: </p><br />
      <a className="flex justify-center items-center" href="https://www.microsoft.com/store/apps/9NWMLTNZB0J8" target="_blank" rel="noreferrer"><button className="btn btn-wide btn-info">Microsoft Store</button></a>
      </div>
</div>



      </div>
      <div className="text-base-content bg-base-100 px-2 py-2 flex justify-center items-center">
        <input  type="text" placeholder="Search here" className="input input-bordered input-info w-full max-w-md"  value={searchItem} onChange={event => setSearchItem(event.target.value)}></input>
      </div>
      <div className="text-base-content bg-base-100 py-6 flex justify-center items-center">
        <ul className="menu menu-vertical px-1 w-full  max-w-lg">
          {items.filter((music) => //items here
              {
                if(searchItem === "") {
                  return music;
                }
                else if 
                (music.Name.toLowerCase().includes(searchItem.toLowerCase())) {
                  return music;
                }
                else return null
                
              }).map(chord => 
          <ImageMP3Display key={chord.Id} name={chord.Name} 
            imageFile={"https://arfusoftaudiofiles.s3.eu-central-1.amazonaws.com/rhythms/"+chord.ImageFile+".png"} 
            mp3File={"https://arfusoftaudiofiles.s3.eu-central-1.amazonaws.com/rhythms/"+chord.Audiomp3File+".mp3"}
            midiFile={"https://arfusoftaudiofiles.s3.eu-central-1.amazonaws.com/rhythms/"+chord.AudiomidiFile+".mid"} >

        </ImageMP3Display>)}
        </ul>

      </div>

      {/*<CommonFooter /> */}
      
    </>
  );}
  }
  export default   Rhythms;