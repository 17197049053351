import React from "react";
import ClassicalCompositions from "./ClassicalCompositions";


function Bach() {
  return (
    <>
      <ClassicalCompositions author={'Bach'} />
      
    </>
  );
}
function Beethoven() {
  return (
    <>
      <ClassicalCompositions author={'Beethoven'} />
    </>
  );
}
function Chopin() {
  return (
    <>
      <ClassicalCompositions author={'Chopin'} />
    </>
  );
}
function Corelli() {
  return (
    <>
      <ClassicalCompositions author={'Corelli'} />
    </>
  );
}
function Haydn() {
  return (
    <>
      <ClassicalCompositions author={'Haydn'} />
    </>
  );
}
function Hummel() {
  return (
    <>
      <ClassicalCompositions author={'Hummel'} />
    </>
  );
}
function Joplin() {
  return (
    <>
      <ClassicalCompositions author={'Joplin'} />
    </>
  );
}
function Mozart() {
  return (
    <>
      <ClassicalCompositions author={'Mozart'} />
    </>
  );
}
function Scarlatti() {
  return (
    <>
      <ClassicalCompositions author={'Scarlatti'} />
    </>
  );
}
function Songs() {
  return (
    <>
      <ClassicalCompositions author={'Songs'} />
    </>
  );
}
export {
  Bach,
  Beethoven,
  Chopin,
  Corelli,
  Haydn,
  Hummel,
  Joplin,
  Mozart,
  Scarlatti,
  Songs
};