import {React, useState }from "react";
import {Helmet} from "react-helmet";
import {  Midi} from "tonal";

function DisplayNoteMidi({root, degree}){
    const [outputText, setOutputText] = useState('')

    const startCalculate =() => { 
        setOutputText('');
        const midiNumber = Midi.toMidi(`${root}${degree}`);
        setOutputText(midiNumber);        
    }
    return(
        <div>
            <div className="py-2">
                <button className="btn bg-blue-600 hover:bg-blue-800 text-white w-full" onClick={startCalculate}>Show midi number of {root}{degree}</button>
            </div>
            <div>
                <textarea className="textarea textarea-bordered font-bold w-full" value={outputText} onChange={event => setOutputText(event.target.value)}></textarea>
            </div>
        </div>
    )
}

function DisplayMidiNote({midi}){
    const [outputText, setOutputText] = useState('')

    const startCalculate =() => { 
        setOutputText('');
        if((midi < 0) || (midi > 127) || (midi ==='')){
            setOutputText('Error: Enter a number between 0 and 127.');
        }
        else {
            
            try {
                const noteName = Midi.midiToNoteName(`${midi}`);
                setOutputText(noteName);
            } catch (error) {
                setOutputText(error);
            }
        }
    
              
    }
    return(
        <div>
            <div className="py-2">
                <button className="btn bg-blue-600 hover:bg-blue-800 text-white w-full" onClick={startCalculate}>Show Note Name</button>
            </div>
            <div>
                <textarea className="textarea textarea-bordered font-bold w-full" value={outputText} onChange={event => setOutputText(event.target.value)}></textarea>
            </div>
        </div>
    )
}



function TheoryNoteMidi () {
    const [rootText, setRootText] = useState('C') //default selected value
    const [degreeText, setDegreeText] = useState('4') //default selected value
    return(
        <>

    <Helmet>
        <meta charSet="utf-8" />
        <title>Note to midi number conversion</title>
        <meta name="description" content="Get the midi number of a musical note" />              
        <meta name="keywords" content="music,note to midi,midi number"/>
        <meta property="og:title" content="Note to midi number conversion" />        
        <meta property="og:description" content="Get the midi number of a musical note" />        
        <meta property="og:site_name" content="Note to midi number conversion" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
    </Helmet>

        
            <section className="text-base-content body-font  bg-base-100 dark:text-white relative">
                <div className="container px-5 py-2 mx-auto  max-w-lg">
                    <div className="flex flex-col text-justify w-full mb-12">
                    <h1 className="flex justify-center items-center text-2xl  text-blue-500 mb-4"><b>Musical Note to Midi</b></h1>
                        <p className="text-black-500 dark:text-white flex items-center justify-center text-base">
                        A MIDI number is a number that represents a musical note or a program value in the MIDI protocol. 
                        MIDI numbers range from 0 to 127, with 0 being the lowest (C five octaves below middle C) and 
                        127 being the highest (G ten octaves above middle C). MIDI numbers can also be converted to frequency 
                        values using formulas that involve logarithms and powers of 2. 
                        MIDI numbers are used in electronic music and MIDI devices.
                        </p>

                    </div>
                </div>
            </section>
            <section className="text-base-content body-font  bg-base-100 dark:text-white relative">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                <h2 className=" flex items-center justify-center text-base font-bold mb-4 text-purple-500"> <u>Musical note to midi converter</u> </h2>
                <div className="flex flex-row py-2">
                    <div className="basis-1/2">
                        <div className="py-1 px-1">
                            <select className="select select-bordered w-full" value={rootText} onChange={event => setRootText(event.target.value)}>
                            <option value="A">A</option>
                            <option value="A#">A#</option>
                            <option value="B">B</option>
                            <option value="C">C</option>
                            <option value="C#">C#</option>
                            <option value="D">D</option>  
                            <option value="D#">D#</option>
                            <option value="E">E</option>
                            <option value="F">F</option>
                            <option value="F#">F#</option>
                            <option value="G">G</option>  
                            <option value="G#">G#</option>
                               
                            </select>
                        </div>
                    </div>
                    <div className="basis-1/2">
                        <div className="py-1">
                            <select className="select select-bordered w-full" value={degreeText} onChange={event => setDegreeText(event.target.value)}>
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option> 
                            <option value="6">6</option>
                            <option value="7">7</option> 
                            <option value="8">8</option> 
                               
                            </select>
                        </div>
                    </div>
                </div>



                        <div>
                        <DisplayNoteMidi root={rootText} degree={degreeText}  ></DisplayNoteMidi>  
                        </div>
                        <div className="py-12">
 
                        </div>
                        <p className="text-black-500 dark:text-white flex items-center justify-center text-base">
                        If you want to get a note name from midi number, please click on the link below:
                        </p>
                        <ul className="space-y-4 py-4 px-12">
                            <li className="flex items-center justify-center text-base">
                            <svg className="h-6 w-6 mr-1 flex-none fill-sky-100 stroke-sky-500 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <a className="text-sky-600 font-bold" href="/theory-note-frommidi">Midi to Note</a>
                            </li>
                        </ul>
                </div>
            </section>
        </>
    )
}

function TheoryMidiNote () {
    const [inputText, setInputText] = useState('')
    return(
        <>

    <Helmet>
        <meta charSet="utf-8" />
        <title>Midi to musical note conversion</title>
        <meta name="description" content="Get the musical note that corresponds to a midi number." />              
        <meta name="keywords" content="music,midi to musical note,midi number"/>
        <meta property="og:title" content="Midi to musical note" />        
        <meta property="og:description" content="Get the musical note that corresponds to a midi number." />        
        <meta property="og:site_name" content="Midi to musical note" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
    </Helmet>

        
            <section className="text-base-content body-font  bg-base-100 dark:text-white relative">
                <div className="container px-5 py-2 mx-auto max-w-lg">
                    <div className="flex flex-col text-justify w-full mb-12">
                    <h1 className="flex justify-center items-center text-2xl  text-blue-500 mb-4"><b>Midi number to musical note</b></h1>
                        <p className="text-black-500 dark:text-white  flex items-center justify-center text-base">
                        A MIDI number is a number that represents a musical note or a program value in the MIDI protocol. 
                        MIDI numbers range from 0 to 127, with 0 being the lowest (C five octaves below middle C) and 
                        127 being the highest (G ten octaves above middle C). MIDI numbers can also be converted to frequency 
                        values using formulas that involve logarithms and powers of 2. 
                        MIDI numbers are used in electronic music and MIDI devices. 
                        </p>

                    </div>
                </div>
            </section>
            <section className="text-base-content body-font  bg-base-100 dark:text-white relative">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                <h2 className=" flex items-center justify-center text-base font-bold mb-4 text-purple-500"> <u>Midi number to musical note converter</u> </h2>
                <div>
                    <input  type="text" placeholder="Enter a number between 0 and 127" className="input input-bordered w-full"  value={inputText} onChange={event => setInputText(event.target.value)}></input>
                </div>



                        <div>
                        <DisplayMidiNote midi={inputText}  ></DisplayMidiNote>  
                        </div>
                        <div className="py-12">
 
                        </div>
                        <p className="text-black-500 dark:text-white  flex items-center justify-center text-base">
                        If you want to display the midi number of a note, please click on the link below:
                        </p>
                        <ul className="space-y-4 py-4 px-12">
                            <li className=" flex items-center justify-center text-base">
                            <svg className="h-6 w-6 mr-1 flex-none fill-sky-100 stroke-sky-500 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <a className="text-sky-600 font-bold" href="/theory-note-tomidi">Note to midi</a>
                            </li>
                        </ul>
                </div>
            </section>
        </>
    )
}



export {TheoryNoteMidi, TheoryMidiNote};
