import React from "react";
import {Helmet} from "react-helmet";
function About() {
    return (
      <>

        <Helmet>
          <meta charSet="utf-8"/>
          <title>About us</title>
          <link rel="canonical" href="https://alleramusic.com/about"/>
          <meta name="description" content="Why we built the All Era Music website."/>
          <meta name="keywords" content="about us"/>
          <meta property="og:title" content="About us"/>
          <meta property="og:description" content="Why we built the All Era Music website."/>
          <meta property="og:site_name" content="About us"/>
          <meta property="og:locale" content="en_US"/>
          <meta property="og:type" content="article"/>
        </Helmet>


        <div className="py-6 sm:py-12  bg-base-100 text-base-content">
          <div className="ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-lg sm:rounded-lg">
            <div className="mx-auto max-w-md px-4">
              <h2 className="text-base"><b> About Us</b></h2>
              <div className="divide-y divide-gray-300/50">
                <div className="space-y-6 py-8 text-base leading-7">
                  {/*  Besides the various tools available on this website, <b>we</b> also <a className="text-purple-500 hover:text-purple-600" href="/products"> recommend products</a> that we know or trust to be of high quality. */}
                  <p className="text-base">
                  <span className="text-base text-purple-500 font-bold"> All Era Music</span>  website aims to be a platform for musical knowledge sharing. 
                  By creating this website, <b>we</b> want to give any novice composer or music amateur the foundation to understand and build musical masterpieces. 
                 
                  <b> But who are we? </b> <b>we</b> refers to our software company <a className="text-sky-400 hover:text-sky-600 font-bold" href="https://arfusoft.com/" target="_blank" rel="noreferrer">  Arfusoft.</a> It has conceived and built this website and all the different applications that it is referring to.
                    
                  </p>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
  
      </>
  
    );
  }
  export default About;