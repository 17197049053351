import React from "react";
import "../titlestyle.css";

function Navbar() {
    return (
        <div className="text-base-content navbar bg-base-100 z-50 dark:text-gray-400 ">
            <div className="navbar-start">
                <div className="dropdown">
                <label tabIndex={0} className="btn btn-ghost btn-circle">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h7" /></svg>
                </label>
                <ul tabIndex={0} className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52  dark:text-white">
                    <li className="dark:text-white"><a  className="text-base dark:text-white" href="/">
                    <ion-icon name="home"></ion-icon>
                        Home</a>
                    </li>
                    <li><a   className="text-base" href="/theory-main">
                    <ion-icon name="musical-note"></ion-icon>
                        Music Theory</a>
                    </li>
                    <li><a   className="text-base" href="/rhythms">
                    <ion-icon name="musical-notes"></ion-icon>
                        Music Rhythms</a>
                    </li>
                    <li><a   className="text-base" href="/progressions">
                    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="12" height="12" viewBox="0 0 1280.000000 1280.000000"
 preserveAspectRatio="xMidYMid meet">
<metadata>
Created by potrace 1.15, written by Peter Selinger 2001-2017
</metadata>
<g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
fill="#6A82FB" stroke="none">
<path d="M6060 12160 c-1456 -57 -2864 -474 -3851 -1142 -602 -406 -971 -864
-1055 -1305 -52 -274 69 -846 377 -1781 610 -1851 1527 -3834 2391 -5172 375
-580 693 -986 1057 -1351 299 -298 523 -472 776 -601 242 -123 425 -171 650
-171 358 1 720 150 1119 463 872 683 1852 2182 2764 4230 558 1254 1058 2666
1277 3606 87 373 112 612 81 777 -84 441 -453 899 -1055 1305 -1151 778 -2854
1207 -4531 1142z"/>
</g>
</svg>
                        Chords Progressions</a>
                    </li>

                    <li><a   className="text-base" href="/melodies">
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" width={14} height={14}
	 viewBox="0 0 500 500" enableBackground="new 0 0 500 500" >
<g>
	<path fill="#6A82FB" d="M351.8,41.8V19.2c0-10.6-8.6-19.2-19.2-19.2s-19.2,8.6-19.2,19.2V313c-29.6-20.6-73.1-29.4-118.5-20.1
		C120.2,308,68.9,365.2,80.1,420.8s80.8,88.4,155.3,73.3c70.1-14.1,119.5-65.6,116-117.8c0.1-0.6,0.2-1.4,0.2-2V99.5
		c5.1-0.1,11,0.2,17.9,1.2c96.3,14,116.3,135.5,116.3,135.5C522.4,140.3,411.4,71.1,351.8,41.8z"/>
	<path fill="#6A82FB" d="M140.8,268.2c6.2-0.6,10.9-6.2,10.2-12.5c-0.6-6.2-6.2-10.9-12.5-10.2C50.6,254.3,35.2,332.7,35,333.6
		c-1.1,6.2,3,12.2,9.2,13.4c0.7,0.1,1.4,0.1,2.1,0.1c5.4,0,10.2-3.9,11.2-9.4C57.8,336.9,69.9,275.2,140.8,268.2z"/>
	<path fill="#6A82FB" d="M68.8,235.8c6.2-0.6,10.9-6.2,10.2-12.5c-0.6-6.2-6.2-11-12.5-10.2c-40.3,4-56.5,35-59.3,50.8
		c-1.1,6.1,3,12.1,9.1,13.2c0.7,0.1,1.5,0.2,2.1,0.2c5.4,0,10.1-3.7,11.2-9.2C29.9,266.9,35.8,239,68.8,235.8z"/>
	<path fill="#6A82FB" d="M396.5,430.3c-6.4-0.5-11.7,4.2-12.2,10.5c-0.1,1.2-2.7,29.5-35.1,36.6c-6.1,1.4-10.1,7.5-8.7,13.6
		c1.1,5.4,5.9,9,11.2,9c0.7,0,1.6-0.1,2.5-0.2c39.6-8.6,52-41.3,53.1-57.3C407.5,436.1,402.8,430.8,396.5,430.3z"/>
</g>
</svg>
                        Music Melodies</a>
                    </li>
                    <li><a   className="text-base" href="/bass/grooves">
                    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="16" height="16" viewBox="0 0 1280.000000 640.000000"
 preserveAspectRatio="xMidYMid meet">
<metadata>
Created by potrace 1.15, written by Peter Selinger 2001-2017
</metadata>
<g transform="translate(0.000000,640.000000) scale(0.100000,-0.100000)"
fill="#6A82FB" stroke="none">
<path d="M12182 6223 l-33 -4 7 -50 c6 -48 6 -49 -19 -49 -14 0 -32 -3 -41 -6
-12 -5 -17 5 -22 46 l-7 52 -51 -6 c-28 -3 -65 -6 -83 -6 l-32 0 2 -52 2 -53
-37 -3 -37 -3 -3 48 -3 48 -40 -2 c-22 -2 -60 -6 -84 -9 l-43 -6 4 -48 5 -48
-43 -4 c-48 -3 -54 3 -54 58 0 39 -1 39 -110 27 l-62 -6 6 -47 c7 -54 1 -60
-64 -60 l-38 0 -7 50 -7 51 -61 -6 c-111 -11 -107 -8 -100 -64 5 -48 5 -48
-27 -55 -18 -3 -36 -6 -41 -6 -4 0 -11 24 -14 53 l-7 53 -66 -8 c-37 -4 -75
-7 -85 -7 -15 -1 -16 -8 -11 -56 l7 -55 -49 -1 c-72 -1 -89 -9 -127 -59 -33
-44 -39 -46 -183 -93 -82 -27 -284 -92 -449 -147 -165 -54 -439 -144 -610
-200 -660 -216 -1275 -417 -1768 -579 -166 -55 -552 -181 -857 -281 -519 -170
-698 -229 -1465 -480 -450 -148 -404 -136 -434 -119 -36 21 -89 93 -102 141
-16 60 -3 135 36 215 109 225 421 452 870 633 72 29 133 54 137 57 10 5 -17
48 -56 91 -37 40 -139 94 -222 117 -92 25 -290 31 -404 11 -186 -32 -375 -105
-550 -211 -47 -28 -359 -249 -695 -490 -521 -375 -620 -442 -680 -463 -77 -27
-77 -27 -730 -2 -132 5 -389 14 -572 21 l-331 12 -114 -28 c-801 -195 -1332
-721 -1483 -1470 -130 -648 34 -1393 447 -2027 99 -153 203 -241 393 -336 281
-139 632 -165 997 -72 537 138 1095 519 1414 967 123 172 339 356 517 438 201
93 376 113 811 95 439 -19 590 -1 790 93 164 77 310 209 376 341 114 226 -114
340 -509 256 -232 -49 -434 -10 -546 106 -111 114 -116 313 -11 469 43 64 120
140 171 170 22 13 324 123 670 245 347 122 662 233 700 247 39 13 567 200
1175 415 608 215 1166 412 1240 438 806 286 2192 774 2218 782 30 8 39 4 117
-48 l85 -57 188 5 c153 5 195 9 223 23 19 10 208 116 421 236 422 239 415 236
518 205 42 -12 47 -17 70 -74 l25 -61 70 -25 c38 -14 70 -24 71 -23 1 1 21 30
46 63 40 55 46 71 57 147 l12 85 -39 97 c-34 86 -44 101 -103 154 l-64 58
-127 22 c-69 11 -128 21 -131 21 -3 0 -8 25 -12 55 l-6 55 -52 -2 c-29 0 -67
-3 -85 -5z"/>
</g>
</svg>
                        Bass Grooves</a>
                    </li>


                    <li><a   className="text-base" href="/contemporary/songs/m">
                    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="16" height="16" viewBox="0 0 1280.000000 640.000000"
 preserveAspectRatio="xMidYMid meet">
<metadata>
Created by potrace 1.15, written by Peter Selinger 2001-2017
</metadata>
<g transform="translate(0.000000,640.000000) scale(0.100000,-0.100000)"
fill="#6A82FB" stroke="none">
<path d="M1310 5314 c-316 -46 -528 -150 -745 -368 -149 -150 -218 -257 -280
-436 -85 -245 -145 -709 -145 -1122 0 -100 -2 -117 -15 -112 -9 4 -15 0 -15
-9 0 -31 -20 -27 -53 9 -20 22 -39 34 -45 30 -15 -9 -16 -185 -2 -206 9 -13
16 -9 44 23 37 42 52 46 61 17 4 -11 10 -18 14 -16 4 3 11 -115 14 -262 14
-594 81 -948 227 -1194 23 -37 80 -109 128 -159 202 -212 418 -339 687 -402
120 -28 412 -30 555 -3 301 55 564 148 1004 356 379 178 567 234 791 233 122
0 155 -4 255 -30 63 -17 196 -62 295 -101 312 -122 493 -169 695 -179 216 -10
392 41 504 147 78 74 97 149 61 236 -35 85 -113 137 -296 197 -277 91 -425
200 -511 373 -39 80 -43 93 -42 163 0 62 5 85 26 127 43 83 137 153 243 183
27 7 517 18 1510 31 809 11 2125 29 2925 40 1030 14 1481 17 1545 9 175 -19
239 -37 605 -168 150 -53 240 -71 357 -71 113 0 191 18 291 67 95 47 134 43
229 -19 124 -83 149 -92 238 -93 67 0 89 4 135 27 115 57 180 153 180 266 0
78 -17 121 -64 158 -24 19 -36 37 -36 54 0 24 2 25 49 22 l49 -4 12 52 c11 46
10 53 -6 72 -42 45 -129 74 -194 63 -35 -6 -40 -11 -54 -53 -9 -25 -16 -49
-16 -52 0 -4 18 -15 40 -25 44 -20 46 -23 27 -48 -11 -17 -19 -16 -111 13 -55
17 -101 32 -102 33 -2 2 -1 12 2 24 5 18 11 20 49 15 l44 -4 14 48 c19 62 10
79 -63 117 -57 29 -134 37 -162 15 -15 -12 -38 -68 -38 -92 0 -6 16 -20 35
-30 35 -19 41 -31 27 -54 -7 -10 -23 -8 -78 8 -137 42 -131 39 -131 65 0 23 2
23 48 18 l48 -6 11 44 c17 63 5 88 -59 121 -56 29 -125 37 -161 18 -18 -10
-40 -59 -40 -90 0 -9 16 -25 35 -36 27 -14 34 -23 29 -39 -4 -11 -8 -22 -10
-24 -1 -2 -47 10 -101 27 -88 26 -98 32 -101 55 -3 24 -1 25 44 24 l47 -2 13
49 13 50 -33 31 c-37 35 -94 55 -152 55 -49 0 -60 -7 -79 -53 -22 -52 -19 -61
20 -80 35 -17 42 -31 27 -55 -7 -10 -23 -8 -78 8 -139 42 -134 40 -134 66 0
22 3 24 47 21 l46 -2 13 50 c12 47 12 51 -9 74 -35 37 -95 61 -160 61 l-58 0
-20 -46 c-25 -55 -21 -69 25 -91 31 -14 34 -19 24 -37 -6 -12 -11 -22 -12 -23
-1 -1 -44 11 -96 26 -87 26 -95 30 -98 55 -3 27 -2 27 44 24 l48 -3 13 45 c18
60 7 81 -58 114 -39 19 -68 26 -111 26 -53 0 -59 -2 -71 -27 -8 -16 -17 -40
-21 -55 -6 -24 -2 -30 34 -48 37 -19 39 -22 28 -45 -6 -14 -14 -25 -17 -25 -3
0 -49 14 -101 30 -51 17 -114 30 -139 30 -43 0 -48 -3 -120 -79 -47 -50 -87
-82 -106 -87 -28 -7 -1079 2 -3075 27 -454 5 -1133 13 -1510 18 -758 8 -731 6
-766 73 -29 56 -15 118 63 284 39 82 82 181 96 219 37 102 38 105 47 100 4 -3
13 2 20 10 15 18 40 20 40 3 0 -7 11 -26 24 -42 22 -29 24 -29 34 -10 16 31
43 187 34 196 -5 5 -25 -3 -46 -17 -45 -30 -53 -31 -61 -5 -4 11 -11 18 -16
15 -5 -4 -9 26 -9 72 0 160 -51 284 -160 394 -115 116 -247 163 -465 163 -217
-1 -417 -49 -747 -180 -265 -105 -323 -118 -533 -119 -255 0 -328 22 -763 229
-441 211 -592 274 -817 340 -142 41 -239 62 -360 76 -85 9 -250 12 -305 4z"/>
</g>
</svg>
                        Modern Music</a>
                    </li>




                    {/*<li><a href="/voice/exercises">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={18} height={18} fill="#6A82FB"><path d="M16.75 4.36C18.77 6.56 18.77 9.61 16.75 11.63L15.07 9.94C15.91 8.76 15.91 7.23 15.07 6.05L16.75 4.36M20.06 1C24 5.05 23.96 11.11 20.06 15L18.43 13.37C21.2 10.19 21.2 5.65 18.43 2.63L20.06 1M13.36 13.76C15.31 14.42 17 15.5 17 17V22H1V17C1 15.5 2.69 14.42 4.65 13.76L5.27 15L5.5 15.5C4 15.96 2.9 16.62 2.9 17V20.1H7.12L8 15.03L7.06 13.15C7.68 13.08 8.33 13.03 9 13.03S10.32 13.08 10.94 13.15L10 15.03L10.88 20.1H15.1V17C15.1 16.62 14 15.96 12.5 15.5L12.73 15L13.36 13.76M9 6C7.9 6 7 6.9 7 8S7.9 10 9 10 11 9.1 11 8 10.1 6 9 6M9 12C6.79 12 5 10.21 5 8S6.79 4 9 4 13 5.79 13 8 11.21 12 9 12Z" /></svg>
                        Vocal exercises</a>
                    </li>
                    <li><a href="/drums/patterns"> 
                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"  height="18" 
	 viewBox="0 0 500 500" enableBackground="new 0 0 500 500">
<g fill="#6A82FB">
	
		<rect x="294.4" y="115.2" transform="matrix(-0.9387 -0.3446 0.3446 -0.9387 650.1656 356.6668)" fill="#6A82FB" width="124.7" height="10.7"/>
	<polygon fill="#6A82FB" points="411,153.8 293.9,110.8 281.3,145.4 398.4,188.3 	"/>
	
		<rect x="273.2" y="173.2" transform="matrix(-0.9387 -0.3446 0.3446 -0.9387 588.9542 461.8746)" fill="#6A82FB" width="124.7" height="10.7"/>
	
		<rect x="104.9" y="115.3" transform="matrix(0.9387 -0.3446 0.3446 0.9387 -31.3302 65.0347)" fill="#6A82FB" width="124.7" height="10.7"/>
	
		<rect x="115.4" y="131.2" transform="matrix(-0.9387 0.3446 -0.3446 -0.9387 396.2407 228.7773)" fill="#6A82FB" width="124.7" height="36.8"/>
	
		<rect x="183.1" y="116.2" transform="matrix(-0.3446 -0.9387 0.9387 -0.3446 85.8618 416.9727)" fill="#6A82FB" width="10.7" height="124.7"/>
	<path fill="#6A82FB" d="M262,204.7c-60.2,0-109.1,48.9-109.1,109.1S201.8,422.9,262,422.9s109.1-48.9,109.1-109.1
		C371.2,253.6,322.1,204.7,262,204.7z"/>
	<path fill="#6A82FB" d="M262,182.1c-72.8,0-131.7,58.9-131.7,131.7s59,131.7,131.7,131.7c72.8,0,131.7-59,131.7-131.7
		C393.7,241,334.7,182.1,262,182.1z M262,432.1c-65.3,0-118.3-53-118.3-118.3s53-118.3,118.3-118.3s118.3,53,118.3,118.3
		C380.3,379.1,327.3,432.1,262,432.1z"/>
	<path fill="#6A82FB" d="M378.1,221.3H500c0-4.2-27.3-7.7-60.9-7.7C405.4,213.4,378.1,216.9,378.1,221.3z"/>
	<path fill="#6A82FB" d="M439.1,235.9c33.7,0,60.9-3.5,60.9-7.7H378.1C378.1,232.4,405.4,235.9,439.1,235.9z"/>
	<polygon fill="#6A82FB" points="435.8,422.1 412.5,445.6 423.7,445.6 439.8,429.4 455.9,445.6 467.2,445.6 443.8,422.1 
		443.8,235.9 439.1,235.9 435.8,235.9 	"/>
	<path fill="#6A82FB" d="M88.9,81l81,23.1c1.1-4.1-35.9-18.3-82.8-31.7C40.2,58.9,1.1,51.4,0,55.5l81,23.1v343.5l-23.3,23.5h11.2
		L85,429.5l16.1,16.1h11.2L89,422.2V81H88.9z"/>
</g>
</svg>
    Drums patterns</a></li>*/}
                    <li>
                        <details>
                            <summary  className="text-base" >
                            <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#6A82FB"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M4 8h4V4H4v4zm6 12h4v-4h-4v4zm-6 0h4v-4H4v4zm0-6h4v-4H4v4zm6 0h4v-4h-4v4zm6-10v4h4V4h-4zm-6 4h4V4h-4v4zm6 6h4v-4h-4v4zm0 6h4v-4h-4v4z"/></svg>
                                Classical Music
                            </summary>
                            <ul className="p-2 bg-base-100">
                                <li><a href="/bach"> <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#B06AB3"><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><path d="M15.45,16.52l-3.01-3.01c-0.11-0.11-0.24-0.13-0.31-0.13s-0.2,0.02-0.31,0.13L8.8,16.53c-0.34,0.34-0.87,0.89-2.64,0.89 l3.71,3.7c1.17,1.17,3.07,1.17,4.24,0l3.72-3.71C16.92,17.41,16.16,17.23,15.45,16.52z"/><path d="M8.8,7.47l3.02,3.02c0.08,0.08,0.2,0.13,0.31,0.13s0.23-0.05,0.31-0.13l2.99-2.99c0.71-0.74,1.52-0.91,2.43-0.91 l-3.72-3.71c-1.17-1.17-3.07-1.17-4.24,0l-3.71,3.7C7.95,6.58,8.49,7.16,8.8,7.47z"/><path d="M21.11,9.85l-2.25-2.26H17.6c-0.54,0-1.08,0.22-1.45,0.61l-3,3c-0.28,0.28-0.65,0.42-1.02,0.42 c-0.36,0-0.74-0.15-1.02-0.42L8.09,8.17c-0.38-0.38-0.9-0.6-1.45-0.6H5.17l-2.29,2.3c-1.17,1.17-1.17,3.07,0,4.24l2.29,2.3h1.48 c0.54,0,1.06-0.22,1.45-0.6l3.02-3.02c0.28-0.28,0.65-0.42,1.02-0.42c0.37,0,0.74,0.14,1.02,0.42l3.01,3.01 c0.38,0.38,0.9,0.6,1.45,0.6h1.26l2.25-2.26C22.3,12.96,22.3,11.04,21.11,9.85z"/></g></g></svg>Bach</a></li>
                                <li><a href="/beethoven"> <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#B06AB3"><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><path d="M15.45,16.52l-3.01-3.01c-0.11-0.11-0.24-0.13-0.31-0.13s-0.2,0.02-0.31,0.13L8.8,16.53c-0.34,0.34-0.87,0.89-2.64,0.89 l3.71,3.7c1.17,1.17,3.07,1.17,4.24,0l3.72-3.71C16.92,17.41,16.16,17.23,15.45,16.52z"/><path d="M8.8,7.47l3.02,3.02c0.08,0.08,0.2,0.13,0.31,0.13s0.23-0.05,0.31-0.13l2.99-2.99c0.71-0.74,1.52-0.91,2.43-0.91 l-3.72-3.71c-1.17-1.17-3.07-1.17-4.24,0l-3.71,3.7C7.95,6.58,8.49,7.16,8.8,7.47z"/><path d="M21.11,9.85l-2.25-2.26H17.6c-0.54,0-1.08,0.22-1.45,0.61l-3,3c-0.28,0.28-0.65,0.42-1.02,0.42 c-0.36,0-0.74-0.15-1.02-0.42L8.09,8.17c-0.38-0.38-0.9-0.6-1.45-0.6H5.17l-2.29,2.3c-1.17,1.17-1.17,3.07,0,4.24l2.29,2.3h1.48 c0.54,0,1.06-0.22,1.45-0.6l3.02-3.02c0.28-0.28,0.65-0.42,1.02-0.42c0.37,0,0.74,0.14,1.02,0.42l3.01,3.01 c0.38,0.38,0.9,0.6,1.45,0.6h1.26l2.25-2.26C22.3,12.96,22.3,11.04,21.11,9.85z"/></g></g></svg>Beethoven</a></li>
                                <li><a href="/chopin"> <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#B06AB3"><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><path d="M15.45,16.52l-3.01-3.01c-0.11-0.11-0.24-0.13-0.31-0.13s-0.2,0.02-0.31,0.13L8.8,16.53c-0.34,0.34-0.87,0.89-2.64,0.89 l3.71,3.7c1.17,1.17,3.07,1.17,4.24,0l3.72-3.71C16.92,17.41,16.16,17.23,15.45,16.52z"/><path d="M8.8,7.47l3.02,3.02c0.08,0.08,0.2,0.13,0.31,0.13s0.23-0.05,0.31-0.13l2.99-2.99c0.71-0.74,1.52-0.91,2.43-0.91 l-3.72-3.71c-1.17-1.17-3.07-1.17-4.24,0l-3.71,3.7C7.95,6.58,8.49,7.16,8.8,7.47z"/><path d="M21.11,9.85l-2.25-2.26H17.6c-0.54,0-1.08,0.22-1.45,0.61l-3,3c-0.28,0.28-0.65,0.42-1.02,0.42 c-0.36,0-0.74-0.15-1.02-0.42L8.09,8.17c-0.38-0.38-0.9-0.6-1.45-0.6H5.17l-2.29,2.3c-1.17,1.17-1.17,3.07,0,4.24l2.29,2.3h1.48 c0.54,0,1.06-0.22,1.45-0.6l3.02-3.02c0.28-0.28,0.65-0.42,1.02-0.42c0.37,0,0.74,0.14,1.02,0.42l3.01,3.01 c0.38,0.38,0.9,0.6,1.45,0.6h1.26l2.25-2.26C22.3,12.96,22.3,11.04,21.11,9.85z"/></g></g></svg>Chopin</a></li>
                                <li><a href="/corelli"> <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#B06AB3"><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><path d="M15.45,16.52l-3.01-3.01c-0.11-0.11-0.24-0.13-0.31-0.13s-0.2,0.02-0.31,0.13L8.8,16.53c-0.34,0.34-0.87,0.89-2.64,0.89 l3.71,3.7c1.17,1.17,3.07,1.17,4.24,0l3.72-3.71C16.92,17.41,16.16,17.23,15.45,16.52z"/><path d="M8.8,7.47l3.02,3.02c0.08,0.08,0.2,0.13,0.31,0.13s0.23-0.05,0.31-0.13l2.99-2.99c0.71-0.74,1.52-0.91,2.43-0.91 l-3.72-3.71c-1.17-1.17-3.07-1.17-4.24,0l-3.71,3.7C7.95,6.58,8.49,7.16,8.8,7.47z"/><path d="M21.11,9.85l-2.25-2.26H17.6c-0.54,0-1.08,0.22-1.45,0.61l-3,3c-0.28,0.28-0.65,0.42-1.02,0.42 c-0.36,0-0.74-0.15-1.02-0.42L8.09,8.17c-0.38-0.38-0.9-0.6-1.45-0.6H5.17l-2.29,2.3c-1.17,1.17-1.17,3.07,0,4.24l2.29,2.3h1.48 c0.54,0,1.06-0.22,1.45-0.6l3.02-3.02c0.28-0.28,0.65-0.42,1.02-0.42c0.37,0,0.74,0.14,1.02,0.42l3.01,3.01 c0.38,0.38,0.9,0.6,1.45,0.6h1.26l2.25-2.26C22.3,12.96,22.3,11.04,21.11,9.85z"/></g></g></svg>Corelli</a></li>
                                <li><a href="/haydn"> <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#B06AB3"><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><path d="M15.45,16.52l-3.01-3.01c-0.11-0.11-0.24-0.13-0.31-0.13s-0.2,0.02-0.31,0.13L8.8,16.53c-0.34,0.34-0.87,0.89-2.64,0.89 l3.71,3.7c1.17,1.17,3.07,1.17,4.24,0l3.72-3.71C16.92,17.41,16.16,17.23,15.45,16.52z"/><path d="M8.8,7.47l3.02,3.02c0.08,0.08,0.2,0.13,0.31,0.13s0.23-0.05,0.31-0.13l2.99-2.99c0.71-0.74,1.52-0.91,2.43-0.91 l-3.72-3.71c-1.17-1.17-3.07-1.17-4.24,0l-3.71,3.7C7.95,6.58,8.49,7.16,8.8,7.47z"/><path d="M21.11,9.85l-2.25-2.26H17.6c-0.54,0-1.08,0.22-1.45,0.61l-3,3c-0.28,0.28-0.65,0.42-1.02,0.42 c-0.36,0-0.74-0.15-1.02-0.42L8.09,8.17c-0.38-0.38-0.9-0.6-1.45-0.6H5.17l-2.29,2.3c-1.17,1.17-1.17,3.07,0,4.24l2.29,2.3h1.48 c0.54,0,1.06-0.22,1.45-0.6l3.02-3.02c0.28-0.28,0.65-0.42,1.02-0.42c0.37,0,0.74,0.14,1.02,0.42l3.01,3.01 c0.38,0.38,0.9,0.6,1.45,0.6h1.26l2.25-2.26C22.3,12.96,22.3,11.04,21.11,9.85z"/></g></g></svg>Haydn</a></li>
                                <li><a href="/hummel"> <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#B06AB3"><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><path d="M15.45,16.52l-3.01-3.01c-0.11-0.11-0.24-0.13-0.31-0.13s-0.2,0.02-0.31,0.13L8.8,16.53c-0.34,0.34-0.87,0.89-2.64,0.89 l3.71,3.7c1.17,1.17,3.07,1.17,4.24,0l3.72-3.71C16.92,17.41,16.16,17.23,15.45,16.52z"/><path d="M8.8,7.47l3.02,3.02c0.08,0.08,0.2,0.13,0.31,0.13s0.23-0.05,0.31-0.13l2.99-2.99c0.71-0.74,1.52-0.91,2.43-0.91 l-3.72-3.71c-1.17-1.17-3.07-1.17-4.24,0l-3.71,3.7C7.95,6.58,8.49,7.16,8.8,7.47z"/><path d="M21.11,9.85l-2.25-2.26H17.6c-0.54,0-1.08,0.22-1.45,0.61l-3,3c-0.28,0.28-0.65,0.42-1.02,0.42 c-0.36,0-0.74-0.15-1.02-0.42L8.09,8.17c-0.38-0.38-0.9-0.6-1.45-0.6H5.17l-2.29,2.3c-1.17,1.17-1.17,3.07,0,4.24l2.29,2.3h1.48 c0.54,0,1.06-0.22,1.45-0.6l3.02-3.02c0.28-0.28,0.65-0.42,1.02-0.42c0.37,0,0.74,0.14,1.02,0.42l3.01,3.01 c0.38,0.38,0.9,0.6,1.45,0.6h1.26l2.25-2.26C22.3,12.96,22.3,11.04,21.11,9.85z"/></g></g></svg>Hummel</a></li>
                                <li><a href="/joplin"> <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#B06AB3"><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><path d="M15.45,16.52l-3.01-3.01c-0.11-0.11-0.24-0.13-0.31-0.13s-0.2,0.02-0.31,0.13L8.8,16.53c-0.34,0.34-0.87,0.89-2.64,0.89 l3.71,3.7c1.17,1.17,3.07,1.17,4.24,0l3.72-3.71C16.92,17.41,16.16,17.23,15.45,16.52z"/><path d="M8.8,7.47l3.02,3.02c0.08,0.08,0.2,0.13,0.31,0.13s0.23-0.05,0.31-0.13l2.99-2.99c0.71-0.74,1.52-0.91,2.43-0.91 l-3.72-3.71c-1.17-1.17-3.07-1.17-4.24,0l-3.71,3.7C7.95,6.58,8.49,7.16,8.8,7.47z"/><path d="M21.11,9.85l-2.25-2.26H17.6c-0.54,0-1.08,0.22-1.45,0.61l-3,3c-0.28,0.28-0.65,0.42-1.02,0.42 c-0.36,0-0.74-0.15-1.02-0.42L8.09,8.17c-0.38-0.38-0.9-0.6-1.45-0.6H5.17l-2.29,2.3c-1.17,1.17-1.17,3.07,0,4.24l2.29,2.3h1.48 c0.54,0,1.06-0.22,1.45-0.6l3.02-3.02c0.28-0.28,0.65-0.42,1.02-0.42c0.37,0,0.74,0.14,1.02,0.42l3.01,3.01 c0.38,0.38,0.9,0.6,1.45,0.6h1.26l2.25-2.26C22.3,12.96,22.3,11.04,21.11,9.85z"/></g></g></svg>Joplin</a></li>
                                <li><a href="/mozart"> <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#B06AB3"><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><path d="M15.45,16.52l-3.01-3.01c-0.11-0.11-0.24-0.13-0.31-0.13s-0.2,0.02-0.31,0.13L8.8,16.53c-0.34,0.34-0.87,0.89-2.64,0.89 l3.71,3.7c1.17,1.17,3.07,1.17,4.24,0l3.72-3.71C16.92,17.41,16.16,17.23,15.45,16.52z"/><path d="M8.8,7.47l3.02,3.02c0.08,0.08,0.2,0.13,0.31,0.13s0.23-0.05,0.31-0.13l2.99-2.99c0.71-0.74,1.52-0.91,2.43-0.91 l-3.72-3.71c-1.17-1.17-3.07-1.17-4.24,0l-3.71,3.7C7.95,6.58,8.49,7.16,8.8,7.47z"/><path d="M21.11,9.85l-2.25-2.26H17.6c-0.54,0-1.08,0.22-1.45,0.61l-3,3c-0.28,0.28-0.65,0.42-1.02,0.42 c-0.36,0-0.74-0.15-1.02-0.42L8.09,8.17c-0.38-0.38-0.9-0.6-1.45-0.6H5.17l-2.29,2.3c-1.17,1.17-1.17,3.07,0,4.24l2.29,2.3h1.48 c0.54,0,1.06-0.22,1.45-0.6l3.02-3.02c0.28-0.28,0.65-0.42,1.02-0.42c0.37,0,0.74,0.14,1.02,0.42l3.01,3.01 c0.38,0.38,0.9,0.6,1.45,0.6h1.26l2.25-2.26C22.3,12.96,22.3,11.04,21.11,9.85z"/></g></g></svg>Mozart</a></li>
                                <li><a href="/scarlatti"> <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#B06AB3"><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><path d="M15.45,16.52l-3.01-3.01c-0.11-0.11-0.24-0.13-0.31-0.13s-0.2,0.02-0.31,0.13L8.8,16.53c-0.34,0.34-0.87,0.89-2.64,0.89 l3.71,3.7c1.17,1.17,3.07,1.17,4.24,0l3.72-3.71C16.92,17.41,16.16,17.23,15.45,16.52z"/><path d="M8.8,7.47l3.02,3.02c0.08,0.08,0.2,0.13,0.31,0.13s0.23-0.05,0.31-0.13l2.99-2.99c0.71-0.74,1.52-0.91,2.43-0.91 l-3.72-3.71c-1.17-1.17-3.07-1.17-4.24,0l-3.71,3.7C7.95,6.58,8.49,7.16,8.8,7.47z"/><path d="M21.11,9.85l-2.25-2.26H17.6c-0.54,0-1.08,0.22-1.45,0.61l-3,3c-0.28,0.28-0.65,0.42-1.02,0.42 c-0.36,0-0.74-0.15-1.02-0.42L8.09,8.17c-0.38-0.38-0.9-0.6-1.45-0.6H5.17l-2.29,2.3c-1.17,1.17-1.17,3.07,0,4.24l2.29,2.3h1.48 c0.54,0,1.06-0.22,1.45-0.6l3.02-3.02c0.28-0.28,0.65-0.42,1.02-0.42c0.37,0,0.74,0.14,1.02,0.42l3.01,3.01 c0.38,0.38,0.9,0.6,1.45,0.6h1.26l2.25-2.26C22.3,12.96,22.3,11.04,21.11,9.85z"/></g></g></svg>Scarlatti</a></li>
                                <li><a href="/songs"> <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#B06AB3"><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><path d="M15.45,16.52l-3.01-3.01c-0.11-0.11-0.24-0.13-0.31-0.13s-0.2,0.02-0.31,0.13L8.8,16.53c-0.34,0.34-0.87,0.89-2.64,0.89 l3.71,3.7c1.17,1.17,3.07,1.17,4.24,0l3.72-3.71C16.92,17.41,16.16,17.23,15.45,16.52z"/><path d="M8.8,7.47l3.02,3.02c0.08,0.08,0.2,0.13,0.31,0.13s0.23-0.05,0.31-0.13l2.99-2.99c0.71-0.74,1.52-0.91,2.43-0.91 l-3.72-3.71c-1.17-1.17-3.07-1.17-4.24,0l-3.71,3.7C7.95,6.58,8.49,7.16,8.8,7.47z"/><path d="M21.11,9.85l-2.25-2.26H17.6c-0.54,0-1.08,0.22-1.45,0.61l-3,3c-0.28,0.28-0.65,0.42-1.02,0.42 c-0.36,0-0.74-0.15-1.02-0.42L8.09,8.17c-0.38-0.38-0.9-0.6-1.45-0.6H5.17l-2.29,2.3c-1.17,1.17-1.17,3.07,0,4.24l2.29,2.3h1.48 c0.54,0,1.06-0.22,1.45-0.6l3.02-3.02c0.28-0.28,0.65-0.42,1.02-0.42c0.37,0,0.74,0.14,1.02,0.42l3.01,3.01 c0.38,0.38,0.9,0.6,1.45,0.6h1.26l2.25-2.26C22.3,12.96,22.3,11.04,21.11,9.85z"/></g></g></svg>Songs</a></li>
                            </ul>
                        </details>
                    </li>

                    <li>
                        <details>
                            <summary    className="text-base" >
                            <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#6A82FB"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M4 8h4V4H4v4zm6 12h4v-4h-4v4zm-6 0h4v-4H4v4zm0-6h4v-4H4v4zm6 0h4v-4h-4v4zm6-10v4h4V4h-4zm-6 4h4V4h-4v4zm6 6h4v-4h-4v4zm0 6h4v-4h-4v4z"/></svg>
                                Renaissance
                            </summary>
                            <ul className="p-2 bg-base-100">
                            <li><a href="/agricola"> <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#B06AB3"><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><path d="M15.45,16.52l-3.01-3.01c-0.11-0.11-0.24-0.13-0.31-0.13s-0.2,0.02-0.31,0.13L8.8,16.53c-0.34,0.34-0.87,0.89-2.64,0.89 l3.71,3.7c1.17,1.17,3.07,1.17,4.24,0l3.72-3.71C16.92,17.41,16.16,17.23,15.45,16.52z"/><path d="M8.8,7.47l3.02,3.02c0.08,0.08,0.2,0.13,0.31,0.13s0.23-0.05,0.31-0.13l2.99-2.99c0.71-0.74,1.52-0.91,2.43-0.91 l-3.72-3.71c-1.17-1.17-3.07-1.17-4.24,0l-3.71,3.7C7.95,6.58,8.49,7.16,8.8,7.47z"/><path d="M21.11,9.85l-2.25-2.26H17.6c-0.54,0-1.08,0.22-1.45,0.61l-3,3c-0.28,0.28-0.65,0.42-1.02,0.42 c-0.36,0-0.74-0.15-1.02-0.42L8.09,8.17c-0.38-0.38-0.9-0.6-1.45-0.6H5.17l-2.29,2.3c-1.17,1.17-1.17,3.07,0,4.24l2.29,2.3h1.48 c0.54,0,1.06-0.22,1.45-0.6l3.02-3.02c0.28-0.28,0.65-0.42,1.02-0.42c0.37,0,0.74,0.14,1.02,0.42l3.01,3.01 c0.38,0.38,0.9,0.6,1.45,0.6h1.26l2.25-2.26C22.3,12.96,22.3,11.04,21.11,9.85z"/></g></g></svg>Agricola</a></li>
                            <li><a href="/anonymous"> <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#B06AB3"><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><path d="M15.45,16.52l-3.01-3.01c-0.11-0.11-0.24-0.13-0.31-0.13s-0.2,0.02-0.31,0.13L8.8,16.53c-0.34,0.34-0.87,0.89-2.64,0.89 l3.71,3.7c1.17,1.17,3.07,1.17,4.24,0l3.72-3.71C16.92,17.41,16.16,17.23,15.45,16.52z"/><path d="M8.8,7.47l3.02,3.02c0.08,0.08,0.2,0.13,0.31,0.13s0.23-0.05,0.31-0.13l2.99-2.99c0.71-0.74,1.52-0.91,2.43-0.91 l-3.72-3.71c-1.17-1.17-3.07-1.17-4.24,0l-3.71,3.7C7.95,6.58,8.49,7.16,8.8,7.47z"/><path d="M21.11,9.85l-2.25-2.26H17.6c-0.54,0-1.08,0.22-1.45,0.61l-3,3c-0.28,0.28-0.65,0.42-1.02,0.42 c-0.36,0-0.74-0.15-1.02-0.42L8.09,8.17c-0.38-0.38-0.9-0.6-1.45-0.6H5.17l-2.29,2.3c-1.17,1.17-1.17,3.07,0,4.24l2.29,2.3h1.48 c0.54,0,1.06-0.22,1.45-0.6l3.02-3.02c0.28-0.28,0.65-0.42,1.02-0.42c0.37,0,0.74,0.14,1.02,0.42l3.01,3.01 c0.38,0.38,0.9,0.6,1.45,0.6h1.26l2.25-2.26C22.3,12.96,22.3,11.04,21.11,9.85z"/></g></g></svg>Anonymous</a></li>
                            <li><a href="/binchois"> <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#B06AB3"><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><path d="M15.45,16.52l-3.01-3.01c-0.11-0.11-0.24-0.13-0.31-0.13s-0.2,0.02-0.31,0.13L8.8,16.53c-0.34,0.34-0.87,0.89-2.64,0.89 l3.71,3.7c1.17,1.17,3.07,1.17,4.24,0l3.72-3.71C16.92,17.41,16.16,17.23,15.45,16.52z"/><path d="M8.8,7.47l3.02,3.02c0.08,0.08,0.2,0.13,0.31,0.13s0.23-0.05,0.31-0.13l2.99-2.99c0.71-0.74,1.52-0.91,2.43-0.91 l-3.72-3.71c-1.17-1.17-3.07-1.17-4.24,0l-3.71,3.7C7.95,6.58,8.49,7.16,8.8,7.47z"/><path d="M21.11,9.85l-2.25-2.26H17.6c-0.54,0-1.08,0.22-1.45,0.61l-3,3c-0.28,0.28-0.65,0.42-1.02,0.42 c-0.36,0-0.74-0.15-1.02-0.42L8.09,8.17c-0.38-0.38-0.9-0.6-1.45-0.6H5.17l-2.29,2.3c-1.17,1.17-1.17,3.07,0,4.24l2.29,2.3h1.48 c0.54,0,1.06-0.22,1.45-0.6l3.02-3.02c0.28-0.28,0.65-0.42,1.02-0.42c0.37,0,0.74,0.14,1.02,0.42l3.01,3.01 c0.38,0.38,0.9,0.6,1.45,0.6h1.26l2.25-2.26C22.3,12.96,22.3,11.04,21.11,9.85z"/></g></g></svg>Binchois</a></li>
                            <li><a href="/brumel"> <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#B06AB3"><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><path d="M15.45,16.52l-3.01-3.01c-0.11-0.11-0.24-0.13-0.31-0.13s-0.2,0.02-0.31,0.13L8.8,16.53c-0.34,0.34-0.87,0.89-2.64,0.89 l3.71,3.7c1.17,1.17,3.07,1.17,4.24,0l3.72-3.71C16.92,17.41,16.16,17.23,15.45,16.52z"/><path d="M8.8,7.47l3.02,3.02c0.08,0.08,0.2,0.13,0.31,0.13s0.23-0.05,0.31-0.13l2.99-2.99c0.71-0.74,1.52-0.91,2.43-0.91 l-3.72-3.71c-1.17-1.17-3.07-1.17-4.24,0l-3.71,3.7C7.95,6.58,8.49,7.16,8.8,7.47z"/><path d="M21.11,9.85l-2.25-2.26H17.6c-0.54,0-1.08,0.22-1.45,0.61l-3,3c-0.28,0.28-0.65,0.42-1.02,0.42 c-0.36,0-0.74-0.15-1.02-0.42L8.09,8.17c-0.38-0.38-0.9-0.6-1.45-0.6H5.17l-2.29,2.3c-1.17,1.17-1.17,3.07,0,4.24l2.29,2.3h1.48 c0.54,0,1.06-0.22,1.45-0.6l3.02-3.02c0.28-0.28,0.65-0.42,1.02-0.42c0.37,0,0.74,0.14,1.02,0.42l3.01,3.01 c0.38,0.38,0.9,0.6,1.45,0.6h1.26l2.25-2.26C22.3,12.96,22.3,11.04,21.11,9.85z"/></g></g></svg>Brumel</a></li>
                            <li><a href="/busnoys"> <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#B06AB3"><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><path d="M15.45,16.52l-3.01-3.01c-0.11-0.11-0.24-0.13-0.31-0.13s-0.2,0.02-0.31,0.13L8.8,16.53c-0.34,0.34-0.87,0.89-2.64,0.89 l3.71,3.7c1.17,1.17,3.07,1.17,4.24,0l3.72-3.71C16.92,17.41,16.16,17.23,15.45,16.52z"/><path d="M8.8,7.47l3.02,3.02c0.08,0.08,0.2,0.13,0.31,0.13s0.23-0.05,0.31-0.13l2.99-2.99c0.71-0.74,1.52-0.91,2.43-0.91 l-3.72-3.71c-1.17-1.17-3.07-1.17-4.24,0l-3.71,3.7C7.95,6.58,8.49,7.16,8.8,7.47z"/><path d="M21.11,9.85l-2.25-2.26H17.6c-0.54,0-1.08,0.22-1.45,0.61l-3,3c-0.28,0.28-0.65,0.42-1.02,0.42 c-0.36,0-0.74-0.15-1.02-0.42L8.09,8.17c-0.38-0.38-0.9-0.6-1.45-0.6H5.17l-2.29,2.3c-1.17,1.17-1.17,3.07,0,4.24l2.29,2.3h1.48 c0.54,0,1.06-0.22,1.45-0.6l3.02-3.02c0.28-0.28,0.65-0.42,1.02-0.42c0.37,0,0.74,0.14,1.02,0.42l3.01,3.01 c0.38,0.38,0.9,0.6,1.45,0.6h1.26l2.25-2.26C22.3,12.96,22.3,11.04,21.11,9.85z"/></g></g></svg>Busnoys</a></li>
                            <li><a href="/compere"> <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#B06AB3"><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><path d="M15.45,16.52l-3.01-3.01c-0.11-0.11-0.24-0.13-0.31-0.13s-0.2,0.02-0.31,0.13L8.8,16.53c-0.34,0.34-0.87,0.89-2.64,0.89 l3.71,3.7c1.17,1.17,3.07,1.17,4.24,0l3.72-3.71C16.92,17.41,16.16,17.23,15.45,16.52z"/><path d="M8.8,7.47l3.02,3.02c0.08,0.08,0.2,0.13,0.31,0.13s0.23-0.05,0.31-0.13l2.99-2.99c0.71-0.74,1.52-0.91,2.43-0.91 l-3.72-3.71c-1.17-1.17-3.07-1.17-4.24,0l-3.71,3.7C7.95,6.58,8.49,7.16,8.8,7.47z"/><path d="M21.11,9.85l-2.25-2.26H17.6c-0.54,0-1.08,0.22-1.45,0.61l-3,3c-0.28,0.28-0.65,0.42-1.02,0.42 c-0.36,0-0.74-0.15-1.02-0.42L8.09,8.17c-0.38-0.38-0.9-0.6-1.45-0.6H5.17l-2.29,2.3c-1.17,1.17-1.17,3.07,0,4.24l2.29,2.3h1.48 c0.54,0,1.06-0.22,1.45-0.6l3.02-3.02c0.28-0.28,0.65-0.42,1.02-0.42c0.37,0,0.74,0.14,1.02,0.42l3.01,3.01 c0.38,0.38,0.9,0.6,1.45,0.6h1.26l2.25-2.26C22.3,12.96,22.3,11.04,21.11,9.85z"/></g></g></svg>Compere</a></li>
                            <li><a href="/daser"> <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#B06AB3"><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><path d="M15.45,16.52l-3.01-3.01c-0.11-0.11-0.24-0.13-0.31-0.13s-0.2,0.02-0.31,0.13L8.8,16.53c-0.34,0.34-0.87,0.89-2.64,0.89 l3.71,3.7c1.17,1.17,3.07,1.17,4.24,0l3.72-3.71C16.92,17.41,16.16,17.23,15.45,16.52z"/><path d="M8.8,7.47l3.02,3.02c0.08,0.08,0.2,0.13,0.31,0.13s0.23-0.05,0.31-0.13l2.99-2.99c0.71-0.74,1.52-0.91,2.43-0.91 l-3.72-3.71c-1.17-1.17-3.07-1.17-4.24,0l-3.71,3.7C7.95,6.58,8.49,7.16,8.8,7.47z"/><path d="M21.11,9.85l-2.25-2.26H17.6c-0.54,0-1.08,0.22-1.45,0.61l-3,3c-0.28,0.28-0.65,0.42-1.02,0.42 c-0.36,0-0.74-0.15-1.02-0.42L8.09,8.17c-0.38-0.38-0.9-0.6-1.45-0.6H5.17l-2.29,2.3c-1.17,1.17-1.17,3.07,0,4.24l2.29,2.3h1.48 c0.54,0,1.06-0.22,1.45-0.6l3.02-3.02c0.28-0.28,0.65-0.42,1.02-0.42c0.37,0,0.74,0.14,1.02,0.42l3.01,3.01 c0.38,0.38,0.9,0.6,1.45,0.6h1.26l2.25-2.26C22.3,12.96,22.3,11.04,21.11,9.85z"/></g></g></svg>Daser</a></li>
                            <li><a href="/deorto"> <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#B06AB3"><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><path d="M15.45,16.52l-3.01-3.01c-0.11-0.11-0.24-0.13-0.31-0.13s-0.2,0.02-0.31,0.13L8.8,16.53c-0.34,0.34-0.87,0.89-2.64,0.89 l3.71,3.7c1.17,1.17,3.07,1.17,4.24,0l3.72-3.71C16.92,17.41,16.16,17.23,15.45,16.52z"/><path d="M8.8,7.47l3.02,3.02c0.08,0.08,0.2,0.13,0.31,0.13s0.23-0.05,0.31-0.13l2.99-2.99c0.71-0.74,1.52-0.91,2.43-0.91 l-3.72-3.71c-1.17-1.17-3.07-1.17-4.24,0l-3.71,3.7C7.95,6.58,8.49,7.16,8.8,7.47z"/><path d="M21.11,9.85l-2.25-2.26H17.6c-0.54,0-1.08,0.22-1.45,0.61l-3,3c-0.28,0.28-0.65,0.42-1.02,0.42 c-0.36,0-0.74-0.15-1.02-0.42L8.09,8.17c-0.38-0.38-0.9-0.6-1.45-0.6H5.17l-2.29,2.3c-1.17,1.17-1.17,3.07,0,4.24l2.29,2.3h1.48 c0.54,0,1.06-0.22,1.45-0.6l3.02-3.02c0.28-0.28,0.65-0.42,1.02-0.42c0.37,0,0.74,0.14,1.02,0.42l3.01,3.01 c0.38,0.38,0.9,0.6,1.45,0.6h1.26l2.25-2.26C22.3,12.96,22.3,11.04,21.11,9.85z"/></g></g></svg>De Orto</a></li>
                            <li><a href="/dufay"> <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#B06AB3"><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><path d="M15.45,16.52l-3.01-3.01c-0.11-0.11-0.24-0.13-0.31-0.13s-0.2,0.02-0.31,0.13L8.8,16.53c-0.34,0.34-0.87,0.89-2.64,0.89 l3.71,3.7c1.17,1.17,3.07,1.17,4.24,0l3.72-3.71C16.92,17.41,16.16,17.23,15.45,16.52z"/><path d="M8.8,7.47l3.02,3.02c0.08,0.08,0.2,0.13,0.31,0.13s0.23-0.05,0.31-0.13l2.99-2.99c0.71-0.74,1.52-0.91,2.43-0.91 l-3.72-3.71c-1.17-1.17-3.07-1.17-4.24,0l-3.71,3.7C7.95,6.58,8.49,7.16,8.8,7.47z"/><path d="M21.11,9.85l-2.25-2.26H17.6c-0.54,0-1.08,0.22-1.45,0.61l-3,3c-0.28,0.28-0.65,0.42-1.02,0.42 c-0.36,0-0.74-0.15-1.02-0.42L8.09,8.17c-0.38-0.38-0.9-0.6-1.45-0.6H5.17l-2.29,2.3c-1.17,1.17-1.17,3.07,0,4.24l2.29,2.3h1.48 c0.54,0,1.06-0.22,1.45-0.6l3.02-3.02c0.28-0.28,0.65-0.42,1.02-0.42c0.37,0,0.74,0.14,1.02,0.42l3.01,3.01 c0.38,0.38,0.9,0.6,1.45,0.6h1.26l2.25-2.26C22.3,12.96,22.3,11.04,21.11,9.85z"/></g></g></svg>Du Fay</a></li>
                            <li><a href="/frye"> <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#B06AB3"><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><path d="M15.45,16.52l-3.01-3.01c-0.11-0.11-0.24-0.13-0.31-0.13s-0.2,0.02-0.31,0.13L8.8,16.53c-0.34,0.34-0.87,0.89-2.64,0.89 l3.71,3.7c1.17,1.17,3.07,1.17,4.24,0l3.72-3.71C16.92,17.41,16.16,17.23,15.45,16.52z"/><path d="M8.8,7.47l3.02,3.02c0.08,0.08,0.2,0.13,0.31,0.13s0.23-0.05,0.31-0.13l2.99-2.99c0.71-0.74,1.52-0.91,2.43-0.91 l-3.72-3.71c-1.17-1.17-3.07-1.17-4.24,0l-3.71,3.7C7.95,6.58,8.49,7.16,8.8,7.47z"/><path d="M21.11,9.85l-2.25-2.26H17.6c-0.54,0-1.08,0.22-1.45,0.61l-3,3c-0.28,0.28-0.65,0.42-1.02,0.42 c-0.36,0-0.74-0.15-1.02-0.42L8.09,8.17c-0.38-0.38-0.9-0.6-1.45-0.6H5.17l-2.29,2.3c-1.17,1.17-1.17,3.07,0,4.24l2.29,2.3h1.48 c0.54,0,1.06-0.22,1.45-0.6l3.02-3.02c0.28-0.28,0.65-0.42,1.02-0.42c0.37,0,0.74,0.14,1.02,0.42l3.01,3.01 c0.38,0.38,0.9,0.6,1.45,0.6h1.26l2.25-2.26C22.3,12.96,22.3,11.04,21.11,9.85z"/></g></g></svg>Frye</a></li>
                            <li><a href="/fevin"> <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#B06AB3"><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><path d="M15.45,16.52l-3.01-3.01c-0.11-0.11-0.24-0.13-0.31-0.13s-0.2,0.02-0.31,0.13L8.8,16.53c-0.34,0.34-0.87,0.89-2.64,0.89 l3.71,3.7c1.17,1.17,3.07,1.17,4.24,0l3.72-3.71C16.92,17.41,16.16,17.23,15.45,16.52z"/><path d="M8.8,7.47l3.02,3.02c0.08,0.08,0.2,0.13,0.31,0.13s0.23-0.05,0.31-0.13l2.99-2.99c0.71-0.74,1.52-0.91,2.43-0.91 l-3.72-3.71c-1.17-1.17-3.07-1.17-4.24,0l-3.71,3.7C7.95,6.58,8.49,7.16,8.8,7.47z"/><path d="M21.11,9.85l-2.25-2.26H17.6c-0.54,0-1.08,0.22-1.45,0.61l-3,3c-0.28,0.28-0.65,0.42-1.02,0.42 c-0.36,0-0.74-0.15-1.02-0.42L8.09,8.17c-0.38-0.38-0.9-0.6-1.45-0.6H5.17l-2.29,2.3c-1.17,1.17-1.17,3.07,0,4.24l2.29,2.3h1.48 c0.54,0,1.06-0.22,1.45-0.6l3.02-3.02c0.28-0.28,0.65-0.42,1.02-0.42c0.37,0,0.74,0.14,1.02,0.42l3.01,3.01 c0.38,0.38,0.9,0.6,1.45,0.6h1.26l2.25-2.26C22.3,12.96,22.3,11.04,21.11,9.85z"/></g></g></svg>Févin</a></li>
                            <li><a href="/gaspard"> <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#B06AB3"><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><path d="M15.45,16.52l-3.01-3.01c-0.11-0.11-0.24-0.13-0.31-0.13s-0.2,0.02-0.31,0.13L8.8,16.53c-0.34,0.34-0.87,0.89-2.64,0.89 l3.71,3.7c1.17,1.17,3.07,1.17,4.24,0l3.72-3.71C16.92,17.41,16.16,17.23,15.45,16.52z"/><path d="M8.8,7.47l3.02,3.02c0.08,0.08,0.2,0.13,0.31,0.13s0.23-0.05,0.31-0.13l2.99-2.99c0.71-0.74,1.52-0.91,2.43-0.91 l-3.72-3.71c-1.17-1.17-3.07-1.17-4.24,0l-3.71,3.7C7.95,6.58,8.49,7.16,8.8,7.47z"/><path d="M21.11,9.85l-2.25-2.26H17.6c-0.54,0-1.08,0.22-1.45,0.61l-3,3c-0.28,0.28-0.65,0.42-1.02,0.42 c-0.36,0-0.74-0.15-1.02-0.42L8.09,8.17c-0.38-0.38-0.9-0.6-1.45-0.6H5.17l-2.29,2.3c-1.17,1.17-1.17,3.07,0,4.24l2.29,2.3h1.48 c0.54,0,1.06-0.22,1.45-0.6l3.02-3.02c0.28-0.28,0.65-0.42,1.02-0.42c0.37,0,0.74,0.14,1.02,0.42l3.01,3.01 c0.38,0.38,0.9,0.6,1.45,0.6h1.26l2.25-2.26C22.3,12.96,22.3,11.04,21.11,9.85z"/></g></g></svg>Gaspard</a></li>
                            <li><a href="/isaac"> <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#B06AB3"><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><path d="M15.45,16.52l-3.01-3.01c-0.11-0.11-0.24-0.13-0.31-0.13s-0.2,0.02-0.31,0.13L8.8,16.53c-0.34,0.34-0.87,0.89-2.64,0.89 l3.71,3.7c1.17,1.17,3.07,1.17,4.24,0l3.72-3.71C16.92,17.41,16.16,17.23,15.45,16.52z"/><path d="M8.8,7.47l3.02,3.02c0.08,0.08,0.2,0.13,0.31,0.13s0.23-0.05,0.31-0.13l2.99-2.99c0.71-0.74,1.52-0.91,2.43-0.91 l-3.72-3.71c-1.17-1.17-3.07-1.17-4.24,0l-3.71,3.7C7.95,6.58,8.49,7.16,8.8,7.47z"/><path d="M21.11,9.85l-2.25-2.26H17.6c-0.54,0-1.08,0.22-1.45,0.61l-3,3c-0.28,0.28-0.65,0.42-1.02,0.42 c-0.36,0-0.74-0.15-1.02-0.42L8.09,8.17c-0.38-0.38-0.9-0.6-1.45-0.6H5.17l-2.29,2.3c-1.17,1.17-1.17,3.07,0,4.24l2.29,2.3h1.48 c0.54,0,1.06-0.22,1.45-0.6l3.02-3.02c0.28-0.28,0.65-0.42,1.02-0.42c0.37,0,0.74,0.14,1.02,0.42l3.01,3.01 c0.38,0.38,0.9,0.6,1.45,0.6h1.26l2.25-2.26C22.3,12.96,22.3,11.04,21.11,9.85z"/></g></g></svg>Isaac</a></li>
                            <li><a href="/japart"> <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#B06AB3"><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><path d="M15.45,16.52l-3.01-3.01c-0.11-0.11-0.24-0.13-0.31-0.13s-0.2,0.02-0.31,0.13L8.8,16.53c-0.34,0.34-0.87,0.89-2.64,0.89 l3.71,3.7c1.17,1.17,3.07,1.17,4.24,0l3.72-3.71C16.92,17.41,16.16,17.23,15.45,16.52z"/><path d="M8.8,7.47l3.02,3.02c0.08,0.08,0.2,0.13,0.31,0.13s0.23-0.05,0.31-0.13l2.99-2.99c0.71-0.74,1.52-0.91,2.43-0.91 l-3.72-3.71c-1.17-1.17-3.07-1.17-4.24,0l-3.71,3.7C7.95,6.58,8.49,7.16,8.8,7.47z"/><path d="M21.11,9.85l-2.25-2.26H17.6c-0.54,0-1.08,0.22-1.45,0.61l-3,3c-0.28,0.28-0.65,0.42-1.02,0.42 c-0.36,0-0.74-0.15-1.02-0.42L8.09,8.17c-0.38-0.38-0.9-0.6-1.45-0.6H5.17l-2.29,2.3c-1.17,1.17-1.17,3.07,0,4.24l2.29,2.3h1.48 c0.54,0,1.06-0.22,1.45-0.6l3.02-3.02c0.28-0.28,0.65-0.42,1.02-0.42c0.37,0,0.74,0.14,1.02,0.42l3.01,3.01 c0.38,0.38,0.9,0.6,1.45,0.6h1.26l2.25-2.26C22.3,12.96,22.3,11.04,21.11,9.85z"/></g></g></svg>Japart</a></li>
                            <li><a href="/josquin"> <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#B06AB3"><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><path d="M15.45,16.52l-3.01-3.01c-0.11-0.11-0.24-0.13-0.31-0.13s-0.2,0.02-0.31,0.13L8.8,16.53c-0.34,0.34-0.87,0.89-2.64,0.89 l3.71,3.7c1.17,1.17,3.07,1.17,4.24,0l3.72-3.71C16.92,17.41,16.16,17.23,15.45,16.52z"/><path d="M8.8,7.47l3.02,3.02c0.08,0.08,0.2,0.13,0.31,0.13s0.23-0.05,0.31-0.13l2.99-2.99c0.71-0.74,1.52-0.91,2.43-0.91 l-3.72-3.71c-1.17-1.17-3.07-1.17-4.24,0l-3.71,3.7C7.95,6.58,8.49,7.16,8.8,7.47z"/><path d="M21.11,9.85l-2.25-2.26H17.6c-0.54,0-1.08,0.22-1.45,0.61l-3,3c-0.28,0.28-0.65,0.42-1.02,0.42 c-0.36,0-0.74-0.15-1.02-0.42L8.09,8.17c-0.38-0.38-0.9-0.6-1.45-0.6H5.17l-2.29,2.3c-1.17,1.17-1.17,3.07,0,4.24l2.29,2.3h1.48 c0.54,0,1.06-0.22,1.45-0.6l3.02-3.02c0.28-0.28,0.65-0.42,1.02-0.42c0.37,0,0.74,0.14,1.02,0.42l3.01,3.01 c0.38,0.38,0.9,0.6,1.45,0.6h1.26l2.25-2.26C22.3,12.96,22.3,11.04,21.11,9.85z"/></g></g></svg>Josquin</a></li>
                            <li><a href="/larue"> <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#B06AB3"><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><path d="M15.45,16.52l-3.01-3.01c-0.11-0.11-0.24-0.13-0.31-0.13s-0.2,0.02-0.31,0.13L8.8,16.53c-0.34,0.34-0.87,0.89-2.64,0.89 l3.71,3.7c1.17,1.17,3.07,1.17,4.24,0l3.72-3.71C16.92,17.41,16.16,17.23,15.45,16.52z"/><path d="M8.8,7.47l3.02,3.02c0.08,0.08,0.2,0.13,0.31,0.13s0.23-0.05,0.31-0.13l2.99-2.99c0.71-0.74,1.52-0.91,2.43-0.91 l-3.72-3.71c-1.17-1.17-3.07-1.17-4.24,0l-3.71,3.7C7.95,6.58,8.49,7.16,8.8,7.47z"/><path d="M21.11,9.85l-2.25-2.26H17.6c-0.54,0-1.08,0.22-1.45,0.61l-3,3c-0.28,0.28-0.65,0.42-1.02,0.42 c-0.36,0-0.74-0.15-1.02-0.42L8.09,8.17c-0.38-0.38-0.9-0.6-1.45-0.6H5.17l-2.29,2.3c-1.17,1.17-1.17,3.07,0,4.24l2.29,2.3h1.48 c0.54,0,1.06-0.22,1.45-0.6l3.02-3.02c0.28-0.28,0.65-0.42,1.02-0.42c0.37,0,0.74,0.14,1.02,0.42l3.01,3.01 c0.38,0.38,0.9,0.6,1.45,0.6h1.26l2.25-2.26C22.3,12.96,22.3,11.04,21.11,9.85z"/></g></g></svg>La Rue</a></li>
                            <li><a href="/martini"> <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#B06AB3"><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><path d="M15.45,16.52l-3.01-3.01c-0.11-0.11-0.24-0.13-0.31-0.13s-0.2,0.02-0.31,0.13L8.8,16.53c-0.34,0.34-0.87,0.89-2.64,0.89 l3.71,3.7c1.17,1.17,3.07,1.17,4.24,0l3.72-3.71C16.92,17.41,16.16,17.23,15.45,16.52z"/><path d="M8.8,7.47l3.02,3.02c0.08,0.08,0.2,0.13,0.31,0.13s0.23-0.05,0.31-0.13l2.99-2.99c0.71-0.74,1.52-0.91,2.43-0.91 l-3.72-3.71c-1.17-1.17-3.07-1.17-4.24,0l-3.71,3.7C7.95,6.58,8.49,7.16,8.8,7.47z"/><path d="M21.11,9.85l-2.25-2.26H17.6c-0.54,0-1.08,0.22-1.45,0.61l-3,3c-0.28,0.28-0.65,0.42-1.02,0.42 c-0.36,0-0.74-0.15-1.02-0.42L8.09,8.17c-0.38-0.38-0.9-0.6-1.45-0.6H5.17l-2.29,2.3c-1.17,1.17-1.17,3.07,0,4.24l2.29,2.3h1.48 c0.54,0,1.06-0.22,1.45-0.6l3.02-3.02c0.28-0.28,0.65-0.42,1.02-0.42c0.37,0,0.74,0.14,1.02,0.42l3.01,3.01 c0.38,0.38,0.9,0.6,1.45,0.6h1.26l2.25-2.26C22.3,12.96,22.3,11.04,21.11,9.85z"/></g></g></svg>Martini</a></li>
                            <li><a href="/mouton"> <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#B06AB3"><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><path d="M15.45,16.52l-3.01-3.01c-0.11-0.11-0.24-0.13-0.31-0.13s-0.2,0.02-0.31,0.13L8.8,16.53c-0.34,0.34-0.87,0.89-2.64,0.89 l3.71,3.7c1.17,1.17,3.07,1.17,4.24,0l3.72-3.71C16.92,17.41,16.16,17.23,15.45,16.52z"/><path d="M8.8,7.47l3.02,3.02c0.08,0.08,0.2,0.13,0.31,0.13s0.23-0.05,0.31-0.13l2.99-2.99c0.71-0.74,1.52-0.91,2.43-0.91 l-3.72-3.71c-1.17-1.17-3.07-1.17-4.24,0l-3.71,3.7C7.95,6.58,8.49,7.16,8.8,7.47z"/><path d="M21.11,9.85l-2.25-2.26H17.6c-0.54,0-1.08,0.22-1.45,0.61l-3,3c-0.28,0.28-0.65,0.42-1.02,0.42 c-0.36,0-0.74-0.15-1.02-0.42L8.09,8.17c-0.38-0.38-0.9-0.6-1.45-0.6H5.17l-2.29,2.3c-1.17,1.17-1.17,3.07,0,4.24l2.29,2.3h1.48 c0.54,0,1.06-0.22,1.45-0.6l3.02-3.02c0.28-0.28,0.65-0.42,1.02-0.42c0.37,0,0.74,0.14,1.02,0.42l3.01,3.01 c0.38,0.38,0.9,0.6,1.45,0.6h1.26l2.25-2.26C22.3,12.96,22.3,11.04,21.11,9.85z"/></g></g></svg>Mouton</a></li>
                            <li><a href="/obrecht"> <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#B06AB3"><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><path d="M15.45,16.52l-3.01-3.01c-0.11-0.11-0.24-0.13-0.31-0.13s-0.2,0.02-0.31,0.13L8.8,16.53c-0.34,0.34-0.87,0.89-2.64,0.89 l3.71,3.7c1.17,1.17,3.07,1.17,4.24,0l3.72-3.71C16.92,17.41,16.16,17.23,15.45,16.52z"/><path d="M8.8,7.47l3.02,3.02c0.08,0.08,0.2,0.13,0.31,0.13s0.23-0.05,0.31-0.13l2.99-2.99c0.71-0.74,1.52-0.91,2.43-0.91 l-3.72-3.71c-1.17-1.17-3.07-1.17-4.24,0l-3.71,3.7C7.95,6.58,8.49,7.16,8.8,7.47z"/><path d="M21.11,9.85l-2.25-2.26H17.6c-0.54,0-1.08,0.22-1.45,0.61l-3,3c-0.28,0.28-0.65,0.42-1.02,0.42 c-0.36,0-0.74-0.15-1.02-0.42L8.09,8.17c-0.38-0.38-0.9-0.6-1.45-0.6H5.17l-2.29,2.3c-1.17,1.17-1.17,3.07,0,4.24l2.29,2.3h1.48 c0.54,0,1.06-0.22,1.45-0.6l3.02-3.02c0.28-0.28,0.65-0.42,1.02-0.42c0.37,0,0.74,0.14,1.02,0.42l3.01,3.01 c0.38,0.38,0.9,0.6,1.45,0.6h1.26l2.25-2.26C22.3,12.96,22.3,11.04,21.11,9.85z"/></g></g></svg>Obrecht</a></li>
                            <li><a href="/ockeghem"> <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#B06AB3"><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><path d="M15.45,16.52l-3.01-3.01c-0.11-0.11-0.24-0.13-0.31-0.13s-0.2,0.02-0.31,0.13L8.8,16.53c-0.34,0.34-0.87,0.89-2.64,0.89 l3.71,3.7c1.17,1.17,3.07,1.17,4.24,0l3.72-3.71C16.92,17.41,16.16,17.23,15.45,16.52z"/><path d="M8.8,7.47l3.02,3.02c0.08,0.08,0.2,0.13,0.31,0.13s0.23-0.05,0.31-0.13l2.99-2.99c0.71-0.74,1.52-0.91,2.43-0.91 l-3.72-3.71c-1.17-1.17-3.07-1.17-4.24,0l-3.71,3.7C7.95,6.58,8.49,7.16,8.8,7.47z"/><path d="M21.11,9.85l-2.25-2.26H17.6c-0.54,0-1.08,0.22-1.45,0.61l-3,3c-0.28,0.28-0.65,0.42-1.02,0.42 c-0.36,0-0.74-0.15-1.02-0.42L8.09,8.17c-0.38-0.38-0.9-0.6-1.45-0.6H5.17l-2.29,2.3c-1.17,1.17-1.17,3.07,0,4.24l2.29,2.3h1.48 c0.54,0,1.06-0.22,1.45-0.6l3.02-3.02c0.28-0.28,0.65-0.42,1.02-0.42c0.37,0,0.74,0.14,1.02,0.42l3.01,3.01 c0.38,0.38,0.9,0.6,1.45,0.6h1.26l2.25-2.26C22.3,12.96,22.3,11.04,21.11,9.85z"/></g></g></svg>Ockeghem</a></li>
                            <li><a href="/pipelare"> <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#B06AB3"><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><path d="M15.45,16.52l-3.01-3.01c-0.11-0.11-0.24-0.13-0.31-0.13s-0.2,0.02-0.31,0.13L8.8,16.53c-0.34,0.34-0.87,0.89-2.64,0.89 l3.71,3.7c1.17,1.17,3.07,1.17,4.24,0l3.72-3.71C16.92,17.41,16.16,17.23,15.45,16.52z"/><path d="M8.8,7.47l3.02,3.02c0.08,0.08,0.2,0.13,0.31,0.13s0.23-0.05,0.31-0.13l2.99-2.99c0.71-0.74,1.52-0.91,2.43-0.91 l-3.72-3.71c-1.17-1.17-3.07-1.17-4.24,0l-3.71,3.7C7.95,6.58,8.49,7.16,8.8,7.47z"/><path d="M21.11,9.85l-2.25-2.26H17.6c-0.54,0-1.08,0.22-1.45,0.61l-3,3c-0.28,0.28-0.65,0.42-1.02,0.42 c-0.36,0-0.74-0.15-1.02-0.42L8.09,8.17c-0.38-0.38-0.9-0.6-1.45-0.6H5.17l-2.29,2.3c-1.17,1.17-1.17,3.07,0,4.24l2.29,2.3h1.48 c0.54,0,1.06-0.22,1.45-0.6l3.02-3.02c0.28-0.28,0.65-0.42,1.02-0.42c0.37,0,0.74,0.14,1.02,0.42l3.01,3.01 c0.38,0.38,0.9,0.6,1.45,0.6h1.26l2.25-2.26C22.3,12.96,22.3,11.04,21.11,9.85z"/></g></g></svg>Pipelare</a></li>
                            <li><a href="/regis"> <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#B06AB3"><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><path d="M15.45,16.52l-3.01-3.01c-0.11-0.11-0.24-0.13-0.31-0.13s-0.2,0.02-0.31,0.13L8.8,16.53c-0.34,0.34-0.87,0.89-2.64,0.89 l3.71,3.7c1.17,1.17,3.07,1.17,4.24,0l3.72-3.71C16.92,17.41,16.16,17.23,15.45,16.52z"/><path d="M8.8,7.47l3.02,3.02c0.08,0.08,0.2,0.13,0.31,0.13s0.23-0.05,0.31-0.13l2.99-2.99c0.71-0.74,1.52-0.91,2.43-0.91 l-3.72-3.71c-1.17-1.17-3.07-1.17-4.24,0l-3.71,3.7C7.95,6.58,8.49,7.16,8.8,7.47z"/><path d="M21.11,9.85l-2.25-2.26H17.6c-0.54,0-1.08,0.22-1.45,0.61l-3,3c-0.28,0.28-0.65,0.42-1.02,0.42 c-0.36,0-0.74-0.15-1.02-0.42L8.09,8.17c-0.38-0.38-0.9-0.6-1.45-0.6H5.17l-2.29,2.3c-1.17,1.17-1.17,3.07,0,4.24l2.29,2.3h1.48 c0.54,0,1.06-0.22,1.45-0.6l3.02-3.02c0.28-0.28,0.65-0.42,1.02-0.42c0.37,0,0.74,0.14,1.02,0.42l3.01,3.01 c0.38,0.38,0.9,0.6,1.45,0.6h1.26l2.25-2.26C22.3,12.96,22.3,11.04,21.11,9.85z"/></g></g></svg>Regis</a></li>
                            <li><a href="/tinctoris"> <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#B06AB3"><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><path d="M15.45,16.52l-3.01-3.01c-0.11-0.11-0.24-0.13-0.31-0.13s-0.2,0.02-0.31,0.13L8.8,16.53c-0.34,0.34-0.87,0.89-2.64,0.89 l3.71,3.7c1.17,1.17,3.07,1.17,4.24,0l3.72-3.71C16.92,17.41,16.16,17.23,15.45,16.52z"/><path d="M8.8,7.47l3.02,3.02c0.08,0.08,0.2,0.13,0.31,0.13s0.23-0.05,0.31-0.13l2.99-2.99c0.71-0.74,1.52-0.91,2.43-0.91 l-3.72-3.71c-1.17-1.17-3.07-1.17-4.24,0l-3.71,3.7C7.95,6.58,8.49,7.16,8.8,7.47z"/><path d="M21.11,9.85l-2.25-2.26H17.6c-0.54,0-1.08,0.22-1.45,0.61l-3,3c-0.28,0.28-0.65,0.42-1.02,0.42 c-0.36,0-0.74-0.15-1.02-0.42L8.09,8.17c-0.38-0.38-0.9-0.6-1.45-0.6H5.17l-2.29,2.3c-1.17,1.17-1.17,3.07,0,4.24l2.29,2.3h1.48 c0.54,0,1.06-0.22,1.45-0.6l3.02-3.02c0.28-0.28,0.65-0.42,1.02-0.42c0.37,0,0.74,0.14,1.02,0.42l3.01,3.01 c0.38,0.38,0.9,0.6,1.45,0.6h1.26l2.25-2.26C22.3,12.96,22.3,11.04,21.11,9.85z"/></g></g></svg>Tinctoris</a></li>
                            </ul>
                        </details>
                    </li>
                    <li>
                        <details>
                            <summary    className="text-base" >
                            <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#6A82FB"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M4 8h4V4H4v4zm6 12h4v-4h-4v4zm-6 0h4v-4H4v4zm0-6h4v-4H4v4zm6 0h4v-4h-4v4zm6-10v4h4V4h-4zm-6 4h4V4h-4v4zm6 6h4v-4h-4v4zm0 6h4v-4h-4v4z"/></svg>
                                Native American
                            </summary>
                            <ul className="p-2 bg-base-100">
                            <li><a href="/acoma"> <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#B06AB3"><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><path d="M15.45,16.52l-3.01-3.01c-0.11-0.11-0.24-0.13-0.31-0.13s-0.2,0.02-0.31,0.13L8.8,16.53c-0.34,0.34-0.87,0.89-2.64,0.89 l3.71,3.7c1.17,1.17,3.07,1.17,4.24,0l3.72-3.71C16.92,17.41,16.16,17.23,15.45,16.52z"/><path d="M8.8,7.47l3.02,3.02c0.08,0.08,0.2,0.13,0.31,0.13s0.23-0.05,0.31-0.13l2.99-2.99c0.71-0.74,1.52-0.91,2.43-0.91 l-3.72-3.71c-1.17-1.17-3.07-1.17-4.24,0l-3.71,3.7C7.95,6.58,8.49,7.16,8.8,7.47z"/><path d="M21.11,9.85l-2.25-2.26H17.6c-0.54,0-1.08,0.22-1.45,0.61l-3,3c-0.28,0.28-0.65,0.42-1.02,0.42 c-0.36,0-0.74-0.15-1.02-0.42L8.09,8.17c-0.38-0.38-0.9-0.6-1.45-0.6H5.17l-2.29,2.3c-1.17,1.17-1.17,3.07,0,4.24l2.29,2.3h1.48 c0.54,0,1.06-0.22,1.45-0.6l3.02-3.02c0.28-0.28,0.65-0.42,1.02-0.42c0.37,0,0.74,0.14,1.02,0.42l3.01,3.01 c0.38,0.38,0.9,0.6,1.45,0.6h1.26l2.25-2.26C22.3,12.96,22.3,11.04,21.11,9.85z"/></g></g></svg>Acoma</a></li>
                            <li><a href="/britishcolumbia"> <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#B06AB3"><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><path d="M15.45,16.52l-3.01-3.01c-0.11-0.11-0.24-0.13-0.31-0.13s-0.2,0.02-0.31,0.13L8.8,16.53c-0.34,0.34-0.87,0.89-2.64,0.89 l3.71,3.7c1.17,1.17,3.07,1.17,4.24,0l3.72-3.71C16.92,17.41,16.16,17.23,15.45,16.52z"/><path d="M8.8,7.47l3.02,3.02c0.08,0.08,0.2,0.13,0.31,0.13s0.23-0.05,0.31-0.13l2.99-2.99c0.71-0.74,1.52-0.91,2.43-0.91 l-3.72-3.71c-1.17-1.17-3.07-1.17-4.24,0l-3.71,3.7C7.95,6.58,8.49,7.16,8.8,7.47z"/><path d="M21.11,9.85l-2.25-2.26H17.6c-0.54,0-1.08,0.22-1.45,0.61l-3,3c-0.28,0.28-0.65,0.42-1.02,0.42 c-0.36,0-0.74-0.15-1.02-0.42L8.09,8.17c-0.38-0.38-0.9-0.6-1.45-0.6H5.17l-2.29,2.3c-1.17,1.17-1.17,3.07,0,4.24l2.29,2.3h1.48 c0.54,0,1.06-0.22,1.45-0.6l3.02-3.02c0.28-0.28,0.65-0.42,1.02-0.42c0.37,0,0.74,0.14,1.02,0.42l3.01,3.01 c0.38,0.38,0.9,0.6,1.45,0.6h1.26l2.25-2.26C22.3,12.96,22.3,11.04,21.11,9.85z"/></g></g></svg>British Columbia</a></li>
                            <li><a href="/cheyenneandarapaho"> <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#B06AB3"><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><path d="M15.45,16.52l-3.01-3.01c-0.11-0.11-0.24-0.13-0.31-0.13s-0.2,0.02-0.31,0.13L8.8,16.53c-0.34,0.34-0.87,0.89-2.64,0.89 l3.71,3.7c1.17,1.17,3.07,1.17,4.24,0l3.72-3.71C16.92,17.41,16.16,17.23,15.45,16.52z"/><path d="M8.8,7.47l3.02,3.02c0.08,0.08,0.2,0.13,0.31,0.13s0.23-0.05,0.31-0.13l2.99-2.99c0.71-0.74,1.52-0.91,2.43-0.91 l-3.72-3.71c-1.17-1.17-3.07-1.17-4.24,0l-3.71,3.7C7.95,6.58,8.49,7.16,8.8,7.47z"/><path d="M21.11,9.85l-2.25-2.26H17.6c-0.54,0-1.08,0.22-1.45,0.61l-3,3c-0.28,0.28-0.65,0.42-1.02,0.42 c-0.36,0-0.74-0.15-1.02-0.42L8.09,8.17c-0.38-0.38-0.9-0.6-1.45-0.6H5.17l-2.29,2.3c-1.17,1.17-1.17,3.07,0,4.24l2.29,2.3h1.48 c0.54,0,1.06-0.22,1.45-0.6l3.02-3.02c0.28-0.28,0.65-0.42,1.02-0.42c0.37,0,0.74,0.14,1.02,0.42l3.01,3.01 c0.38,0.38,0.9,0.6,1.45,0.6h1.26l2.25-2.26C22.3,12.96,22.3,11.04,21.11,9.85z"/></g></g></svg>Cheyenne and Arapaho</a></li>
                            <li><a href="/chippewa1"> <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#B06AB3"><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><path d="M15.45,16.52l-3.01-3.01c-0.11-0.11-0.24-0.13-0.31-0.13s-0.2,0.02-0.31,0.13L8.8,16.53c-0.34,0.34-0.87,0.89-2.64,0.89 l3.71,3.7c1.17,1.17,3.07,1.17,4.24,0l3.72-3.71C16.92,17.41,16.16,17.23,15.45,16.52z"/><path d="M8.8,7.47l3.02,3.02c0.08,0.08,0.2,0.13,0.31,0.13s0.23-0.05,0.31-0.13l2.99-2.99c0.71-0.74,1.52-0.91,2.43-0.91 l-3.72-3.71c-1.17-1.17-3.07-1.17-4.24,0l-3.71,3.7C7.95,6.58,8.49,7.16,8.8,7.47z"/><path d="M21.11,9.85l-2.25-2.26H17.6c-0.54,0-1.08,0.22-1.45,0.61l-3,3c-0.28,0.28-0.65,0.42-1.02,0.42 c-0.36,0-0.74-0.15-1.02-0.42L8.09,8.17c-0.38-0.38-0.9-0.6-1.45-0.6H5.17l-2.29,2.3c-1.17,1.17-1.17,3.07,0,4.24l2.29,2.3h1.48 c0.54,0,1.06-0.22,1.45-0.6l3.02-3.02c0.28-0.28,0.65-0.42,1.02-0.42c0.37,0,0.74,0.14,1.02,0.42l3.01,3.01 c0.38,0.38,0.9,0.6,1.45,0.6h1.26l2.25-2.26C22.3,12.96,22.3,11.04,21.11,9.85z"/></g></g></svg>Chippewa1</a></li>
                            <li><a href="/chippewa2"> <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#B06AB3"><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><path d="M15.45,16.52l-3.01-3.01c-0.11-0.11-0.24-0.13-0.31-0.13s-0.2,0.02-0.31,0.13L8.8,16.53c-0.34,0.34-0.87,0.89-2.64,0.89 l3.71,3.7c1.17,1.17,3.07,1.17,4.24,0l3.72-3.71C16.92,17.41,16.16,17.23,15.45,16.52z"/><path d="M8.8,7.47l3.02,3.02c0.08,0.08,0.2,0.13,0.31,0.13s0.23-0.05,0.31-0.13l2.99-2.99c0.71-0.74,1.52-0.91,2.43-0.91 l-3.72-3.71c-1.17-1.17-3.07-1.17-4.24,0l-3.71,3.7C7.95,6.58,8.49,7.16,8.8,7.47z"/><path d="M21.11,9.85l-2.25-2.26H17.6c-0.54,0-1.08,0.22-1.45,0.61l-3,3c-0.28,0.28-0.65,0.42-1.02,0.42 c-0.36,0-0.74-0.15-1.02-0.42L8.09,8.17c-0.38-0.38-0.9-0.6-1.45-0.6H5.17l-2.29,2.3c-1.17,1.17-1.17,3.07,0,4.24l2.29,2.3h1.48 c0.54,0,1.06-0.22,1.45-0.6l3.02-3.02c0.28-0.28,0.65-0.42,1.02-0.42c0.37,0,0.74,0.14,1.02,0.42l3.01,3.01 c0.38,0.38,0.9,0.6,1.45,0.6h1.26l2.25-2.26C22.3,12.96,22.3,11.04,21.11,9.85z"/></g></g></svg>Chippewa2</a></li>
                            <li><a href="/choctaw"> <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#B06AB3"><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><path d="M15.45,16.52l-3.01-3.01c-0.11-0.11-0.24-0.13-0.31-0.13s-0.2,0.02-0.31,0.13L8.8,16.53c-0.34,0.34-0.87,0.89-2.64,0.89 l3.71,3.7c1.17,1.17,3.07,1.17,4.24,0l3.72-3.71C16.92,17.41,16.16,17.23,15.45,16.52z"/><path d="M8.8,7.47l3.02,3.02c0.08,0.08,0.2,0.13,0.31,0.13s0.23-0.05,0.31-0.13l2.99-2.99c0.71-0.74,1.52-0.91,2.43-0.91 l-3.72-3.71c-1.17-1.17-3.07-1.17-4.24,0l-3.71,3.7C7.95,6.58,8.49,7.16,8.8,7.47z"/><path d="M21.11,9.85l-2.25-2.26H17.6c-0.54,0-1.08,0.22-1.45,0.61l-3,3c-0.28,0.28-0.65,0.42-1.02,0.42 c-0.36,0-0.74-0.15-1.02-0.42L8.09,8.17c-0.38-0.38-0.9-0.6-1.45-0.6H5.17l-2.29,2.3c-1.17,1.17-1.17,3.07,0,4.24l2.29,2.3h1.48 c0.54,0,1.06-0.22,1.45-0.6l3.02-3.02c0.28-0.28,0.65-0.42,1.02-0.42c0.37,0,0.74,0.14,1.02,0.42l3.01,3.01 c0.38,0.38,0.9,0.6,1.45,0.6h1.26l2.25-2.26C22.3,12.96,22.3,11.04,21.11,9.85z"/></g></g></svg>Choctaw</a></li>
                            <li><a href="/maidu"> <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#B06AB3"><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><path d="M15.45,16.52l-3.01-3.01c-0.11-0.11-0.24-0.13-0.31-0.13s-0.2,0.02-0.31,0.13L8.8,16.53c-0.34,0.34-0.87,0.89-2.64,0.89 l3.71,3.7c1.17,1.17,3.07,1.17,4.24,0l3.72-3.71C16.92,17.41,16.16,17.23,15.45,16.52z"/><path d="M8.8,7.47l3.02,3.02c0.08,0.08,0.2,0.13,0.31,0.13s0.23-0.05,0.31-0.13l2.99-2.99c0.71-0.74,1.52-0.91,2.43-0.91 l-3.72-3.71c-1.17-1.17-3.07-1.17-4.24,0l-3.71,3.7C7.95,6.58,8.49,7.16,8.8,7.47z"/><path d="M21.11,9.85l-2.25-2.26H17.6c-0.54,0-1.08,0.22-1.45,0.61l-3,3c-0.28,0.28-0.65,0.42-1.02,0.42 c-0.36,0-0.74-0.15-1.02-0.42L8.09,8.17c-0.38-0.38-0.9-0.6-1.45-0.6H5.17l-2.29,2.3c-1.17,1.17-1.17,3.07,0,4.24l2.29,2.3h1.48 c0.54,0,1.06-0.22,1.45-0.6l3.02-3.02c0.28-0.28,0.65-0.42,1.02-0.42c0.37,0,0.74,0.14,1.02,0.42l3.01,3.01 c0.38,0.38,0.9,0.6,1.45,0.6h1.26l2.25-2.26C22.3,12.96,22.3,11.04,21.11,9.85z"/></g></g></svg>Maidu</a></li>
                            <li><a href="/mandan"> <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#B06AB3"><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><path d="M15.45,16.52l-3.01-3.01c-0.11-0.11-0.24-0.13-0.31-0.13s-0.2,0.02-0.31,0.13L8.8,16.53c-0.34,0.34-0.87,0.89-2.64,0.89 l3.71,3.7c1.17,1.17,3.07,1.17,4.24,0l3.72-3.71C16.92,17.41,16.16,17.23,15.45,16.52z"/><path d="M8.8,7.47l3.02,3.02c0.08,0.08,0.2,0.13,0.31,0.13s0.23-0.05,0.31-0.13l2.99-2.99c0.71-0.74,1.52-0.91,2.43-0.91 l-3.72-3.71c-1.17-1.17-3.07-1.17-4.24,0l-3.71,3.7C7.95,6.58,8.49,7.16,8.8,7.47z"/><path d="M21.11,9.85l-2.25-2.26H17.6c-0.54,0-1.08,0.22-1.45,0.61l-3,3c-0.28,0.28-0.65,0.42-1.02,0.42 c-0.36,0-0.74-0.15-1.02-0.42L8.09,8.17c-0.38-0.38-0.9-0.6-1.45-0.6H5.17l-2.29,2.3c-1.17,1.17-1.17,3.07,0,4.24l2.29,2.3h1.48 c0.54,0,1.06-0.22,1.45-0.6l3.02-3.02c0.28-0.28,0.65-0.42,1.02-0.42c0.37,0,0.74,0.14,1.02,0.42l3.01,3.01 c0.38,0.38,0.9,0.6,1.45,0.6h1.26l2.25-2.26C22.3,12.96,22.3,11.04,21.11,9.85z"/></g></g></svg>Mandan</a></li>
                            <li><a href="/menominee"> <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#B06AB3"><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><path d="M15.45,16.52l-3.01-3.01c-0.11-0.11-0.24-0.13-0.31-0.13s-0.2,0.02-0.31,0.13L8.8,16.53c-0.34,0.34-0.87,0.89-2.64,0.89 l3.71,3.7c1.17,1.17,3.07,1.17,4.24,0l3.72-3.71C16.92,17.41,16.16,17.23,15.45,16.52z"/><path d="M8.8,7.47l3.02,3.02c0.08,0.08,0.2,0.13,0.31,0.13s0.23-0.05,0.31-0.13l2.99-2.99c0.71-0.74,1.52-0.91,2.43-0.91 l-3.72-3.71c-1.17-1.17-3.07-1.17-4.24,0l-3.71,3.7C7.95,6.58,8.49,7.16,8.8,7.47z"/><path d="M21.11,9.85l-2.25-2.26H17.6c-0.54,0-1.08,0.22-1.45,0.61l-3,3c-0.28,0.28-0.65,0.42-1.02,0.42 c-0.36,0-0.74-0.15-1.02-0.42L8.09,8.17c-0.38-0.38-0.9-0.6-1.45-0.6H5.17l-2.29,2.3c-1.17,1.17-1.17,3.07,0,4.24l2.29,2.3h1.48 c0.54,0,1.06-0.22,1.45-0.6l3.02-3.02c0.28-0.28,0.65-0.42,1.02-0.42c0.37,0,0.74,0.14,1.02,0.42l3.01,3.01 c0.38,0.38,0.9,0.6,1.45,0.6h1.26l2.25-2.26C22.3,12.96,22.3,11.04,21.11,9.85z"/></g></g></svg>Menominee</a></li>
                            <li><a href="/nootka"> <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#B06AB3"><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><path d="M15.45,16.52l-3.01-3.01c-0.11-0.11-0.24-0.13-0.31-0.13s-0.2,0.02-0.31,0.13L8.8,16.53c-0.34,0.34-0.87,0.89-2.64,0.89 l3.71,3.7c1.17,1.17,3.07,1.17,4.24,0l3.72-3.71C16.92,17.41,16.16,17.23,15.45,16.52z"/><path d="M8.8,7.47l3.02,3.02c0.08,0.08,0.2,0.13,0.31,0.13s0.23-0.05,0.31-0.13l2.99-2.99c0.71-0.74,1.52-0.91,2.43-0.91 l-3.72-3.71c-1.17-1.17-3.07-1.17-4.24,0l-3.71,3.7C7.95,6.58,8.49,7.16,8.8,7.47z"/><path d="M21.11,9.85l-2.25-2.26H17.6c-0.54,0-1.08,0.22-1.45,0.61l-3,3c-0.28,0.28-0.65,0.42-1.02,0.42 c-0.36,0-0.74-0.15-1.02-0.42L8.09,8.17c-0.38-0.38-0.9-0.6-1.45-0.6H5.17l-2.29,2.3c-1.17,1.17-1.17,3.07,0,4.24l2.29,2.3h1.48 c0.54,0,1.06-0.22,1.45-0.6l3.02-3.02c0.28-0.28,0.65-0.42,1.02-0.42c0.37,0,0.74,0.14,1.02,0.42l3.01,3.01 c0.38,0.38,0.9,0.6,1.45,0.6h1.26l2.25-2.26C22.3,12.96,22.3,11.04,21.11,9.85z"/></g></g></svg>Nootka</a></li>
                            <li><a href="/northernute"> <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#B06AB3"><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><path d="M15.45,16.52l-3.01-3.01c-0.11-0.11-0.24-0.13-0.31-0.13s-0.2,0.02-0.31,0.13L8.8,16.53c-0.34,0.34-0.87,0.89-2.64,0.89 l3.71,3.7c1.17,1.17,3.07,1.17,4.24,0l3.72-3.71C16.92,17.41,16.16,17.23,15.45,16.52z"/><path d="M8.8,7.47l3.02,3.02c0.08,0.08,0.2,0.13,0.31,0.13s0.23-0.05,0.31-0.13l2.99-2.99c0.71-0.74,1.52-0.91,2.43-0.91 l-3.72-3.71c-1.17-1.17-3.07-1.17-4.24,0l-3.71,3.7C7.95,6.58,8.49,7.16,8.8,7.47z"/><path d="M21.11,9.85l-2.25-2.26H17.6c-0.54,0-1.08,0.22-1.45,0.61l-3,3c-0.28,0.28-0.65,0.42-1.02,0.42 c-0.36,0-0.74-0.15-1.02-0.42L8.09,8.17c-0.38-0.38-0.9-0.6-1.45-0.6H5.17l-2.29,2.3c-1.17,1.17-1.17,3.07,0,4.24l2.29,2.3h1.48 c0.54,0,1.06-0.22,1.45-0.6l3.02-3.02c0.28-0.28,0.65-0.42,1.02-0.42c0.37,0,0.74,0.14,1.02,0.42l3.01,3.01 c0.38,0.38,0.9,0.6,1.45,0.6h1.26l2.25-2.26C22.3,12.96,22.3,11.04,21.11,9.85z"/></g></g></svg>Northern Ute</a></li>
                            <li><a href="/papago"> <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#B06AB3"><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><path d="M15.45,16.52l-3.01-3.01c-0.11-0.11-0.24-0.13-0.31-0.13s-0.2,0.02-0.31,0.13L8.8,16.53c-0.34,0.34-0.87,0.89-2.64,0.89 l3.71,3.7c1.17,1.17,3.07,1.17,4.24,0l3.72-3.71C16.92,17.41,16.16,17.23,15.45,16.52z"/><path d="M8.8,7.47l3.02,3.02c0.08,0.08,0.2,0.13,0.31,0.13s0.23-0.05,0.31-0.13l2.99-2.99c0.71-0.74,1.52-0.91,2.43-0.91 l-3.72-3.71c-1.17-1.17-3.07-1.17-4.24,0l-3.71,3.7C7.95,6.58,8.49,7.16,8.8,7.47z"/><path d="M21.11,9.85l-2.25-2.26H17.6c-0.54,0-1.08,0.22-1.45,0.61l-3,3c-0.28,0.28-0.65,0.42-1.02,0.42 c-0.36,0-0.74-0.15-1.02-0.42L8.09,8.17c-0.38-0.38-0.9-0.6-1.45-0.6H5.17l-2.29,2.3c-1.17,1.17-1.17,3.07,0,4.24l2.29,2.3h1.48 c0.54,0,1.06-0.22,1.45-0.6l3.02-3.02c0.28-0.28,0.65-0.42,1.02-0.42c0.37,0,0.74,0.14,1.02,0.42l3.01,3.01 c0.38,0.38,0.9,0.6,1.45,0.6h1.26l2.25-2.26C22.3,12.96,22.3,11.04,21.11,9.85z"/></g></g></svg>Papago</a></li>
                            <li><a href="/pawnee"> <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#B06AB3"><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><path d="M15.45,16.52l-3.01-3.01c-0.11-0.11-0.24-0.13-0.31-0.13s-0.2,0.02-0.31,0.13L8.8,16.53c-0.34,0.34-0.87,0.89-2.64,0.89 l3.71,3.7c1.17,1.17,3.07,1.17,4.24,0l3.72-3.71C16.92,17.41,16.16,17.23,15.45,16.52z"/><path d="M8.8,7.47l3.02,3.02c0.08,0.08,0.2,0.13,0.31,0.13s0.23-0.05,0.31-0.13l2.99-2.99c0.71-0.74,1.52-0.91,2.43-0.91 l-3.72-3.71c-1.17-1.17-3.07-1.17-4.24,0l-3.71,3.7C7.95,6.58,8.49,7.16,8.8,7.47z"/><path d="M21.11,9.85l-2.25-2.26H17.6c-0.54,0-1.08,0.22-1.45,0.61l-3,3c-0.28,0.28-0.65,0.42-1.02,0.42 c-0.36,0-0.74-0.15-1.02-0.42L8.09,8.17c-0.38-0.38-0.9-0.6-1.45-0.6H5.17l-2.29,2.3c-1.17,1.17-1.17,3.07,0,4.24l2.29,2.3h1.48 c0.54,0,1.06-0.22,1.45-0.6l3.02-3.02c0.28-0.28,0.65-0.42,1.02-0.42c0.37,0,0.74,0.14,1.02,0.42l3.01,3.01 c0.38,0.38,0.9,0.6,1.45,0.6h1.26l2.25-2.26C22.3,12.96,22.3,11.04,21.11,9.85z"/></g></g></svg>Pawnee</a></li>
                            <li><a href="/seminole"> <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#B06AB3"><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><path d="M15.45,16.52l-3.01-3.01c-0.11-0.11-0.24-0.13-0.31-0.13s-0.2,0.02-0.31,0.13L8.8,16.53c-0.34,0.34-0.87,0.89-2.64,0.89 l3.71,3.7c1.17,1.17,3.07,1.17,4.24,0l3.72-3.71C16.92,17.41,16.16,17.23,15.45,16.52z"/><path d="M8.8,7.47l3.02,3.02c0.08,0.08,0.2,0.13,0.31,0.13s0.23-0.05,0.31-0.13l2.99-2.99c0.71-0.74,1.52-0.91,2.43-0.91 l-3.72-3.71c-1.17-1.17-3.07-1.17-4.24,0l-3.71,3.7C7.95,6.58,8.49,7.16,8.8,7.47z"/><path d="M21.11,9.85l-2.25-2.26H17.6c-0.54,0-1.08,0.22-1.45,0.61l-3,3c-0.28,0.28-0.65,0.42-1.02,0.42 c-0.36,0-0.74-0.15-1.02-0.42L8.09,8.17c-0.38-0.38-0.9-0.6-1.45-0.6H5.17l-2.29,2.3c-1.17,1.17-1.17,3.07,0,4.24l2.29,2.3h1.48 c0.54,0,1.06-0.22,1.45-0.6l3.02-3.02c0.28-0.28,0.65-0.42,1.02-0.42c0.37,0,0.74,0.14,1.02,0.42l3.01,3.01 c0.38,0.38,0.9,0.6,1.45,0.6h1.26l2.25-2.26C22.3,12.96,22.3,11.04,21.11,9.85z"/></g></g></svg>Seminole</a></li>
                            <li><a href="/sioux"> <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#B06AB3"><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><path d="M15.45,16.52l-3.01-3.01c-0.11-0.11-0.24-0.13-0.31-0.13s-0.2,0.02-0.31,0.13L8.8,16.53c-0.34,0.34-0.87,0.89-2.64,0.89 l3.71,3.7c1.17,1.17,3.07,1.17,4.24,0l3.72-3.71C16.92,17.41,16.16,17.23,15.45,16.52z"/><path d="M8.8,7.47l3.02,3.02c0.08,0.08,0.2,0.13,0.31,0.13s0.23-0.05,0.31-0.13l2.99-2.99c0.71-0.74,1.52-0.91,2.43-0.91 l-3.72-3.71c-1.17-1.17-3.07-1.17-4.24,0l-3.71,3.7C7.95,6.58,8.49,7.16,8.8,7.47z"/><path d="M21.11,9.85l-2.25-2.26H17.6c-0.54,0-1.08,0.22-1.45,0.61l-3,3c-0.28,0.28-0.65,0.42-1.02,0.42 c-0.36,0-0.74-0.15-1.02-0.42L8.09,8.17c-0.38-0.38-0.9-0.6-1.45-0.6H5.17l-2.29,2.3c-1.17,1.17-1.17,3.07,0,4.24l2.29,2.3h1.48 c0.54,0,1.06-0.22,1.45-0.6l3.02-3.02c0.28-0.28,0.65-0.42,1.02-0.42c0.37,0,0.74,0.14,1.02,0.42l3.01,3.01 c0.38,0.38,0.9,0.6,1.45,0.6h1.26l2.25-2.26C22.3,12.96,22.3,11.04,21.11,9.85z"/></g></g></svg>Sioux</a></li>
                            <li><a href="/yuman"> <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#B06AB3"><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><path d="M15.45,16.52l-3.01-3.01c-0.11-0.11-0.24-0.13-0.31-0.13s-0.2,0.02-0.31,0.13L8.8,16.53c-0.34,0.34-0.87,0.89-2.64,0.89 l3.71,3.7c1.17,1.17,3.07,1.17,4.24,0l3.72-3.71C16.92,17.41,16.16,17.23,15.45,16.52z"/><path d="M8.8,7.47l3.02,3.02c0.08,0.08,0.2,0.13,0.31,0.13s0.23-0.05,0.31-0.13l2.99-2.99c0.71-0.74,1.52-0.91,2.43-0.91 l-3.72-3.71c-1.17-1.17-3.07-1.17-4.24,0l-3.71,3.7C7.95,6.58,8.49,7.16,8.8,7.47z"/><path d="M21.11,9.85l-2.25-2.26H17.6c-0.54,0-1.08,0.22-1.45,0.61l-3,3c-0.28,0.28-0.65,0.42-1.02,0.42 c-0.36,0-0.74-0.15-1.02-0.42L8.09,8.17c-0.38-0.38-0.9-0.6-1.45-0.6H5.17l-2.29,2.3c-1.17,1.17-1.17,3.07,0,4.24l2.29,2.3h1.48 c0.54,0,1.06-0.22,1.45-0.6l3.02-3.02c0.28-0.28,0.65-0.42,1.02-0.42c0.37,0,0.74,0.14,1.02,0.42l3.01,3.01 c0.38,0.38,0.9,0.6,1.45,0.6h1.26l2.25-2.26C22.3,12.96,22.3,11.04,21.11,9.85z"/></g></g></svg>Yuman</a></li>
                            </ul>
                        </details>
                    </li>
                    {/*<li><a    className="text-base" href="/products"><ion-icon name="grid"></ion-icon>Products</a></li>
                    <li><a    className="text-base" href="/disclaimer"><ion-icon name="information-circle"></ion-icon>Disclaimers</a></li>*/}
                </ul>
                </div>
            </div>
            <div className="navbar-center">
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="10pt" height="20.000000pt" viewBox="0 0 740.000000 1280.000000"
 preserveAspectRatio="xMidYMid meet">
<metadata>
Created by potrace 1.15, written by Peter Selinger 2001-2017
</metadata>
<g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
fill="#4568DC" stroke="none">
<path d="M4095 12786 c-177 -56 -398 -251 -557 -491 -84 -126 -250 -453 -313
-615 -175 -448 -253 -861 -258 -1364 l-2 -218 -185 -147 c-102 -81 -304 -239
-450 -351 -786 -604 -1125 -913 -1447 -1315 -275 -344 -495 -740 -635 -1145
-16 -47 -34 -89 -39 -94 -5 -6 -19 0 -35 15 -24 22 -26 30 -22 86 4 66 23 107
23 50 0 -27 4 -32 24 -32 19 0 30 11 49 48 33 63 41 141 17 151 -29 11 -59
-18 -95 -91 -28 -55 -35 -82 -38 -144 -4 -80 2 -95 52 -124 14 -8 16 -15 7
-37 -56 -148 -136 -560 -173 -888 -17 -154 -17 -745 0 -910 56 -535 177 -1036
364 -1505 74 -186 263 -571 356 -727 276 -461 586 -819 943 -1088 64 -48 124
-92 134 -98 17 -9 17 -11 -2 -38 -12 -16 -30 -38 -42 -48 -12 -11 -21 -25 -21
-31 0 -7 -13 -42 -30 -78 -61 -136 -75 -205 -74 -392 0 -142 3 -184 22 -252
105 -400 377 -695 770 -837 284 -102 693 -100 1015 5 197 64 353 157 494 292
219 211 330 460 366 822 l12 117 85 25 c390 112 710 275 1192 608 293 203 523
436 864 875 302 391 541 843 674 1277 39 127 31 122 127 77 73 -35 98 -73 98
-149 -1 -33 -7 -71 -15 -85 -14 -25 -15 -24 -20 26 -6 58 -20 79 -56 88 -67
17 -145 -109 -114 -183 16 -39 63 -64 105 -57 67 13 135 127 135 225 -1 49
-30 111 -69 143 -17 15 -57 35 -87 45 -64 20 -62 -4 -21 198 41 197 52 316 51
550 0 157 -5 251 -17 330 -53 335 -138 598 -284 875 -522 991 -1391 1642
-2393 1791 -169 25 -508 30 -675 10 -113 -13 -330 -54 -400 -75 -39 -12 -47
-12 -52 -1 -2 8 -3 18 -1 23 1 6 68 69 148 142 257 235 549 536 649 668 340
450 508 884 576 1487 64 579 58 947 -25 1401 -86 466 -256 870 -459 1087 -82
86 -144 105 -246 73z m168 -1112 c6 -6 -67 -156 -143 -292 -94 -169 -232 -362
-391 -548 -100 -117 -387 -414 -400 -414 -16 0 82 295 153 460 167 392 382
672 584 761 67 30 181 49 197 33z m113 -28 c308 -143 280 -756 -66 -1446 -159
-316 -522 -751 -905 -1084 l-100 -87 -7 28 c-10 40 -46 327 -58 458 -13 143
-13 317 0 445 l11 102 139 151 c465 509 729 930 905 1445 6 17 23 15 81 -12z
m-536 -720 c0 -2 -8 -10 -17 -17 -16 -13 -17 -12 -4 4 13 16 21 21 21 13z m4
-78 c-21 -29 -47 -65 -58 -80 -11 -15 -21 -26 -24 -24 -4 5 107 156 115 156 3
0 -12 -24 -33 -52z m10 -30 c-71 -102 -94 -133 -94 -124 0 9 140 206 147 206
2 0 -21 -37 -53 -82z m-72 34 c-131 -165 -493 -545 -476 -498 4 11 75 87 159
170 83 83 193 199 244 259 92 106 131 143 73 69z m-78 -138 c-49 -62 -66 -80
-62 -66 4 17 82 111 87 106 2 -2 -9 -20 -25 -40z m-4 -57 c-84 -104 -193 -227
-198 -222 -7 6 4 20 128 163 52 60 97 108 99 106 2 -2 -11 -23 -29 -47z m-228
-204 c-17 -30 -102 -108 -102 -93 0 8 23 38 52 67 52 52 69 61 50 26z m-87
-156 c-101 -106 -115 -118 -115 -96 0 18 204 222 208 209 2 -6 -40 -56 -93
-113z m-415 -316 c0 -18 -52 -71 -70 -71 -23 0 -13 28 19 57 42 38 51 40 51
14z m-135 -37 c-1 -18 -15 -37 -48 -62 -240 -192 -823 -666 -940 -767 -82 -69
-144 -117 -138 -106 6 11 16 23 21 26 16 10 12 43 -6 47 -18 3 128 124 455
377 193 150 656 509 656 510 1 1 0 -11 0 -25z m124 -59 c-15 -15 -21 -17 -31
-7 -10 10 -7 17 12 36 22 20 26 21 31 7 4 -9 -1 -24 -12 -36z m-99 -10 c-17
-20 -88 -57 -101 -53 -6 2 13 23 42 46 35 28 56 39 62 33 7 -7 6 -16 -3 -26z
m120 -29 c0 -18 -41 -61 -167 -178 -388 -356 -372 -342 -388 -328 -24 20 -18
32 43 82 31 26 158 137 282 247 124 110 226 200 228 200 1 1 2 -10 2 -23z
m-124 -59 c-279 -251 -462 -404 -473 -395 -7 6 -13 16 -13 24 0 7 69 70 153
140 83 71 186 157 227 192 68 58 138 102 165 102 6 0 -21 -28 -59 -63z m-124
-18 c7 -5 -63 -69 -169 -152 -21 -17 -22 -17 -16 -1 5 14 2 16 -22 12 l-28 -6
29 23 c17 13 66 52 109 88 44 35 82 59 84 53 2 -6 8 -14 13 -17z m258 -32 c0
-61 20 -237 70 -602 16 -121 30 -234 32 -250 3 -29 -8 -40 -152 -160 -793
-659 -1207 -1086 -1526 -1570 -288 -439 -447 -869 -494 -1344 -45 -457 58
-986 279 -1431 127 -255 248 -425 446 -629 413 -425 947 -710 1470 -786 184
-27 638 -14 747 21 10 3 18 4 18 1 0 -2 14 -104 30 -228 17 -123 35 -258 41
-299 5 -41 14 -106 19 -145 5 -38 10 -77 10 -85 0 -18 -138 -50 -304 -71 -135
-17 -504 -6 -636 20 -386 74 -711 220 -1020 459 -113 88 -335 311 -444 447
-463 578 -804 1386 -921 2180 -43 292 -58 722 -35 980 60 672 247 1227 583
1730 273 408 581 733 1382 1460 121 110 257 235 303 278 46 42 87 75 93 72 5
-4 9 -25 9 -48z m-540 -190 c0 -2 -15 -16 -32 -33 l-33 -29 29 33 c28 30 36
37 36 29z m-94 -57 c-25 -27 -46 -58 -46 -68 0 -11 -33 -47 -82 -88 -102 -86
-108 -90 -101 -64 8 31 -11 24 -69 -23 -59 -48 -101 -104 -94 -124 3 -7 -6
-21 -20 -32 -14 -11 -84 -73 -155 -138 -72 -65 -134 -119 -139 -121 -5 -2 -15
10 -24 25 l-14 29 126 111 c198 173 648 543 660 543 3 0 -16 -22 -42 -50z m-6
-116 c16 -18 24 -9 -171 -182 -135 -119 -137 -121 -155 -101 -13 14 -15 24 -9
34 13 19 305 265 315 265 4 0 13 -7 20 -16z m50 -69 c0 -6 -105 -109 -232
-227 -357 -331 -578 -564 -784 -824 -58 -73 -109 -134 -113 -134 -3 0 -19 10
-35 23 l-28 23 63 79 c162 204 362 419 588 631 165 154 176 163 193 147 23
-21 33 -11 18 17 -6 12 -10 23 -8 25 2 1 72 64 157 139 120 106 157 133 168
125 7 -6 13 -17 13 -24z m-670 -534 c-246 -245 -397 -412 -511 -561 l-27 -35
-28 21 c-15 11 -29 24 -31 30 -3 8 76 107 175 220 l44 49 19 -23 c10 -13 23
-22 28 -20 11 4 291 296 291 303 0 3 -7 16 -15 28 -14 22 -12 26 43 78 31 31
104 98 161 149 l104 93 17 -23 c17 -23 16 -24 -270 -309z m-120 226 c0 -2 -11
-19 -25 -37 -18 -24 -24 -42 -20 -60 5 -21 -7 -38 -80 -110 -47 -46 -85 -82
-85 -78 0 4 10 23 21 43 26 43 15 64 -22 45 -13 -7 10 19 51 56 41 38 93 86
115 106 39 37 45 41 45 35z m10 -192 c0 -6 -58 -68 -129 -139 l-128 -128 -24
33 -23 33 134 128 135 128 17 -22 c10 -13 18 -28 18 -33z m-101 -165 c-114
-120 -139 -143 -139 -122 0 12 250 265 257 259 3 -3 -50 -65 -118 -137z m-220
58 c-24 -33 -30 -51 -27 -78 4 -32 -3 -43 -90 -140 -303 -339 -512 -657 -683
-1043 -51 -116 -61 -132 -91 -144 -40 -17 -85 -58 -105 -96 -8 -16 -17 -27
-19 -24 -10 9 99 263 187 437 188 370 409 676 718 993 74 75 136 137 138 137
2 0 -11 -19 -28 -42z m41 -188 c-1 -3 -29 -36 -64 -75 -54 -59 -63 -65 -59
-42 4 19 1 27 -9 27 -46 0 -132 -120 -133 -184 0 -35 -13 -59 -80 -154 -69
-99 -80 -110 -83 -88 -4 35 -26 33 -56 -7 -52 -68 -82 -166 -56 -182 6 -4 -17
-57 -54 -128 -74 -139 -131 -267 -186 -412 l-38 -100 -7 49 c-5 32 -16 57 -31
71 l-23 21 58 131 c169 381 432 776 710 1066 l66 68 22 -28 c13 -15 23 -30 23
-33z m-186 -344 c18 -14 18 -17 2 -40 -23 -36 -73 -61 -92 -45 -20 17 -17 38
12 81 28 39 32 39 78 4z m-92 -140 c12 -7 5 -25 -42 -100 -31 -50 -65 -106
-76 -123 -10 -18 -22 -33 -27 -33 -17 0 -67 34 -67 46 0 12 84 149 145 235
l40 56 6 -36 c4 -19 13 -40 21 -45z m134 -28 c-35 -51 -89 -137 -120 -190 -31
-54 -60 -98 -63 -98 -9 0 -73 41 -73 47 0 6 103 173 142 231 31 45 117 102
155 102 19 0 15 -10 -41 -92z m3358 -43 c497 -70 972 -266 1376 -567 216 -161
467 -404 633 -613 164 -205 358 -515 457 -730 244 -526 302 -1114 168 -1695
l-22 -95 -7 58 c-3 31 -17 78 -31 104 -27 53 -95 111 -140 120 -25 5 -28 9
-23 37 17 102 25 220 25 366 0 420 -99 784 -318 1170 -63 112 -69 126 -54 138
23 17 25 16 48 -31 26 -54 88 -98 147 -104 39 -5 49 -2 71 20 36 36 34 82 -4
134 -31 43 -99 83 -139 83 -23 0 -88 -30 -134 -62 -16 -11 -29 -19 -30 -17
-35 54 -57 90 -57 94 0 3 8 6 18 6 9 1 6 4 -9 8 -19 4 -49 37 -102 107 -268
361 -621 673 -979 864 -69 36 -95 55 -91 66 7 18 6 18 56 -8 52 -27 147 -30
193 -6 43 23 63 72 43 107 -18 33 -93 71 -140 71 -76 0 -125 -33 -166 -113
-14 -28 -29 -46 -36 -43 -147 64 -206 88 -275 111 -46 16 -81 33 -79 39 2 6
15 10 28 8 139 -19 192 -10 233 39 51 60 21 120 -69 139 -46 10 -63 9 -105 -5
-56 -19 -81 -45 -106 -113 -10 -26 -20 -51 -24 -55 -4 -5 -46 1 -93 13 -215
51 -370 70 -592 70 -244 0 -379 -16 -598 -72 -65 -16 -121 -27 -124 -24 -6 5
-26 134 -38 242 l-7 61 144 31 c177 40 253 51 393 62 147 11 425 4 559 -15z
m-3679 -195 l33 -20 -30 -56 c-36 -66 -54 -76 -80 -45 -23 30 -23 52 2 101 23
46 30 48 75 20z m2383 -112 c7 -51 17 -122 22 -158 5 -36 14 -103 20 -150 6
-47 16 -116 21 -155 26 -192 79 -600 79 -610 0 -7 -30 -22 -67 -35 -545 -181
-959 -693 -1054 -1301 -15 -102 -6 -379 16 -479 59 -266 173 -486 339 -659
144 -148 281 -230 466 -276 30 -8 74 -14 97 -15 l43 0 -198 198 c-162 161
-205 211 -239 272 -163 293 -161 641 5 935 110 196 271 350 454 435 71 33 238
83 247 74 6 -5 340 -2486 336 -2492 -7 -8 -180 -34 -313 -47 -427 -41 -701 6
-1057 180 -401 197 -687 472 -879 845 -90 176 -137 311 -171 490 -101 530 59
1144 436 1672 176 246 466 558 799 858 280 251 574 510 580 510 3 0 12 -42 18
-92z m-2334 51 c-9 -11 -19 -17 -21 -15 -8 7 21 45 30 39 4 -3 1 -14 -9 -24z
m42 -97 c-245 -489 -376 -1074 -376 -1687 0 -450 71 -924 199 -1339 36 -115
34 -103 19 -103 -7 0 -39 -8 -72 -17 -32 -9 -59 -16 -60 -14 -5 6 -59 206 -82
298 -46 194 -93 465 -109 637 l-7 73 47 -7 48 -6 -7 39 c-4 21 -9 189 -12 373
-3 184 -7 338 -9 342 -3 4 -19 11 -36 14 l-31 7 7 77 c6 74 26 219 43 311 8
44 8 45 44 42 67 -6 78 8 78 97 0 63 -5 87 -25 127 -30 58 -30 58 31 241 50
150 104 290 162 414 35 76 46 91 97 127 32 23 67 42 77 42 16 0 12 -12 -26
-88z m-176 -9 c0 -2 9 -3 19 -3 17 0 14 -12 -29 -107 -61 -139 -130 -323 -165
-443 -15 -52 -31 -99 -35 -103 -4 -5 -17 -6 -29 -2 -19 6 -21 14 -21 100 0 82
4 106 35 190 36 101 118 290 167 389 l30 60 14 -40 c8 -21 14 -40 14 -41z
m3830 29 c133 -25 268 -57 276 -65 6 -6 -69 -241 -85 -266 -7 -11 -27 -9 -115
10 l-106 22 28 18 c87 52 98 153 20 178 -81 26 -169 -33 -189 -127 -10 -48
-13 -53 -32 -48 -12 3 -83 6 -158 6 l-137 0 34 37 c39 42 52 90 33 124 -16 32
-28 39 -67 39 -43 0 -82 -21 -121 -65 -27 -31 -31 -43 -31 -95 0 -57 -1 -60
-25 -60 -34 0 -200 -36 -308 -67 -49 -14 -90 -23 -92 -21 -4 4 -45 302 -42
305 9 9 263 66 347 78 58 8 116 16 130 19 14 2 138 3 275 2 205 -3 271 -7 365
-24z m423 -111 c120 -41 257 -102 257 -113 0 -11 -102 -203 -123 -232 -14 -19
-17 -18 -118 22 -57 22 -130 49 -161 58 -32 10 -58 20 -58 24 0 9 87 247 95
260 9 14 16 13 108 -19z m396 -177 c120 -65 292 -180 412 -275 197 -157 453
-427 587 -617 l42 -60 -37 -7 c-74 -12 -124 -46 -158 -107 l-31 -56 -24 36
c-35 51 -155 199 -204 251 -22 24 -36 42 -31 39 6 -2 28 -12 50 -22 22 -10 59
-21 82 -24 37 -4 46 -1 73 26 17 17 30 41 30 55 0 36 -39 88 -94 125 -40 27
-57 32 -106 32 -53 0 -61 -3 -103 -40 l-45 -40 -64 58 c-35 31 -86 75 -113 96
-28 22 -53 43 -58 46 -4 4 19 5 50 3 69 -5 102 10 119 53 18 43 0 73 -59 103
-65 32 -107 23 -165 -35 -25 -24 -46 -44 -48 -44 -2 0 -46 27 -98 60 -52 33
-129 76 -170 97 -42 21 -76 41 -76 45 0 16 135 248 144 248 5 0 48 -21 95 -46z
m-1011 -13 c11 -7 12 -32 2 -147 -7 -77 -16 -143 -20 -147 -13 -14 -48 11 -67
46 -23 45 -23 152 1 195 23 45 13 53 -14 11 -33 -54 -53 -136 -48 -195 l5 -54
-52 0 c-29 0 -86 -3 -127 -6 l-74 -7 -12 115 c-18 175 -17 186 16 191 53 8
377 7 390 -2z m272 -41 c52 -12 96 -22 98 -24 1 -2 -14 -51 -34 -110 -23 -67
-33 -111 -27 -117 5 -5 42 -18 82 -29 39 -11 113 -36 162 -56 50 -20 92 -34
95 -32 2 2 27 47 55 99 28 52 53 96 54 98 6 5 189 -95 277 -151 l78 -50 -68
-97 c-37 -53 -74 -107 -83 -119 l-16 -23 -80 50 c-254 157 -502 247 -816 296
-36 6 -37 8 -33 38 3 18 10 79 16 137 6 58 13 110 15 116 4 14 95 3 225 -26z
m-720 17 c0 -7 7 -67 15 -132 8 -66 15 -127 15 -136 0 -24 -77 -35 -100 -14
-28 25 -43 96 -39 184 4 84 -7 94 -22 19 -16 -82 -10 -125 25 -210 6 -14 -1
-19 -36 -28 -23 -6 -88 -25 -142 -41 -112 -34 -110 -35 -120 45 -3 28 -12 90
-18 138 -6 48 -9 90 -6 93 8 9 176 56 268 75 104 23 160 25 160 7z m1045 -116
c60 -23 113 -45 119 -51 5 -5 4 -20 -4 -39 -21 -51 -31 -54 -92 -27 -30 13
-95 37 -144 52 -49 15 -92 30 -97 33 -4 4 0 28 8 53 l16 46 42 -13 c23 -7 91
-31 152 -54z m-4615 45 c0 -21 -26 -67 -41 -71 -19 -6 -62 -55 -55 -62 2 -3
13 0 24 6 11 6 22 8 25 5 4 -4 -19 -89 -27 -101 -1 -1 -21 3 -44 9 -49 12 -49
14 -1 109 28 57 79 109 105 109 8 0 14 -2 14 -4z m40 -41 c26 -68 23 -231 -3
-222 -8 2 -13 33 -14 87 -2 45 -7 88 -13 95 -12 15 -3 65 11 65 5 0 14 -11 19
-25z m4401 -91 c74 -22 240 -86 236 -91 -3 -2 -29 6 -58 18 -30 12 -99 37
-154 55 -103 34 -120 46 -24 18z m-4475 -102 c-13 -12 -17 8 -10 40 l9 33 3
-33 c2 -19 1 -36 -2 -40z m3963 -69 c31 -61 45 -103 48 -145 3 -32 10 -58 14
-58 5 0 9 3 9 8 1 30 30 266 34 269 6 6 190 -26 276 -48 141 -37 270 -88 412
-161 78 -39 208 -116 227 -134 2 -1 -19 -34 -46 -71 -59 -81 -77 -113 -64
-113 5 0 35 36 66 80 31 44 59 81 63 84 9 5 89 -54 200 -148 l82 -69 -90 -92
c-78 -80 -108 -125 -83 -125 4 0 51 43 104 96 l96 95 73 -78 c82 -88 193 -224
265 -326 58 -84 195 -316 195 -333 0 -7 -24 -20 -52 -30 -29 -9 -65 -23 -80
-31 l-28 -13 26 -58 c63 -139 107 -287 131 -437 l8 -50 88 -3 87 -3 0 -123 c0
-68 -3 -151 -6 -185 -6 -51 -10 -61 -23 -56 -25 11 -166 18 -195 11 -17 -4
-26 -13 -26 -27 0 -51 74 -146 152 -195 38 -24 40 -27 33 -62 -22 -122 -202
-590 -229 -597 -96 -26 -173 -33 -228 -22 -21 4 -38 4 -38 -1 0 -4 43 -33 95
-63 52 -30 95 -58 95 -63 0 -10 -17 -39 -97 -168 l-55 -88 -101 0 c-70 1 -110
5 -127 15 -31 18 -40 18 -40 2 0 -7 38 -41 85 -76 47 -34 85 -66 85 -71 0 -12
-211 -282 -309 -394 -47 -55 -121 -131 -165 -169 -80 -70 -273 -217 -286 -217
-4 0 -29 33 -56 73 -32 48 -49 66 -52 55 -2 -9 15 -44 37 -77 23 -33 41 -64
41 -68 0 -9 -321 -226 -325 -221 -1 2 -24 41 -50 88 -26 47 -53 88 -59 92 -6
4 -8 9 -6 11 3 2 64 43 135 92 440 300 547 401 834 788 231 312 366 556 482
870 90 247 131 462 131 692 0 247 -49 446 -163 665 -186 354 -465 669 -774
873 -154 101 -341 188 -510 236 -278 78 -573 76 -845 -8 -36 -11 -97 -23 -137
-27 -70 -6 -72 -6 -77 17 -8 36 -36 252 -33 255 5 5 234 72 267 79 34 6 38 4
89 -57 54 -63 101 -147 101 -180 0 -10 5 -18 11 -18 7 0 9 14 5 38 -8 48 -26
212 -26 234 0 19 28 23 170 26 l86 2 43 -87z m-4043 51 c28 -7 29 -14 9 -82
-17 -56 -17 -57 -45 -42 -36 19 -39 149 -3 136 8 -3 26 -9 39 -12z m3724 -14
c0 -36 -3 -36 -41 -9 -34 24 -30 39 12 39 26 0 29 -3 29 -30z m-3660 -10 c0
-16 -30 -84 -35 -79 -7 8 17 89 26 89 5 0 9 -5 9 -10z m82 -25 c2 -3 -6 -47
-19 -98 -34 -136 -70 -318 -84 -432 -6 -55 -15 -103 -18 -107 -3 -4 -6 35 -6
86 0 79 -3 97 -19 115 l-20 22 -20 -21 c-18 -17 -21 -33 -21 -104 0 -90 13
-118 64 -137 19 -8 22 -14 18 -38 -3 -16 -9 -92 -13 -170 -7 -146 -21 -193
-21 -72 0 67 -24 141 -48 148 -25 7 -39 -18 -43 -79 -5 -92 14 -138 66 -163
l42 -19 -1 -176 c-1 -150 -3 -166 -11 -110 -17 118 -62 188 -95 144 -19 -25
-12 -125 12 -176 17 -34 30 -43 88 -62 13 -4 17 -16 17 -49 0 -25 7 -109 16
-187 8 -78 12 -146 7 -150 -4 -5 -33 -11 -65 -15 -49 -6 -57 -5 -62 11 -16 52
-36 397 -36 614 0 335 26 611 87 910 24 119 26 126 63 157 24 20 48 57 66 98
26 58 41 75 56 60z m-216 -136 c8 -17 22 -33 30 -36 9 -3 13 -12 9 -22 -12
-30 -46 -205 -65 -336 -34 -225 -50 -451 -50 -695 0 -211 20 -540 36 -605 4
-15 2 -25 -5 -25 -6 0 -11 8 -11 18 0 36 -31 135 -51 164 -28 39 -60 35 -75
-11 -9 -29 -12 -17 -17 95 -26 512 15 969 131 1439 l37 150 7 -53 c5 -29 15
-66 24 -83z m274 -85 c20 -46 20 -47 1 -158 -11 -61 -25 -158 -31 -216 -6 -58
-12 -106 -13 -106 -1 -1 -27 8 -57 20 -55 22 -55 23 -52 62 9 122 63 410 105
559 l11 40 7 -77 c5 -43 17 -99 29 -124z m56 117 c-13 -66 -26 -55 -26 21 0
59 1 60 18 45 16 -15 17 -23 8 -66z m5206 -89 c89 -76 107 -96 99 -110 -5 -9
-50 -57 -100 -106 l-90 -89 -73 65 c-40 35 -103 87 -140 115 l-67 50 46 64
c26 35 64 88 85 117 l39 53 46 -33 c25 -18 95 -75 155 -126z m-5153 -9 c1 -32
-3 -43 -14 -43 -18 0 -47 43 -41 61 3 8 10 34 17 59 l12 45 13 -40 c7 -22 13
-59 13 -82z m3916 -58 c439 -77 833 -327 1174 -742 78 -96 209 -301 270 -425
105 -212 144 -385 144 -628 0 -189 -21 -327 -79 -525 -75 -254 -216 -558 -365
-786 -172 -262 -420 -574 -545 -685 -179 -158 -563 -420 -746 -508 -178 -85
-164 -83 -172 -28 -11 76 -66 481 -76 557 -5 39 -12 88 -15 110 l-7 40 74 24
c40 13 129 51 198 83 698 329 1084 1017 1022 1826 -23 300 -76 518 -182 740
-243 510 -703 834 -1267 892 -53 6 -98 12 -100 13 -6 7 143 40 237 53 115 16
309 11 435 -11z m1589 -42 c48 -34 59 -63 24 -63 -41 0 -148 67 -148 93 0 19
84 -2 124 -30z m-125 -111 c87 -95 238 -284 253 -317 14 -31 -27 -130 -73
-180 -70 -75 -40 -75 76 0 l85 54 50 -85 c28 -47 68 -121 90 -164 l39 -78
-130 -44 c-130 -45 -131 -45 -143 -24 -7 12 -34 59 -61 106 -103 183 -228 355
-383 525 l-91 100 97 98 c53 53 100 97 104 97 3 0 43 -40 87 -88z m541 -202
c18 -28 31 -52 29 -54 -17 -15 -179 -116 -187 -116 -31 0 -28 86 5 137 24 38
83 82 109 83 7 0 26 -23 44 -50z m-6125 -99 c42 -16 50 -23 48 -43 -1 -12 -5
-89 -8 -169 l-7 -146 -56 17 -57 16 2 150 c2 149 7 194 21 194 4 0 30 -9 57
-19z m6228 -68 c166 -289 253 -520 309 -823 35 -193 36 -614 1 -757 -4 -16
-13 -23 -29 -23 -61 0 -140 -68 -173 -148 -12 -28 -13 -29 -57 -19 -33 8 -43
14 -40 26 30 101 66 388 66 524 0 80 2 87 20 87 17 0 20 -7 20 -43 0 -75 54
-153 117 -172 33 -9 73 34 73 79 0 48 -16 85 -52 123 -31 32 -42 36 -139 48
l-46 6 -7 72 c-4 40 -14 110 -22 156 -13 70 -13 85 -2 92 21 14 28 10 28 -13
1 -70 86 -168 145 -168 49 0 69 20 67 69 -2 48 -20 86 -54 119 -34 31 -75 41
-150 35 l-64 -5 -13 53 c-6 30 -29 99 -50 154 -33 87 -36 101 -22 107 26 11
31 9 31 -9 0 -53 98 -143 154 -143 17 0 42 7 55 16 20 14 22 23 18 57 -6 47
-31 83 -77 115 -39 26 -86 28 -159 6 -29 -8 -54 -14 -55 -12 -2 2 -24 45 -51
97 -26 52 -65 124 -87 159 -27 45 -36 68 -29 75 18 18 205 135 218 136 6 1 31
-34 56 -76z m-6146 -85 c-4 -66 -7 -144 -7 -172 0 -37 -3 -47 -11 -39 -15 15
-2 333 14 333 7 0 8 -40 4 -122z m-97 -222 l45 -13 6 -74 c11 -123 12 -264 3
-262 -5 1 -33 4 -61 8 -29 3 -55 9 -57 13 -2 4 -7 82 -11 175 -8 189 -13 180
75 153z m99 -191 c5 -88 7 -162 5 -164 -15 -16 -23 35 -29 179 -4 117 -3 162
4 155 6 -6 15 -83 20 -170z m3466 74 c212 -49 405 -153 559 -299 174 -166 279
-351 337 -597 21 -87 24 -122 24 -303 -1 -172 -4 -222 -23 -311 -85 -411 -283
-676 -635 -849 -67 -33 -126 -60 -133 -60 -6 0 -14 25 -18 58 -3 31 -11 89
-16 127 -16 118 -90 666 -100 740 -5 39 -23 171 -40 295 -17 124 -35 258 -40
298 -6 39 -14 105 -20 145 -10 74 -63 467 -80 592 -6 39 -13 97 -16 131 l-7
60 79 -8 c44 -4 102 -13 129 -19z m2584 -41 c22 -56 92 -271 89 -273 -5 -5
-222 -35 -249 -35 -22 0 -28 8 -48 73 -13 39 -34 96 -47 126 -14 30 -23 56
-21 58 7 6 232 81 247 82 9 1 21 -14 29 -31z m-285 -86 c8 -15 30 -73 49 -129
l36 -101 -27 -5 c-15 -3 -28 -5 -28 -4 -3 3 -86 221 -91 239 -4 14 6 21 39 27
4 0 14 -11 22 -27z m-74 -67 c0 -5 -5 -3 -10 5 -5 8 -10 20 -10 25 0 6 5 3 10
-5 5 -8 10 -19 10 -25z m30 -70 c0 -5 -5 -3 -10 5 -5 8 -10 20 -10 25 0 6 5 3
10 -5 5 -8 10 -19 10 -25z m-5767 -1 c10 -4 17 -26 22 -67 31 -293 83 -570
156 -823 16 -56 29 -109 29 -118 0 -15 -30 -27 -107 -42 -26 -5 -31 -2 -37 18
-26 90 -77 302 -96 400 -28 137 -21 181 36 235 19 17 34 35 34 39 0 4 -27 4
-60 1 -45 -5 -60 -4 -60 6 0 7 -5 51 -11 97 -6 47 -14 127 -18 179 l-7 93 52
-6 c29 -3 59 -9 67 -12z m6210 -171 c9 -49 19 -121 23 -160 l7 -73 -121 0
c-66 0 -127 3 -136 6 -9 3 -16 18 -16 33 0 14 -9 75 -21 134 -12 59 -19 110
-16 113 5 5 187 31 233 33 l31 1 16 -87z m-289 -30 c9 -42 19 -103 23 -135 6
-57 6 -58 -20 -58 -23 0 -26 5 -32 48 -4 26 -15 84 -26 130 -10 46 -19 85 -19
88 0 2 13 4 28 4 28 0 30 -3 46 -77z m-6291 -180 c21 -40 51 -57 88 -49 25 5
29 2 34 -19 3 -14 7 -36 10 -50 3 -15 1 -25 -6 -25 -8 0 -8 -4 -1 -13 6 -7 27
-91 46 -187 91 -448 189 -749 402 -1227 23 -53 1 -37 -25 17 -31 66 -93 122
-129 118 -22 -2 -28 -9 -30 -36 -7 -73 78 -183 141 -181 18 0 41 4 52 8 17 7
30 -11 108 -156 49 -91 86 -167 83 -170 -2 -3 -14 13 -25 34 -23 47 -90 110
-126 119 -63 16 -71 -52 -15 -130 52 -71 86 -83 182 -64 11 2 33 -24 73 -87
257 -408 593 -774 909 -990 l80 -55 -3 -52 c-2 -44 -8 -59 -34 -85 -17 -18
-35 -33 -40 -33 -30 0 -286 203 -433 342 -327 313 -604 705 -844 1193 -273
557 -436 1100 -515 1718 -16 124 -18 173 -6 122 4 -16 15 -44 24 -62z m255
-60 c4 -37 -20 -88 -47 -103 -33 -17 -50 -2 -58 53 -11 73 -12 71 27 78 62 12
75 7 78 -28z m6355 0 c10 -6 9 -167 -3 -281 -12 -118 -50 -331 -59 -336 -4 -3
-26 33 -49 79 -39 75 -91 137 -145 170 -14 9 -15 31 -10 199 l6 188 126 -7
c69 -4 129 -9 134 -12z m-6298 -160 c3 -27 20 -104 36 -172 34 -145 34 -144
10 -106 -49 80 -93 83 -99 6 -4 -52 22 -132 59 -188 31 -44 57 -58 93 -49 20
5 25 -4 66 -127 56 -166 57 -168 31 -135 -39 49 -91 45 -91 -8 0 -37 39 -111
73 -140 24 -20 38 -25 62 -21 l32 5 48 -111 c27 -62 66 -147 87 -191 22 -43
38 -80 36 -82 -5 -4 -136 -54 -143 -54 -16 0 -158 335 -229 538 -89 256 -186
629 -212 816 -6 46 -6 48 14 37 34 -18 70 -13 89 13 10 13 21 22 24 20 4 -2
10 -25 14 -51z m5914 -214 c21 -6 47 -18 58 -28 20 -14 21 -21 12 -65 -5 -27
-11 -52 -14 -55 -9 -8 -90 77 -114 120 l-22 39 22 0 c12 0 38 -5 58 -11z m158
-152 c13 -27 22 -51 19 -54 -7 -8 -73 16 -86 32 -7 8 -8 32 -4 66 l7 52 20
-24 c10 -13 30 -46 44 -72z m-6036 7 c31 -68 35 -84 20 -84 -11 0 -57 92 -67
134 -12 49 15 20 47 -50z m459 -189 c155 -415 359 -800 591 -1112 38 -51 69
-96 68 -101 -1 -13 -132 -103 -138 -95 -3 5 -40 56 -80 113 -145 202 -321 514
-430 759 -28 62 -51 117 -51 121 0 4 25 18 56 30 33 14 52 26 46 32 -5 5 -30
1 -62 -12 -29 -11 -54 -19 -55 -18 -11 14 -135 362 -135 380 0 4 8 8 18 9 9 0
40 8 67 18 28 9 51 17 52 17 1 -1 25 -64 53 -141z m6143 121 c50 -21 87 -70
87 -117 0 -21 -5 -39 -11 -39 -13 0 -171 40 -180 46 -4 2 -3 19 2 37 4 17 11
44 14 60 6 31 35 36 88 13z m-110 -28 c-13 -68 -20 -79 -50 -72 -16 4 -29 8
-31 9 -8 7 30 65 50 79 33 23 38 20 31 -16z m-483 3 l64 -18 37 -97 37 -98
-23 -67 c-35 -103 -98 -256 -155 -376 -45 -96 -54 -108 -64 -91 -57 93 -107
136 -160 136 -34 0 -35 -3 28 138 50 113 123 318 151 425 10 37 18 67 19 67 1
-1 30 -9 66 -19z m-5707 -161 c30 -85 62 -172 71 -192 l16 -37 -53 -19 c-100
-37 -92 -39 -117 25 -32 83 -110 315 -110 329 0 9 129 56 137 50 1 -1 26 -71
56 -156z m5974 89 c6 -6 15 -25 19 -42 13 -61 52 -135 83 -158 34 -25 37 -36
17 -64 -12 -17 -16 -16 -60 25 -26 24 -56 60 -66 80 -18 36 -60 156 -60 173 0
11 49 0 67 -14z m170 -39 c12 0 14 -7 8 -37 -11 -63 -20 -71 -39 -35 -21 42
-22 86 0 78 9 -3 23 -6 31 -6z m166 -45 c43 -11 67 -23 67 -32 0 -48 -66 -139
-122 -169 -30 -15 -115 -19 -136 -5 -12 7 -12 13 1 36 10 21 23 29 48 31 47 5
88 24 99 45 9 16 6 17 -44 12 -65 -7 -72 1 -56 66 13 55 -2 53 143 16z m-350
-227 c18 -15 47 -33 65 -40 17 -7 32 -15 32 -19 0 -3 -20 -53 -44 -110 -106
-254 -280 -562 -438 -779 -95 -131 -313 -406 -329 -416 -5 -3 -47 31 -94 76
l-83 82 66 85 67 85 7 -43 c10 -54 33 -79 74 -79 39 0 77 40 95 100 15 51 -1
113 -36 136 -32 21 -32 27 9 83 18 25 58 83 88 129 l55 82 7 -42 c13 -81 74
-113 120 -63 59 63 54 171 -9 222 l-33 27 53 100 c74 139 160 339 221 515 3 8
18 -9 40 -45 19 -32 49 -71 67 -86z m413 6 c-132 -395 -342 -791 -604 -1139
-273 -362 -580 -689 -804 -856 -144 -106 -481 -323 -638 -410 -186 -103 -455
-213 -656 -268 l-71 -19 -6 102 c-4 71 -2 105 6 113 7 7 72 33 145 58 155 53
333 132 462 205 166 93 530 335 654 435 48 37 73 52 80 45 7 -7 2 -16 -14 -27
-40 -28 -71 -65 -91 -108 -24 -52 -24 -90 1 -115 31 -31 65 -25 117 23 80 73
94 145 43 215 -16 23 -30 43 -30 44 0 11 271 280 277 275 3 -4 -5 -20 -20 -35
-62 -65 -87 -182 -47 -222 37 -37 85 -18 131 50 54 80 51 137 -11 203 l-38 42
85 105 c107 132 222 281 294 385 138 197 310 512 404 743 l38 92 55 0 c30 0
73 5 94 12 51 15 128 84 158 142 14 25 26 44 27 42 2 -1 -17 -61 -41 -132z
m-6161 -290 c119 -247 260 -486 406 -685 27 -37 49 -68 49 -71 0 -11 -131 -99
-139 -94 -13 8 -194 277 -189 281 1 1 28 15 58 30 30 15 57 29 58 30 2 2 -32
63 -76 137 -43 73 -89 152 -101 176 -12 23 -28 42 -36 42 -8 0 -37 -9 -65 -20
l-50 -20 -34 68 c-48 96 -136 300 -136 316 0 8 30 25 68 39 l67 25 19 -41 c11
-23 56 -119 101 -213z m5268 12 c18 -7 54 -36 80 -64 l48 -51 -22 -43 c-12
-24 -23 -44 -24 -46 -3 -3 -196 106 -208 117 -5 5 2 29 14 55 19 39 28 46 51
46 16 0 43 -6 61 -14z m-123 -11 c0 -4 -7 -19 -15 -36 -15 -29 -16 -29 -41
-12 l-26 17 34 17 c35 19 48 23 48 14z m98 -167 c45 -27 82 -54 82 -60 0 -27
-227 -368 -245 -368 -18 0 -190 132 -187 144 9 34 89 49 135 25 34 -18 68 -52
79 -79 6 -15 12 -20 15 -12 6 16 -32 89 -64 124 -14 14 -38 32 -54 38 -16 7
-29 15 -29 18 0 3 32 59 71 123 66 110 73 118 93 107 11 -6 58 -33 104 -60z
m-5232 -100 c14 -28 52 -95 85 -151 33 -55 58 -101 57 -102 -2 -1 -13 -7 -25
-14 -21 -10 -26 -4 -91 111 -113 201 -114 204 -84 211 25 5 30 1 58 -55z m-70
-20 c15 -29 53 -100 86 -157 33 -57 56 -107 51 -111 -4 -5 -38 -22 -74 -38
-60 -27 -67 -28 -76 -13 -27 42 -151 278 -157 297 -6 19 1 25 56 47 35 14 69
26 75 27 7 0 25 -24 39 -52z m5032 -275 c45 -35 82 -67 82 -71 0 -7 -105 -145
-168 -219 l-44 -53 -67 60 c-37 33 -72 60 -78 60 -5 0 -33 -30 -63 -67 -29
-37 -85 -99 -123 -139 -45 -49 -66 -77 -61 -86 4 -7 30 -41 56 -75 l48 -61
-52 -46 c-60 -53 -277 -210 -283 -205 -21 22 -123 184 -120 190 3 4 36 29 73
55 218 155 387 329 631 652 39 51 67 80 74 76 7 -4 49 -36 95 -71z m-4823 -85
c22 -33 48 -74 58 -89 l19 -29 -36 15 c-43 18 -41 18 -60 -1 -23 -22 -20 -53
10 -96 47 -67 115 -94 166 -67 18 10 24 7 47 -22 14 -19 61 -76 104 -126 61
-73 72 -91 52 -83 -54 21 -95 4 -95 -39 0 -20 66 -85 105 -102 50 -23 79 -24
110 -3 25 16 26 15 98 -52 104 -99 196 -171 340 -268 l126 -84 -42 -7 c-55 -9
-125 -42 -138 -66 -5 -10 -14 -19 -19 -19 -5 0 -62 40 -127 88 -332 249 -599
549 -876 984 l-37 58 68 34 c37 19 72 35 77 35 6 1 28 -27 50 -61z m602 -160
c242 -271 522 -476 834 -611 197 -86 371 -135 599 -168 189 -28 511 -23 685
11 61 11 126 23 146 26 l35 6 14 -101 c7 -56 11 -103 9 -105 -7 -7 -144 -34
-207 -42 l-57 -6 0 68 0 68 -55 -5 c-74 -6 -447 -5 -539 1 l-74 5 -17 -55
c-10 -32 -24 -56 -33 -58 -24 -5 -352 77 -375 94 -2 1 4 24 13 51 9 28 15 68
13 89 l-3 39 -70 -1 c-74 -1 -132 -22 -169 -63 l-19 -21 -96 50 c-118 61 -289
171 -397 257 -76 60 -82 67 -88 110 -9 71 -7 131 6 153 27 43 -6 30 -60 -23
-32 -31 -61 -57 -64 -57 -4 0 -52 50 -109 111 l-101 111 4 77 c3 49 12 88 24
109 l19 32 18 -22 c11 -13 62 -71 114 -130z m-251 -9 c28 -55 -11 -129 -68
-129 -15 0 -28 -4 -28 -10 0 -11 42 -13 89 -4 17 4 42 17 55 31 l25 24 75 -79
c42 -43 86 -92 98 -107 l22 -28 -64 -60 -65 -59 -58 63 c-78 85 -216 250 -228
271 -7 14 3 27 53 67 35 28 66 51 70 51 4 0 15 -14 24 -31z m4234 -43 c0 -2
-8 -10 -17 -17 -16 -13 -17 -12 -4 4 13 16 21 21 21 13z m58 -108 c-46 -59
-200 -218 -211 -218 -7 0 -20 12 -30 25 l-18 25 96 106 c53 59 104 117 113
130 l17 23 29 -29 29 -29 -25 -33z m-88 69 c0 -2 -12 -14 -27 -28 l-28 -24 24
28 c23 25 31 32 31 24z m252 -274 c-28 -32 -89 -96 -135 -141 l-84 -84 -72 92
c-70 91 -80 110 -62 110 6 0 61 56 122 125 l113 126 85 -85 84 -84 -51 -59z
m-312 204 c0 -1 -30 -32 -67 -67 l-68 -65 65 68 c60 62 70 72 70 64z m-3800
-153 c17 -43 11 -50 -16 -22 -15 14 -23 31 -20 37 12 18 26 12 36 -15z m-5
-54 l39 -40 -16 -34 c-11 -23 -31 -41 -63 -56 -25 -12 -45 -24 -43 -26 2 -1
24 2 48 7 50 11 90 43 90 71 0 10 2 18 5 18 3 0 40 -28 83 -62 104 -83 266
-188 385 -249 54 -28 97 -54 95 -57 -2 -4 -12 -24 -23 -44 l-19 -38 -99 0
-100 0 -91 56 c-114 71 -254 178 -374 284 -79 71 -91 85 -80 99 27 37 103 111
113 111 6 0 28 -18 50 -40z m3799 -147 c36 -49 65 -92 63 -98 -7 -20 -226
-179 -428 -310 -307 -199 -511 -303 -752 -384 l-115 -39 5 52 c6 58 -11 104
-49 130 -28 20 -19 28 52 51 30 10 102 38 160 62 58 24 112 47 120 50 8 3 -1
-12 -21 -33 -40 -43 -59 -74 -59 -98 0 -25 38 -56 68 -56 43 0 128 47 166 92
46 53 53 88 28 139 l-20 40 22 13 c11 7 72 45 133 84 l113 71 -20 -37 c-37
-69 -19 -122 42 -122 23 0 42 11 73 43 56 55 66 101 36 153 -12 21 -18 40 -13
43 53 35 210 151 257 190 33 27 63 50 67 50 4 1 36 -38 72 -86z m-504 -63 c33
-49 60 -92 60 -95 0 -7 -271 -185 -317 -209 l-32 -16 -26 45 c-14 25 -37 65
-51 90 -14 25 -24 50 -22 55 3 9 308 218 320 219 4 1 35 -39 68 -89z m-477
-53 c20 -36 37 -68 37 -72 0 -8 -245 -125 -323 -154 -43 -16 -59 -18 -62 -8
-2 6 -10 56 -18 111 l-13 98 30 11 c17 6 90 40 161 76 l130 66 11 -31 c6 -17
27 -61 47 -97z m100 -186 c26 -48 47 -93 47 -98 0 -20 -370 -182 -444 -195
-18 -3 -21 7 -33 92 -7 52 -12 101 -11 108 2 7 46 30 98 51 52 21 136 58 185
84 50 25 95 46 101 46 6 1 32 -39 57 -88z m-2048 47 c-4 -13 -19 -36 -34 -51
l-27 -28 -52 22 c-29 12 -52 25 -52 29 0 4 17 14 38 23 54 23 64 25 100 26 30
1 33 -1 27 -21z m-135 -67 c51 -23 41 -32 -48 -39 -69 -5 -71 -3 -34 41 19 22
30 21 82 -2z m191 -71 c72 -23 234 -64 292 -75 24 -4 37 -11 34 -18 -2 -7 -17
-49 -33 -94 -37 -106 -48 -108 -147 -15 -74 68 -176 129 -254 152 l-42 13 37
28 c20 15 40 28 43 28 4 1 35 -8 70 -19z m534 -50 c61 -5 118 -10 128 -10 11
0 17 -8 17 -21 0 -19 -4 -20 -82 -14 -94 7 -220 24 -226 30 -13 13 10 35 30
30 13 -3 73 -9 133 -15z m483 -17 c-4 -22 -64 -31 -210 -32 -91 -1 -98 0 -98
19 0 17 8 20 93 23 232 8 219 8 215 -10z m306 -35 c3 -24 8 -71 12 -104 6 -61
6 -61 -22 -68 -57 -15 -200 -36 -243 -36 l-44 0 7 53 c3 28 6 75 6 102 l0 51
123 21 c167 28 154 30 161 -19z m-1924 27 c0 -6 -76 -45 -87 -45 -10 1 69 50
80 50 4 0 7 -2 7 -5z m1159 -35 c57 -5 106 -12 109 -14 4 -5 -8 -67 -30 -155
l-11 -43 -76 7 c-77 7 -252 32 -256 37 -2 2 11 39 27 83 16 44 32 86 34 94 3
10 16 12 52 7 26 -4 94 -11 151 -16z m1487 -26 c-22 -33 -94 -84 -119 -84 -18
0 -18 2 5 33 30 40 121 103 126 87 2 -6 -3 -22 -12 -36z m-1039 -75 c-4 -56
-9 -104 -11 -107 -7 -6 -323 -8 -330 -2 -3 3 1 33 8 65 8 33 17 77 20 97 l7
37 102 4 c56 1 126 4 156 5 l54 2 -6 -101z m-757 -89 c0 -5 -2 -10 -4 -10 -3
0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m1103 -57 c4 -38 7 -87 7
-109 0 -47 26 -39 -210 -68 -161 -20 -474 -21 -645 -1 -143 17 -165 22 -165
36 0 5 -16 42 -35 82 -21 46 -32 81 -28 95 6 26 0 26 116 8 50 -8 122 -17 160
-21 51 -5 67 -10 67 -22 0 -12 -8 -14 -36 -10 -79 13 -194 -36 -194 -82 0 -45
75 -71 148 -52 60 17 92 48 106 105 l13 49 171 3 c144 2 170 0 165 -12 -3 -8
-19 -14 -35 -14 -51 0 -109 -22 -144 -56 -39 -36 -42 -56 -13 -85 18 -18 29
-21 71 -16 94 11 148 62 156 146 l5 51 46 1 c25 0 89 9 141 19 52 9 102 18
111 19 13 1 17 -13 22 -66z m266 -73 c1 -13 0 -13 -9 0 -5 8 -9 26 -9 40 l1
25 8 -25 c4 -14 8 -32 9 -40z m-1096 -125 c240 -26 472 -19 785 21 43 6 52 4
52 -9 0 -8 -16 -25 -35 -37 -42 -26 -75 -66 -75 -91 0 -29 36 -59 71 -59 16 0
28 -4 25 -8 -21 -34 -661 -49 -866 -20 l-95 13 -3 40 c-3 51 -18 134 -28 159
-6 16 -4 18 11 12 10 -3 81 -13 158 -21z m342 -238 c190 0 301 5 375 15 58 9
107 14 109 12 8 -7 -30 -197 -56 -278 -81 -256 -249 -463 -464 -572 -157 -79
-315 -114 -509 -114 -280 0 -533 82 -669 218 l-56 56 110 8 c339 23 563 172
644 428 16 50 40 199 41 251 0 3 46 -1 103 -9 69 -10 189 -15 372 -15z"/>
</g>
</svg>
               <a href="/" className="btn btn-ghost normal-case text-xl title"><i>All Era Music</i></a>


            </div>
            <div className="navbar-end">

            <div className="dropdown dropdown-bottom dropdown-end">
  <div tabIndex={0} role="button"   className="m-1">
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-5 h-5 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"></path></svg>
  </div>
  <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-46">
  <li><a className="text-base" href="/about"><ion-icon name="help-circle"></ion-icon>About</a></li>
  </ul>
</div>



            </div>
        </div>
    );
  }
  export default Navbar;